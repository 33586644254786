import React, {useEffect} from 'react';
import {Flex, Icon, Link, Text} from '@chakra-ui/react';
import {MdOutlineBrokenImage} from 'react-icons/md';

import './ErrorPage.scss';
import {trackErrorPageView} from '../../shared/mixpanel/page-views';

const ErrorPage: React.FC = () => {
  useEffect(() => {
    trackErrorPageView();
  }, []);

  return (
    <Flex flexDirection="column" alignItems="center" margin="3rem 1.5rem 0rem 1.5rem">
      <Icon
        w={['6rem', '8rem']}
        h={['6rem', '8rem']}
        borderRadius="50%"
        as={MdOutlineBrokenImage}
        backgroundColor="gray.200"
        color="#313871"
        padding="1rem"
        marginBottom="2rem"
      />
      <h1 className="error-title">Sorry, this page isn&apos;t available</h1>
      <Text textAlign={['center', 'inherit']}>
        The link you followed may be broken, or the page may have been removed.
      </Text>
      <p>
        Go back to{' '}
        <Link
          color="black"
          href={`${window.location.origin}/home`}
          style={{textDecoration: 'underline'}}
          _focus={{boxShadow: 'none'}}
        >
          NurseDash
        </Link>
      </p>
    </Flex>
  );
};

export default ErrorPage;
