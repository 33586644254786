import {useEffect} from 'react';
import {gql, useLazyQuery, useQuery} from '@apollo/client';
import {QueryHookOptions} from '@apollo/react-hooks';
import {AllOverviewShiftsDetails} from 'shared/types/allOverviewShiftsDetails';
import {ApplicantFacilityInfoDetails} from 'shared/types/applicantFacilityInfoDetails';
import {GetSummaryOfShiftUnitsInput, GetSummaryOfShiftUnitsResult} from './types';
import {
  getAllOverviewShiftsFields,
  getApplicantDataFields,
  getShiftDetailsFields,
  getSummaryOfShiftUnitsFields,
} from './fields';
import useAppDispatch from '../../hooks/useAppDispatch';
import {useFacilityId} from '../../../routes/Shifts/CreateShift/appSelectors';
import {
  getAllOverviewShifts,
  setGetAllOverviewShifts,
  getApplicantFacilityInfo,
  setGetApplicantFacilityInfo,
  getShiftSummary,
  setShiftSummary,
} from '../../slices/calShiftSlice';
import {useApolloClients} from '../../../core/providers/ApolloContextProvider';

const getSummaryOfShiftUnits = (fields: string = getSummaryOfShiftUnitsFields) => gql`
    query ($facilityId: String!, $startDateTime: String!, $endDateTime: String!) {
      getSummaryOfShiftUnits(facilityId: $facilityId, startDateTime: $startDateTime, endDateTime: $endDateTime){
            ${fields}
        }
    }
`;

export const useGetSummaryOfShiftUnits = (
  options?: QueryHookOptions<GetSummaryOfShiftUnitsResult, GetSummaryOfShiftUnitsInput>,
  fields?: string
) => {
  const {client} = useApolloClients();
  const dispatch = useAppDispatch();
  dispatch(getShiftSummary());
  return useQuery<GetSummaryOfShiftUnitsResult, GetSummaryOfShiftUnitsInput>(getSummaryOfShiftUnits(fields), {
    ...options,
    client,
    onCompleted: data => {
      dispatch(setShiftSummary(data.getSummaryOfShiftUnits));
    },
    onError: err => {
      console.log('err from getSummaryOfShiftUnits', err);
    },
  });
};

const getShiftDetail = (fields: string = getShiftDetailsFields) => gql`
  query ($shiftId: String!) {
    getShiftDetail(shiftId: $shiftId){
      ${fields}
    }
  }
`;

export const useLazyGetShiftDetails = () => {
  const {client} = useApolloClients();
  const [getShiftDetails, {data, loading}] = useLazyQuery(getShiftDetail(), {
    client,
  });
  return {getShiftDetails, data, loading};
};

const getShiftDetailQuery = (fields: string = getShiftDetailsFields) => gql`
  query ($shiftId: String!) {
    getShiftDetail(shiftId: $shiftId){
      ${fields}
    }
  }
`;

export const useGetShiftDetailsMonthly = (shiftId: string | null) => {
  const {client} = useApolloClients();
  const {data, loading, error} = useQuery(getShiftDetailQuery(), {
    variables: {shiftId},
    skip: !shiftId,
    client,
  });

  return {data, loading, error};
};

const getAllOverviewShiftsLegacyQuery = (fields: string = getAllOverviewShiftsFields) => gql`
  query allOverviewShifts($ids: [Int]) {
    allOverviewShifts(filter: {ids: $ids}) {
      ${fields}
    }
  }
`;

export const useGetAllOverviewShifts = (options?: QueryHookOptions<AllOverviewShiftsDetails>) => {
  const {legacyClient} = useApolloClients();
  const facilityId = useFacilityId();

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAllOverviewShifts());
  }, [dispatch]);

  return useQuery<AllOverviewShiftsDetails>(getAllOverviewShiftsLegacyQuery(), {
    skip: !facilityId,
    client: legacyClient,
    ...options,
    onCompleted: data => {
      dispatch(setGetAllOverviewShifts(data));
    },
    onError: err => {
      console.log('err from useGetAllOverviewShifts', err);
    },
  });
};

const getApplicantFacilityInfoLegacyQuery = (fields: string = getApplicantDataFields) => gql`
  query Applicant($id: Int) {
    data: Applicant(id: $id) {
     ${fields}
    }
  }
`;

export const useGetApplicantFacilityInformation = (options?: QueryHookOptions<ApplicantFacilityInfoDetails>) => {
  const {legacyClient} = useApolloClients();
  const facilityId = useFacilityId();

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getApplicantFacilityInfo());
  }, [dispatch]);

  return useQuery<ApplicantFacilityInfoDetails>(getApplicantFacilityInfoLegacyQuery(), {
    skip: !facilityId,
    client: legacyClient,
    ...options,
    onCompleted: data => {
      dispatch(setGetApplicantFacilityInfo(data));
    },
    onError: err => {
      console.log('err from useGetApplicantFacilityInfo', err);
    },
  });
};

export default {useGetSummaryOfShiftUnits, useLazyGetShiftDetails, useGetShiftDetailsMonthly, useGetAllOverviewShifts};
