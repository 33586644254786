import {Box, Button, Flex} from '@chakra-ui/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';

import {Props} from './Footer.types';

const Footer = ({
  formIsDirty,
  isSubmitting,
  // isRateOutsideOfRange,
  openingButNoDay,
  resetForm,
  isEditShift,
  shiftId,
  startDate,
  primaryButtonText = 'Post shift',
  formValidationCheck,
}: Props) => {
  const isDisabled = [!formIsDirty, isSubmitting, openingButNoDay].some(Boolean);
  const navigate = useNavigate();
  const navigateBackToShiftInfoModal = () => {
    const prevUnitName = localStorage.getItem('unitName');
    const prevStartDate = localStorage.getItem('startDate');
    const prevEndDate = localStorage.getItem('endDate');
    navigate('/weekly', {
      state: {
        from: 'editShift',
        startDate: moment(startDate).format('YYYY-MM-DD'),
        shiftId,
        prevUnitName,
        prevStartDate,
        prevEndDate,
      },
    });
  };
  return (
    <Box position="sticky" bottom={0} bg="#FAFAFA" padding="1rem 0rem" zIndex={100}>
      <Flex w="100%" gap="1rem" bg="#FAFAFA">
        <Button
          h="3rem"
          w="8rem"
          variant="outline"
          type="button"
          onClick={isEditShift ? () => navigateBackToShiftInfoModal() : () => resetForm()}
        >
          Cancel
        </Button>
        <Button
          type={isEditShift ? 'button' : 'submit'}
          isDisabled={isDisabled}
          h="3rem"
          w="8rem"
          onClick={isEditShift && formValidationCheck ? () => formValidationCheck() : undefined}
        >
          {primaryButtonText}
        </Button>
      </Flex>
    </Box>
  );
};

export default Footer;
