import {Skeleton, Flex, Text} from '@chakra-ui/react';
import React from 'react';

type Props = {
  title: string;
  subText: string;
};

const DescriptionInputSkelton = ({title, subText}: Props) => (
  <Flex flexDirection="column" gap=".5rem">
    <Flex gap=".5rem">
      <Text fontWeight="600">{title}</Text>
      <Text color="#718096">(Optional)</Text>
    </Flex>
    <Skeleton height="7.5rem" w="25rem" borderRadius=".5rem" />
    <Text color="#718096">{subText}</Text>
  </Flex>
);

export default DescriptionInputSkelton;
