import {MutationHookOptions, gql, useMutation} from '@apollo/client';
import {useApolloClients} from '../../../core/providers/ApolloContextProvider';
import useAppDispatch from '../../hooks/useAppDispatch';
import { setSettingsData } from '../../slices/settingsSlice';

const updateLagacySettings = () => gql`
  mutation updateFacility(
    $address: String
    $allowedQualifications: [String]
    $segmentId: Int
    $segmentName: String
    $selectArea: Int
    $checkReliability: Boolean
    $covidMedicalExemption: Boolean
    $covidReligiousExemption: Boolean
    $covidStatus: String
    $covidStatusCheck: DateTime
    $covidSurveyActionName: String
    $crypted_password: String
    $emailList: [TypeEmailInput]
    $end_time: DateTime
    $exempt_from_holiday_pay: Boolean
    $geofenceRadius: Boolean
    $id: Int
    $image: TypeImageInput
    $isOrientationMaterialsShowForAll: Boolean
    $isSignatureRequired: Boolean
    $isSuper: Boolean
    $isSupervisorEmailRequired: Boolean
    $isSupervisorPhoneRequired: Boolean
    $lat: Float
    $lng: Float
    $login: String
    $min_bonus_amount: Int
    $max_bonus_amount: Int
    $name: String
    $orientationMaterials: TypeFacilityOrientationMaterialsInput
    $phones: [TypeFacilityPhonesInput]
    $rates: [TypeFacilityRatesInput]
    $short_name: String
    $start_time: DateTime
    $status: String
    $timezone: String
    $trackSignatureVerificationPrompt: DateTime
    $trackCovidSurveyPopup: DateTime
    $autoSelectType: autoSelectType
    $selectionType: selectionType
    $unitDescriptions: [TypeFacilityUnitDescriptionsInput]
    $ucw_feature_flag: Boolean
    $updateFutureAutoselectShifts: Boolean
    $uuid: String
    $covidVaccineRequired: Boolean
    $allowSingleDoseVaccine: Boolean
  ) {
    data: updateFacility(
      address: $address
      allowedQualifications: $allowedQualifications
      segmentId: $segmentId
      segmentName: $segmentName
      selectArea: $selectArea
      checkReliability: $checkReliability
      covidMedicalExemption: $covidMedicalExemption
      covidReligiousExemption: $covidReligiousExemption
      covidStatus: $covidStatus
      covidStatusCheck: $covidStatusCheck
      covidSurveyActionName: $covidSurveyActionName
      crypted_password: $crypted_password
      emailList: $emailList
      end_time: $end_time
      exempt_from_holiday_pay: $exempt_from_holiday_pay
      geofenceRadius: $geofenceRadius
      id: $id
      image: $image
      isOrientationMaterialsShowForAll: $isOrientationMaterialsShowForAll
      isSignatureRequired: $isSignatureRequired
      isSuper: $isSuper
      isSupervisorEmailRequired: $isSupervisorEmailRequired
      isSupervisorPhoneRequired: $isSupervisorPhoneRequired
      lat: $lat
      lng: $lng
      login: $login
      min_bonus_amount: $min_bonus_amount
      max_bonus_amount: $max_bonus_amount
      name: $name
      orientationMaterials: $orientationMaterials
      phones: $phones
      rates: $rates
      short_name: $short_name
      start_time: $start_time
      status: $status
      timezone: $timezone
      trackSignatureVerificationPrompt: $trackSignatureVerificationPrompt
      trackCovidSurveyPopup: $trackCovidSurveyPopup
      autoSelectType: $autoSelectType
      selectionType: $selectionType
      unitDescriptions: $unitDescriptions
      ucw_feature_flag: $ucw_feature_flag
      updateFutureAutoselectShifts: $updateFutureAutoselectShifts
      uuid: $uuid
      covidVaccineRequired: $covidVaccineRequired
      allowSingleDoseVaccine: $allowSingleDoseVaccine
    ) {
      id
      covidStatus
      orientationMaterials
        {
          data
          src
          uuid
          title
        }
      covidMedicalExemption
      covidReligiousExemption
      covidVaccineRequired
      allowSingleDoseVaccine
    }
  }
`;

const useUpdateLagacySettings = (options?: MutationHookOptions<any, any>) => {
  const {legacyClient} = useApolloClients();
  const dispatch = useAppDispatch();

  return useMutation<any, any>(updateLagacySettings(), {
    ...options,
    client: legacyClient,
    onCompleted: data => {
      dispatch(setSettingsData(data?.data));
    },
    onError: err => {
      console.log('err from updateSettings', err);
    },
  });
};

export default useUpdateLagacySettings;
