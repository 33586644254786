import {gql, useQuery} from '@apollo/client';
import {QueryHookOptions} from '@apollo/react-hooks';
import {useSelector} from 'react-redux';
import {useApolloClients} from '../../../core/providers/ApolloContextProvider';
import useAppDispatch from '../../hooks/useAppDispatch';
import allChannelsFields from './fields';
import {ReduxState} from '../../types';

import {setMessages} from '../../slices/messagesSlice';

const getAllLegacyChannels = () => gql`
  query {
    allChannels {
      ${allChannelsFields}
    }
  }
`;

const useGetAllLegacyChannels = ( options?: QueryHookOptions) => {
  const authToken = useSelector((state:ReduxState) => state.auth.token);

  const { legacyClient } = useApolloClients();
  const dispatch = useAppDispatch();

  return useQuery(getAllLegacyChannels(), {
    skip: authToken === '',
    client: legacyClient,
    ...options,
    onCompleted: (data) => {
      dispatch(setMessages(data.allChannels));
    },
    onError: (err) => {
      console.log('err from useGetAllLegacyChannels', err);
    },
  });
};

const getLegacyChannel = () => gql`
  query Channel($id: Int!) {
    Channel(id: $id) {
      members {
        user {
          name
          userModel
        }
      }
      messages {
        text
        createdAt
        member {
          user {
            name
            userModel
          }
        }
      }
    }
  }
`;

export const useGetLegacyChannel = (options?: QueryHookOptions<any, any>) => {
  const {legacyClient} = useApolloClients();

  return useQuery<any, any>(getLegacyChannel(), {
    ...options,
    client: legacyClient,
  });
};

export default useGetAllLegacyChannels;
