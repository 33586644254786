const commonUserFields = `
  firstName
  lastName
  email
  type
`;

const commonAccessFields = `
  facilityId
  role
  legacyFacilityId
`;

export const getAllUsersFields = `
${commonUserFields}
facilityId
facilityName
role
legacyFacilityId
userId
`;

export const createUserFields = `
${commonUserFields}
access {
  ${commonAccessFields}
}
`;

export const deleteUserFields = `
${commonUserFields}
access {
  ${commonAccessFields}
  facilityName
}
`;

export const updateUserFields = `
${commonUserFields}
type
access {
  ${commonAccessFields}
  facilityName
}
`;
export const changePasswordFields = commonUserFields;
