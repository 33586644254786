import React from 'react';

import {Box, Flex, IconButton, Input, Spacer, Text} from '@chakra-ui/react';
import {AddIcon, MinusIcon} from '@chakra-ui/icons';
import {RiDeleteBin5Line} from 'react-icons/ri';
import uuid from 'react-uuid';
import moment from 'moment';
import {Props} from './ShiftOpeningSelector.types';
import {getDays} from '../CreateShiftForm/helperFunctions';

const ShiftOpeningSelector = ({
  shiftTemplate,
  getValues,
  isEditable,
  errors,
  setValue,
  datePickerIndex,
  setDatePickerIndex,
}: Props) => {
  const dayAndOpenings = getValues('dayAndOpenings') || [];
  const daysOfWeek = getDays().map(day => day.fullDate);

  const getOrdinalSuffix = (n: number): string => {
    if (n > 3 && n < 21) return 'th';
    switch (n % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  const formatDateToDayMonthOrdinal = (utcDateString: string) => {
    const date = moment.utc(utcDateString); // Parse as UTC
    const dayNum = date.date();
    const dayWithSuffix = `${dayNum}${getOrdinalSuffix(dayNum)}`;

    const formatted = date.format('dddd MMM'); // Get day and month

    return (
      <Flex color="#1A202C" fontWeight="400" fontSize=".9rem">
        <Text fontWeight="600">{formatted.split(' ')[0]}</Text>
        <Spacer minWidth=".3rem" />
        {` ${formatted.split(' ')[1]} ${dayWithSuffix}`}
      </Flex>
    );
  };

  const sortedDayAndOpenings = [...dayAndOpenings].sort(
    (a, b) => new Date(a.localStartDate).getTime() - new Date(b.localStartDate).getTime()
  );

  // add one to the number of openings
  const handleIncrementNumOfOpenings = (index: number) => {
    const newDayAndOpenings = [...dayAndOpenings];
    const sortedIndex = sortedDayAndOpenings[index].localStartDate;
    const actualIndex = newDayAndOpenings.findIndex(item => item.localStartDate === sortedIndex);

    newDayAndOpenings[actualIndex].openings += 1;

    setValue('dayAndOpenings', newDayAndOpenings, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const handleDecrementNumOfOpenings = (index: number) => {
    if (getValues('dayAndOpenings')[index].openings >= 2) {
      const newDayAndOpenings = [...dayAndOpenings];
      const sortedIndex = sortedDayAndOpenings[index].localStartDate;
      const actualIndex = newDayAndOpenings.findIndex(item => item.localStartDate === sortedIndex);

      newDayAndOpenings[actualIndex].openings -= 1;

      setValue('dayAndOpenings', newDayAndOpenings, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  const handleDeleteShift = (index: number) => {
    const newDayAndOpenings = [...dayAndOpenings];

    const sortedIndex = sortedDayAndOpenings[index].localStartDate;
    if (!daysOfWeek.includes(sortedIndex)) {
      const updatedIndex = datePickerIndex - 1;
      setDatePickerIndex(updatedIndex);
    }

    const actualIndex = newDayAndOpenings.findIndex(item => item.localStartDate === sortedIndex);

    newDayAndOpenings.splice(actualIndex, 1);

    setValue('dayAndOpenings', newDayAndOpenings, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });

    if (newDayAndOpenings.length === 0) {
      setValue('date', [], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    } else {
      // map values of localStartDate to an array
      const updatedDates = newDayAndOpenings.map((shift: any) => shift.localStartDate);

      setValue('date', updatedDates, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  return (
    <Box marginTop="1rem" marginBottom="1rem">
      <Flex gap=".5rem">
        <Text fontWeight="600">Number of Openings</Text>
      </Flex>
      <Text width="20rem" color="#4A5568" marginBottom="1.5rem">
        Enter the number of openings that you need to fill for each shift.
      </Text>
      {dayAndOpenings?.[0] && (
        <>
          <Flex gap=".5rem">
            <Text fontWeight="600">Number of Openings</Text>
          </Flex>
          <Text width="20rem" color="#4A5568" marginBottom="1.5rem">
            Enter the number of openings that you need to fill for each shift.
          </Text>
        </>
      )}

      {dayAndOpenings?.[0] &&
        sortedDayAndOpenings.map((shift: any, index: any) => (
          <Box key={uuid()} marginBottom=".5rem">
            <Flex flex-direction="column" justifyContent={['space-between', 'inherit']} gap={['inherit', '1rem']}>
              <Flex
                bg="#E8EAF1"
                borderRadius="4px"
                w="50%"
                h="2.5rem"
                alignItems="center"
                justifyContent="flex-start"
                padding=".8rem"
              >
                {shift ? formatDateToDayMonthOrdinal(shift.localStartDate) : 'Select a day'}
              </Flex>
              <Flex gap=".5rem" width="50%">
                {shift.openings > 1 ? (
                  <IconButton
                    variant="ghost"
                    aria-label="Minus"
                    icon={<MinusIcon />}
                    isDisabled={!!shiftTemplate && !isEditable}
                    _disabled={{
                      opacity: '0.6',
                    }}
                    onClick={() => handleDecrementNumOfOpenings(index)}
                  />
                ) : (
                  <IconButton
                    variant="ghost"
                    aria-label="Delete"
                    isDisabled={!!shiftTemplate && !isEditable}
                    _disabled={{
                      opacity: '0.6',
                    }}
                    icon={<RiDeleteBin5Line />}
                    onClick={() => handleDeleteShift(index)}
                  />
                )}

                <Input
                  width="4rem"
                  value={shift.openings || ''}
                  textAlign="center"
                  isDisabled={!!shiftTemplate && !isEditable}
                  _disabled={{
                    opacity: '0.6',
                  }}
                  onChange={() => {}}
                />
                <IconButton
                  variant="ghost"
                  isDisabled={!!shiftTemplate && !isEditable}
                  _disabled={{
                    opacity: '0.6',
                  }}
                  aria-label="Add"
                  icon={<AddIcon />}
                  onClick={() => {
                    handleIncrementNumOfOpenings(index);
                  }}
                />
              </Flex>
            </Flex>
            {errors.numOfOpenings && (
              <Text color="red.500" fontSize="sm">
                Openings required.
              </Text>
            )}
          </Box>
        ))}
    </Box>
  );
};

export default ShiftOpeningSelector;
