/* eslint-disable no-plusplus */
import React, {useEffect, useState, useRef} from 'react';

import uuid from 'react-uuid';
import {Flex, Text, Button} from '@chakra-ui/react';
import {ChevronDownIcon} from '@chakra-ui/icons';
import DatePicker from 'react-multi-date-picker';
import Icon from 'react-multi-date-picker/components/icon';
import moment from 'moment';
import EditDateSkeleton from './EditDateSkeleton/EditDateSkeleton';

type Props = {
  handleDaySelection: (
    date: string,
    setValue: (name: string, value: string | boolean) => void,
    getValues: () => any
  ) => void;
  setValue: any;
  getValues: any;
  errors: any;
  watch: any;
  selectedTemplateDaysAsDates: string[];
  setSelectedTemplateDaysAsDates: (days: string[]) => void;
  setFocus: any;
  shiftTemplate: any;
  register: any;
  isEditable?: boolean;
  loading?: boolean;
};

const DateSelector = React.forwardRef(
  ({errors, setFocus, shiftTemplate, register, setValue, getValues, isEditable, loading}: Props, ref) => {
    const dateFromShiftTemplate = getValues('date');

    const [datePickerValues, setDatePickerValues] = useState<string[]>([]);

    const datepickerRef = useRef<HTMLDivElement>();

    const handleDatePickerChange = (date: any) => {
      if (!date || date.length === 0 || !date[date.length - 1]) {
        return;
      }
      const datePickerUnix = date[date.length - 1].unix;

      const dateConverted = moment.unix(datePickerUnix).format('YYYY-MM-DD');

      setDatePickerValues([dateConverted]);

      setValue('date', dateConverted, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    };
    const startTime = shiftTemplate?.startTime;
    const currentDate = getValues('date');
    type SevenDaysInfo = {
      firstOfSevenDays: string;
      lastOfSevenDays: string;
      dates: Array<{
        dayOfWeek: string;
        dayOfMonth: string;
        fullDate: string;
      }>;
    };
    const getDays = (selectedDate: string): SevenDaysInfo => {
      const dates = [];
      let sevenDaysInfo = {} as SevenDaysInfo;
      const threeDaysPrior = moment(selectedDate).subtract(3, 'days');

      for (let i = 0; i < 7; i++) {
        const date = moment(threeDaysPrior).add(i, 'days');
        const dayOfWeek = date.format('dddd').slice(0, 3); // 'Mon'
        const dayOfMonth = date.format('DD'); // '01'
        const fullDate = date.format('YYYY-MM-DD'); // '2021-01-01'

        dates.push({dayOfWeek, dayOfMonth, fullDate});
        const firstOfSevenDays = dates[0].fullDate;
        const lastOfSevenDays = dates[dates.length - 1].fullDate;
        sevenDaysInfo = {firstOfSevenDays, lastOfSevenDays, dates};
      }

      return sevenDaysInfo;
    };

    const dayButtons: SevenDaysInfo = getDays(startTime);

    const dayButtonColors = (day: any) => {
      const {dayOfMonth} = day;
      const selectedDay = moment(currentDate).format('DD');
      if (day.fullDate === currentDate && dayOfMonth === selectedDay) {
        return {color: '#25285A', border: '0.125rem solid #25285A'};
      }
      return {color: '#616CA1', border: 'none'};
    };

    useEffect(() => {
      if (shiftTemplate) {
        setFocus('date');
      }
      setDatePickerValues([dateFromShiftTemplate]);
    }, [shiftTemplate, setFocus, dateFromShiftTemplate]);

    const isBeforeToday = (date: string) => {
      const today = moment().format('YYYY-MM-DD');
      return moment(date).isBefore(today);
    };

    const isOutsideOfDayButtons = (date: string) => {
      const firstDay = dayButtons.firstOfSevenDays;
      const lastDay = dayButtons.lastOfSevenDays;
      return moment(date).isBefore(firstDay) || moment(date).isAfter(lastDay);
    };

    if (loading) {
      return <EditDateSkeleton />;
    }

    return (
      <Flex flexDirection="column">
        <Flex gap=".5rem">
          <Text fontWeight="600">Date</Text>
        </Flex>
        <Flex overflowX="auto" overflowY="hidden" whiteSpace="nowrap" css={{'&::-webkit-scrollbar': {display: 'none'}}}>
          <Flex justifyContent="space-between" w="98%" height="5rem" paddingTop="1rem">
            {dayButtons.dates.map(day => (
              <Button
                {...register('date')}
                ref={ref}
                type="button"
                key={uuid()}
                _disabled={{
                  opacity: '0.6',
                  cursor: 'not-allowed',
                  pointerEvents: 'none',
                }}
                isDisabled={isBeforeToday(day.fullDate) || !isEditable}
                variant="square"
                onClick={() => {
                  setValue('date', day.fullDate, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                  setDatePickerValues([day.fullDate]);
                }}
                color={dayButtonColors(day).color}
                border={dayButtonColors(day).border}
              >
                {day.dayOfMonth}
                <br />
                {day.dayOfWeek}
              </Button>
            ))}
            <Flex
              backgroundColor="#E2E8F0"
              color="#616CA1"
              height="4rem"
              width="4rem"
              borderRadius="0.375rem"
              fontSize="16px"
              fontWeight="400"
              flexDirection="column"
              justifyContent="flex-end"
              display={['none', 'inherit']}
            >
              <Flex justifyContent="flex-end">
                <Flex
                  marginRight="-.5rem"
                  visibility={isOutsideOfDayButtons(currentDate) ? 'visible' : 'hidden'}
                  borderRadius="50%"
                  backgroundColor="#475392"
                  color="white"
                  height="1.5rem"
                  width="1.5rem"
                  zIndex="20"
                  justifyContent="center"
                >
                  1
                </Flex>
              </Flex>
              <Flex flexDirection="column" gap=".8rem" alignItems="center" overflow="visible" margin="-.3rem">
                <DatePicker
                  render={<Icon />}
                  ref={datepickerRef}
                  value={datePickerValues}
                  onChange={e => handleDatePickerChange(e)}
                  style={{zIndex: 1000, position: 'relative'}}
                  disabled={!isEditable}
                />
                <Flex position="relative" top="-0.625rem">
                  <ChevronDownIcon />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        {errors.dayAndOpenings && (
          <Text color="red.500" fontSize="sm">
            {errors.dayAndOpenings.message}
          </Text>
        )}
      </Flex>
    );
  }
);

export default DateSelector;
