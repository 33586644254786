import {Box, Flex, Heading, Spinner, Text} from '@chakra-ui/react';
import React, {useCallback, useEffect, useState} from 'react';
import {RxReload} from 'react-icons/rx';
// import {useIntercom} from 'react-use-intercom';
import {useErrorBoundary} from 'react-error-boundary';
import logo from './assets/logo.svg';
import maintenance from './assets/maintenance.svg';
import ErrorPage from '../Error/ErrorPage';

const Maintenance = ({resetErrorBoundary, error}: any) => {
  const {resetBoundary} = useErrorBoundary();

  const [timer, setTimer] = useState(30);
  const [loader, setLoader] = useState(false);

  const triggerRefresh = useCallback(() => {
    setLoader(true);
    setTimeout(() => {
      resetBoundary();
      setLoader(false);
    }, 1000);
  }, [resetBoundary]);

  useEffect(() => {
    let interval: any;
    // eslint-disable-next-line
    interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
      if (timer === 0) {
        triggerRefresh();
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [timer, triggerRefresh]);

  // const {show} = useIntercom();

  return error !== 'Under Maintenance' ? (
    <ErrorPage />
  ) : (
    <Box padding="2.5rem">
      {loader && (
        <Flex margin="0" position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="#38427E" size="xl" />
        </Flex>
      )}
      <img src={logo} alt="logo" />
      <Flex flexDirection="column" alignItems="center" gap="1rem">
        <img src={maintenance} alt="maintenance" />
        <Heading fontSize="2.25rem"> NurseDash is currently undergoing scheduled maintenance.</Heading>
        <Text color="#718096" fontSize="1.25rem">
          We’ll be back soon.
        </Text>

        <Flex gap="0.3rem" alignItems="center" pt="2rem">
          <RxReload color="#3065CA" />
          <Text
            color="#3065CA"
            textDecoration="underline"
            fontSize="1.25rem"
            onClick={resetErrorBoundary}
            cursor="pointer"
          >
            Refresh now
          </Text>
        </Flex>
        <Flex gap="0.5rem" alignItems="center">
          <Text color="#0000007A">Auto refreshes in</Text>
          <Text color="black" fontSize="1.25rem">
            {timer} s
          </Text>
        </Flex>
      </Flex>

      {/* <RxReload color="#3065CA"  onClick={show}/> */}
    </Box>
  );
};

export default Maintenance;
