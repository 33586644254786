import * as React from 'react';
import {ColorModeScript, ChakraProvider} from '@chakra-ui/react';
import mixpanel from 'mixpanel-browser';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import * as ReactDOM from 'react-dom/client';
import {Auth0Provider, Auth0ProviderOptions} from '@auth0/auth0-react';
import {RouterProvider} from 'react-router-dom';
// import {ErrorBoundary} from 'react-error-boundary';
import {Provider as ReduxProvider} from 'react-redux';
import {IntercomProvider} from 'react-use-intercom';
import ApolloGqlProvider from './core/providers/ApolloProvider';
import router from './routes/router';
import reportWebVitals from './reportWebVitals';
import theme from './shared/styles/theme';
import store from './store';
// import Maintenance from './routes/Maintenance/Maintenance';

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);

const App = () => {
  const sentryDataSourceName = process.env.REACT_APP_SENTRY_DSN;
  const currentEnvironment = process.env.REACT_APP_ENVIRONMENT;
  const mixPanelToken = process.env.REACT_APP_MIX_PANEL_PROJECT_TOKEN as string;
  const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN as string;
  const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
  const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE as string;
  const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID as string;

  mixpanel.init(mixPanelToken, {
    debug: true,
    ignore_dnt: true,
  });

  Sentry.init({
    dsn: sentryDataSourceName,
    integrations: [new Integrations.BrowserTracing()],
    environment: currentEnvironment,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  const auth0ProviderConfig: Auth0ProviderOptions = {
    domain: auth0Domain,
    clientId: auth0ClientId,
    authorizationParams: {
      redirect_uri: `${window.location.origin}/home`,
      ...(auth0Audience ? {audience: auth0Audience} : null),
    },
    cacheLocation: 'localstorage',
    useRefreshTokens: true,
  };

  return (
    <React.StrictMode>
      <IntercomProvider appId={intercomAppId}>
        {/* <ErrorBoundary FallbackComponent={Maintenance}> */}
          <ReduxProvider store={store}>
            <ChakraProvider theme={theme}>
              <Auth0Provider {...auth0ProviderConfig}>
                <ApolloGqlProvider>
                  <RouterProvider router={router} />
                  <ColorModeScript />
                </ApolloGqlProvider>
              </Auth0Provider>
            </ChakraProvider>
          </ReduxProvider>
        {/* </ErrorBoundary> */}
      </IntercomProvider>
    </React.StrictMode>
  );
};
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
