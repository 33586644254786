import React, {useEffect} from 'react';
import {useDisclosure, Box, Drawer, DrawerContent} from '@chakra-ui/react';
import {Outlet, useLocation} from 'react-router-dom';
import SidebarContent from '../SidebarContent/SidebarContent';
import MobileNav from '../MobileNav/MobileNav';
import useAppSelector from '../../../hooks/useAppSelector';

const SidebarWithHeader = () => {
  const displayNavigation = useAppSelector(state => state.sidebar.display);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const location = useLocation();
  const isEditShiftPage = /\/edit-shift\/[a-z0-9-]+\/\d+/.test(location.pathname);
  const isCreateShiftPage = location.pathname === '/shifts/create';
  const isCreateOrEditShiftPage = isEditShiftPage || isCreateShiftPage;
  const authState = useAppSelector(state => state.auth);
  const {token} = authState;

  useEffect(() => {}, [token]);
  // ToDo: Create Loader Skeleton for app and use here instead of loading text

  return displayNavigation && token ? (
    <Box minH="100vh" bg="white" overflow="auto">
      <SidebarContent onClose={() => onClose} display={{base: 'none', md: 'block'}} />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box
        ml={{base: 0, md: 60}}
        p="4"
        backgroundColor={isCreateOrEditShiftPage ? '#FAFAFA' : 'inherit'}
        className="outlet"
        height="calc(100vh - 56px)"
        width="calc(100% - 240px)"
        bottom="0"
        position="absolute"

      >
        <Outlet />
      </Box>
    </Box>
  ) : (
    <Box p="2.5rem">Loading</Box>
  );
};

export default SidebarWithHeader;
