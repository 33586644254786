import {gql, useQuery} from '@apollo/client';
import {QueryHookOptions} from '@apollo/react-hooks';
import { useErrorBoundary } from "react-error-boundary";
import {getFacilityFields} from './fields';
import {GetFacility} from './types';
import {useApolloClients} from '../../../core/providers/ApolloContextProvider';

const getCurrentFacilityDetails = (facilityId: string, fields: string = getFacilityFields) => gql`query {
  getFacility(facilityId: "${facilityId}") {
      ${fields}
  }
}`;

export const useLoggedInFacilityDetails: (
  facilityId: string,
  options?: QueryHookOptions<GetFacility>,
  fields?: string
) => {
  data: GetFacility | undefined;
  loading: boolean;
  queryError: any | undefined;
} = (facilityId, options, fields) => {
  const {client} = useApolloClients();
  const { showBoundary } = useErrorBoundary();
  const {
    data,
    loading,
    error: queryError,
  } = useQuery<GetFacility>(getCurrentFacilityDetails(facilityId, fields), {
    skip: !facilityId,
    ...options,
    client,
    onError: (error: any) => {
      if (error?.message?.includes('503') || error?.message?.includes('Failed to fetch')) {
        showBoundary('Under Maintenance');
      }
    },
  });
  return {data, loading, queryError};
};

const getFacilityByLegacyId = (legacyId: number) => gql`query {
  getFacilityByLegacyId(legacyId: ${legacyId}) {
    id
  }
}`;

export const useGetFacilityByLegacyId = (legacyId: number, options?: QueryHookOptions<GetFacility>) => {
  const {client} = useApolloClients();

  const {
    data,
    loading,
    error: queryError,
    // ToDo: fix any type
  } = useQuery<any>(getFacilityByLegacyId(legacyId), {
    ...options,
    client,
  });
  return {data, loading, queryError};
};

export default getCurrentFacilityDetails;
