import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {RootState} from '../../store';

// Define a type for the slice state
export type AuthState = {
  token: string;
  decodedToken?: any;
};

// Define the initial state using that type
const initialState: AuthState = {
  token: '',
  decodedToken: {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state: AuthState, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setDecodedToken: (state: AuthState, action: PayloadAction<any>) => {
      state.decodedToken = action.payload;
    },
    logout: (state: AuthState) => {
      state.token = '';
    },
  },
});

export const {setToken, logout, setDecodedToken} = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth.token;

export default authSlice.reducer;
