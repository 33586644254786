import React from 'react';
import {Box, Flex, IconButton, Input, Text} from '@chakra-ui/react';
import {AddIcon, MinusIcon} from '@chakra-ui/icons';
import {RiDeleteBin5Line} from 'react-icons/ri';
import {Props} from './OpeningsSelector.types';

const OpenningsSelector = ({
  shiftTemplate,
  getValues,
  getFullDayName,
  watch,
  isEditable,
  handleDecrementNumOfOpenings,
  handleDeleteDay,
  handleIncrementNumOfOpenings,
  errors,
}: Props) => (
  <>
    <Flex gap=".5rem">
      <Text fontWeight="600">Number of Openings</Text>
      <Text color="#718096">(Optional)</Text>
    </Flex>
    <Text display={shiftTemplate ? 'none' : 'inherit'} width="20rem" color="#4A5568" marginBottom="1.5rem">
      Enter the number of openings that you need to fill for each shift.
    </Text>

    <Flex flex-direction="column" justifyContent={['space-between', 'inherit']} gap={['inherit', '1rem']}>
      <Box bg="#E8EAF1" borderRadius="4px" w="9.125rem" h="2.5rem">
        <Text color="#1A202C" fontWeight="600" fontSize="0.875rem" margin="auto" padding="10px" textAlign="center">
          {getValues('day') ? getFullDayName(getValues('day')) : 'Select a day'}
        </Text>
      </Box>
      <Flex gap=".5rem">
        {watch('numOfOpenings') > 1 ? (
          <IconButton
            variant="ghost"
            aria-label="Minus"
            icon={<MinusIcon />}
            isDisabled={!!shiftTemplate && !isEditable}
            _disabled={{
              opacity: '0.6',
            }}
            onClick={() => handleDecrementNumOfOpenings()}
          />
        ) : (
          <IconButton
            variant="ghost"
            aria-label="Delete"
            isDisabled={!!shiftTemplate && !isEditable}
            _disabled={{
              opacity: '0.6',
            }}
            icon={<RiDeleteBin5Line />}
            onClick={handleDeleteDay}
          />
        )}

        <Input
          width="4rem"
          value={getValues('numOfOpenings')}
          textAlign="center"
          isDisabled={!!shiftTemplate && !isEditable}
          _disabled={{
            opacity: '0.6',
          }}
          onChange={() => {}}
        />
        <IconButton
          variant="ghost"
          isDisabled={!!shiftTemplate && !isEditable}
          _disabled={{
            opacity: '0.6',
          }}
          aria-label="Add"
          icon={<AddIcon />}
          onClick={() => handleIncrementNumOfOpenings()}
        />
      </Flex>
    </Flex>
    <Text width="20rem" color="#4A5568" marginBottom="1.5rem">
      Enter the number of openings that you need to fill for each shift.
    </Text>

    {errors.numOfOpenings && (
      <Text color="red.500" fontSize="sm">
        Openings required.
      </Text>
    )}
  </>
);

export default OpenningsSelector;
