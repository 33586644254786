import React, {useEffect} from 'react';

import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
} from '@chakra-ui/react';

import {Props} from './DisplayUser.types';

import './DisplayUser.scss';
import {trackUserPageView} from "../../../../shared/mixpanel/page-views";

const DisplayUser: React.FC<Props> = ({
  user,
  email,
  facility,
  userRole,
  // latestLogin,
  handleIsDisplayUserModalOpen,
}: Props) => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  const handleClose = () => {
    onClose();
    handleIsDisplayUserModalOpen(false);
  };

  useEffect(() => {
    trackUserPageView();
  }, []);

  useEffect(() => {
    onOpen();
  }, [onOpen]);

  return (
    <ChakraModal isOpen={isOpen} onClose={() => handleIsDisplayUserModalOpen} isCentered>
      <ModalOverlay />
      <ModalContent className="modal">
        <ModalBody>
          <ModalCloseButton onClick={() => handleClose()} />
          <Box>
            <div className="label">USER NAME</div>
            <div className="value">{user}</div>
          </Box>

          <Box>
            <div className="label">EMAIL</div>
            <div className="value">{email}</div>
          </Box>
          <Box>
            <div className="label">FACILITY</div>
            <div className="value">{facility}</div>
          </Box>
          <Box>
            <div className="label">ROLE</div>
            <div className="value">{userRole}</div>
          </Box>
          {/* <Box>
            <div className="label">LATEST LOGIN</div>
            <div className="value">{latestLogin}</div>
          </Box> */}
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};

export default DisplayUser;
