import React from 'react';
import {Button, Flex, Text} from '@chakra-ui/react';
import {Props, PositionArgument} from './PositionSelector.types';
import {Position} from '../../types/position';
import PositionSkeleton from './PositionSkeleton/PositionSkeleton';

// ToDo: Parts of this functin are in a helper function. I need to combine and resolve the 'any' types.
const getFirstPositionSelectedDescriptionAndInstructions = (
  selectedPositionQualifications: any | undefined
): any | null =>
  selectedPositionQualifications && selectedPositionQualifications.length > 0
    ? {
        clockInInstruction: selectedPositionQualifications[0].qualifications[0].clockInInstruction,
        clockOutInstruction: selectedPositionQualifications[0].qualifications[0].clockOutInstruction,
        description: selectedPositionQualifications[0].qualifications[0].description,
      }
    : null;

const PositionSelector = React.forwardRef(
  (
    {
      selectedUnitPositions,
      isEditable,
      shiftTemplate,
      getValues,
      watch,
      setValue,
      isInCardData,
      errors,
      isMulti,
      loading,
    }: Props,
    ref: any
  ) => {
    const isInPositionArray = (name: string) =>
      getValues('position')?.some((positionObject: Position) => positionObject.name === name);
    // ToDo: Fix any types

    const setQualificationsAndDescription = () => {
      const watchedPosition = watch('position');
      const positionValues = getValues('position');

      if (!watchedPosition || !positionValues) return;

      const filteredPositionQualifications = selectedUnitPositions?.filter(position =>
        watchedPosition.some((pos: any) => pos.name === position.name)
      );

      const uniqueQualificationSet = new Set();
      const uniqueQualifications: any = [];

      filteredPositionQualifications?.forEach((position: any) => {
        position.qualifications.forEach(
          (qualification: {id: string; name: string; rate: {baseRate: number; subsidy: number}}) => {
            const value = qualification.id;
            if (!uniqueQualificationSet.has(value) && value !== '00000000-0000-0000-0000-000000000000') {
              uniqueQualificationSet.add(value);
              uniqueQualifications.push({
                id: value,
                name: qualification.name,
              });
            }
          }
        );
      });

      const shouldValidate = positionValues.length === 1;
      const qualificationsToSet = shouldValidate && uniqueQualifications.length ? uniqueQualifications : [];

      setValue('qualifications', qualificationsToSet, {
        shouldValidate,
        shouldDirty: shouldValidate,
        shouldTouch: shouldValidate,
      });

      const firstPositionSelectedDescriptionAndInstructions =
        getFirstPositionSelectedDescriptionAndInstructions(filteredPositionQualifications);

      setValue('description', firstPositionSelectedDescriptionAndInstructions?.description ?? '');
      setValue('clockInInstruction', firstPositionSelectedDescriptionAndInstructions?.clockInInstruction ?? '');
      setValue('clockOutInstruction', firstPositionSelectedDescriptionAndInstructions?.clockOutInstruction ?? '');
    };

    const handlePositionSelection = (position: PositionArgument) => {
      const positionMinusQualifications = {
        name: position.name,
        rate: position.rate.baseRate,
        adjustmentRate: 0,
        id: position.id,
      };

      if (!isMulti) {
        setValue('position', [positionMinusQualifications], {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
        setQualificationsAndDescription();

        return;
      }

      const isInPosition = watch('position')?.some(
        (positionObject: Position) => positionObject.name === positionMinusQualifications.name
      );
      const currentPosition = watch('position');

      if (currentPosition?.length === 0) {
        setValue('position', [positionMinusQualifications], {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      } else if (currentPosition?.length && currentPosition.length > 0 && !isInPosition) {
        const addToPosition = [...currentPosition, positionMinusQualifications];

        setValue('position', addToPosition, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      } else {
        const removeFromPositionArray = currentPosition?.filter(
          (positionObject: Position) => positionObject.name !== positionMinusQualifications.name
        );
        setValue('position', removeFromPositionArray, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
      }
      setQualificationsAndDescription();
    };

    if (loading) {
      return <PositionSkeleton />;
    }

    return (
      <>
        <Flex gap=".5rem" marginBottom=".5rem">
          {selectedUnitPositions &&
            selectedUnitPositions?.map((position: PositionArgument) => (
              <Button
                ref={ref}
                key={position.id}
                // isDisabled={(!!shiftTemplate && !isEditable) || ((positionsSelected?.length ?? 0) > 0 && !isMulti)}
                isDisabled={!!shiftTemplate && !isEditable}
                w="fit-content"
                variant="circle"
                color={
                  isInPositionArray(`${position.name}`) ||
                  (isInCardData && isInCardData(`${position.name}`) && !isEditable)
                    ? '#25285A'
                    : '#616CA1'
                }
                border={
                  isInPositionArray(`${position.name}`) ||
                  (isInCardData && isInCardData(`${position.name}`) && !isEditable)
                    ? '2px solid #25285A'
                    : 'none'
                }
                onClick={() => handlePositionSelection(position)}
              >
                {`${position.name}`}
              </Button>
            ))}
        </Flex>
        {errors.position?.type === 'required' && (
          <Text color="red.500" fontSize="sm">
            Position required.
          </Text>
        )}
        <Text color="#718096">Choose the position(s) that need to be covered</Text>
      </>
    );
  }
);

export default PositionSelector;
