import React from 'react';
import {Text} from '@chakra-ui/react';
import FontAwesomeImage from '../../../../assets/images/FacilitySelectorTestImage.png';
import './FacilitySelector.scss';
import useAppSelector from '../../../hooks/useAppSelector';
import {ReduxState} from '../../../types/index';

type Props = {
  isIcon?: boolean;
};

const FacilitySelector = ({isIcon}: Props) => {
  const sessionUser = useAppSelector((state: ReduxState) => state.usersData.sessionUser);
  const facilityName = sessionUser?.facilityName || '';

  return (
    <div className="facility-selector">
      {isIcon && <img className="facility-selector__icon" src={FontAwesomeImage} alt="Building Icon" />}
      {sessionUser?.userId && (
        <Text color="var(--gray-gray-600, #52525B)" fontSize="14px" marginRight="1rem" fontWeight="600">
          {facilityName ? `${facilityName}` : ' '}
        </Text>
      )}
    </div>
  );
};

export default FacilitySelector;
