import {useEffect} from 'react';
import {gql, useQuery} from '@apollo/client';
import {QueryHookOptions} from '@apollo/react-hooks';
import shiftTemplateFields, {activityHistoryFields, facilityUnitPositionRateFields} from './fields';
import {
  GetShiftTemplateByFacilityIdData,
  GetShiftTemplateByFacilityIdVariables,
  GetFacilityUnitPositionRateData,
  GetActivityHistoryVariables,
  GetActivityHistoryData,
} from './types';
import useAppDispatch from '../../hooks/useAppDispatch';
import {useFacilityId} from '../../../routes/Shifts/CreateShift/appSelectors';
import {
  getFacilityUnits,
  getShiftTemplates,
  setShiftTemplates,
  setFacilityUnits,
} from '../../slices/shiftTemplateSlice';
import {useApolloClients} from '../../../core/providers/ApolloContextProvider';

const getShiftTemplateByFacilityId = (fields: string = shiftTemplateFields) => gql`
  query ($facilityId: String!) {

    getShiftTemplateByFacilityId(facilityId: $facilityId){
      ${fields}
    }
  }
`;

const useGetShiftTemplateByFacilityId = (
  options?: QueryHookOptions<GetShiftTemplateByFacilityIdData, GetShiftTemplateByFacilityIdVariables>,
  fields?: string
) => {
  const {client} = useApolloClients(); // Use the custom hook to get both clients
  const facilityId = useFacilityId(); // Get the facilityId from the redux store

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getShiftTemplates());
  }, [dispatch]);

  return useQuery<GetShiftTemplateByFacilityIdData, GetShiftTemplateByFacilityIdVariables>(
    getShiftTemplateByFacilityId(fields),
    {
      skip: !facilityId,
      ...options,
      client,
      onCompleted: data => {
        dispatch(setShiftTemplates(data.getShiftTemplateByFacilityId));
      },
      onError: err => {
        console.log('err from useGetShiftTemplateByFacilityId', err);
      },
    }
  );
};

const getFacilityUnitPositionRate = (fields: string = facilityUnitPositionRateFields) => gql`
  query ($facilityId: String!) {
    getFacilityUnitPositionRate(facilityId: $facilityId){
      ${fields}
    }
  }
`;

export const useGetFacilityUnitPositionRate = (
  options?: QueryHookOptions<GetFacilityUnitPositionRateData, GetShiftTemplateByFacilityIdVariables>,
  fields?: string
) => {
  const {client} = useApolloClients();
  const facilityId = useFacilityId();

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getFacilityUnits());
  }, [dispatch]);

  return useQuery<GetFacilityUnitPositionRateData, GetShiftTemplateByFacilityIdVariables>(
    getFacilityUnitPositionRate(fields),
    {
      skip: !facilityId,
      ...options,
      client,
      onCompleted: data => {
        dispatch(setFacilityUnits(data));
      },
    }
  );
};

const getActivityHistory = (fields: string = activityHistoryFields) => gql`
  query ($input: ActivityHistoryGetInput!) {
    activityHistoryGet(input: $input){
      ${fields}
    }
  }
`;

export const useGetActivityHistory = (
  options?: QueryHookOptions<GetActivityHistoryData, GetActivityHistoryVariables>,
  fields?: string
) => {
  const {client} = useApolloClients();

  return useQuery<GetActivityHistoryData, GetActivityHistoryVariables>(getActivityHistory(fields), {
    ...options,
    client,
    onError: error => {
      console.log('Error from GetActivityHistory', error);
    },
  });
};

export default useGetShiftTemplateByFacilityId;
