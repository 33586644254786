import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {RootState} from '../../store';
import {TestMutationData} from '../gql/test/types';

// Define a type for the slice state
export type ExampleState = {
  data: TestMutationData | null;
  error: string | null;
  loading: boolean;
};

// Define the initial state using that type
const initialState: ExampleState = {
  data: null,
  error: null,
  loading: false,
};

export const exampleSlice = createSlice({
  name: 'example',
  initialState,
  reducers: {
    initCallback: (state: ExampleState) => {
      state.error = null;
      state.loading = true;
    },
    setTestData: (state: ExampleState, action: PayloadAction<TestMutationData>) => {
      state.data = action.payload;
      state.loading = false;
    },
    setErrorData: (state: ExampleState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {initCallback, setTestData, setErrorData} = exampleSlice.actions;

export const selectTestData = (state: RootState) => state.example.data;

export default exampleSlice.reducer;
