import {Box, Button, Flex, Menu, MenuButton, MenuGroup, MenuItem, MenuList, Text} from '@chakra-ui/react';
import React, {useState} from 'react';
import {IoPrintOutline} from 'react-icons/io5';
import moment from 'moment';
import InfoIcon from './Icons/Info.svg';
import {Shift, UnitPosition} from '../Shift.types';
import PrintPreviewModal from './PrintPreviewModal';
import {useTimeZone} from '../CreateShift/appSelectors';
import {checkShiftStatus} from './helpers/helpers';
import ShiftStatusFilter from '../CalendarListView/ShiftStatusFilter';
import ShiftUnitFilter from '../CalendarListView/ShiftUnitFilter';

type Props = {
  shiftsSummary: UnitPosition[];
  calenderComponent: JSX.Element;
  setSelectedUnitFilter: any;
  selectedUnitFilter: {unitId: string; positions: string[]}[];
  startDate: Date;
  endDate: Date;
  shifts: Shift[];
  statusFilter: string[];
  setStatusFilter: any;
  isMonthlyFilter?: boolean;
};

const CalenderFilters = ({
  shiftsSummary,
  selectedUnitFilter,
  startDate,
  endDate,
  shifts,
  setSelectedUnitFilter,
  setStatusFilter,
  statusFilter,
  isMonthlyFilter,
  ...rest
}: Props) => {
  const [isOpen, setIsOpen] = useState('');


  const legends = [
    {legend: 'Unfilled', background: '#f1ddcc', border: ''},
    {legend: 'Actions Required', background: '#2970FF', border: ''},
    {legend: 'Filled Completely', background: '#5AC68D', border: ''},
    {legend: 'Filled Partially', background: 'white', border: '3px solid #5AC68D'},
    {legend: 'Cancelled', background: '#D9D9D980', border: ''},
  ];

  const handleSelectedUnitFilter = (unitIds: React.SetStateAction<{unitId: string; positions: string[]}[]>) => {
  
    setSelectedUnitFilter(unitIds);
  };


  const [PrintModalData, setPrintModalData] = useState<{
    isOpen: boolean;
  }>({isOpen: false});
 

  const datesOfWeek = Array.from({length: 7}, (_, i) => moment(startDate).add(i, 'days').format('YYYY-MM-DD'));

  const sessionUserTimeZone = useTimeZone();

  const weeklyShifts = [...shifts]?.filter(shift => datesOfWeek.includes(shift.date));

  const weeklyTotalShiftCount = [...weeklyShifts]
    ?.map(shift => ({
      ...shift,
      shifts: shift.shifts.filter(
        innerShift =>
          !['cancelled', 'pastUnfilled'].includes(checkShiftStatus(innerShift, sessionUserTimeZone || 'UTC'))
      ),
    }))
    ?.reduce((acc, curr) => acc + Number(curr?.shifts?.length), 0);

  const weeklyFilteredShiftCount = [...shifts]
    ?.filter(shift => datesOfWeek.includes(shift.date))
    ?.map(shift => ({
      ...shift,
      shifts: shift.shifts
        .filter(
          innerShift =>
            selectedUnitFilter.find(unit => unit.unitId === innerShift.unitId) &&
            selectedUnitFilter
              ?.find(unit => unit.unitId === innerShift.unitId)
              ?.positions?.includes(innerShift?.positionDetail?.[0]?.id)
        )
        .filter(innerShift => statusFilter.includes(checkShiftStatus(innerShift, sessionUserTimeZone || 'UTC'))),
    }))
    ?.reduce((acc, curr) => acc + Number(curr?.shifts?.length), 0);

  const monthlyShifts = [...shifts]?.filter(
    shift => new Date(shift?.date).getMonth() === new Date(startDate).getMonth()
  );

  const monthlyTotalShiftCount = monthlyShifts?.reduce((acc, curr) => acc + Number(curr?.shifts?.length), 0);

  const monthlyFilteredShiftCount = [...shifts]
    ?.filter(shift => new Date(shift?.date).getMonth() === new Date(startDate).getMonth())
    ?.map(shift => ({
      ...shift,
      shifts: shift.shifts
        .filter(
          innerShift =>
            selectedUnitFilter.find(unit => unit.unitId === innerShift.unitId) &&
            selectedUnitFilter
              ?.find(unit => unit.unitId === innerShift.unitId)
              ?.positions?.includes(innerShift?.positionDetail?.[0]?.id)
        )
        .filter(innerShift => statusFilter.includes(checkShiftStatus(innerShift, sessionUserTimeZone || 'UTC'))),
    }))
    ?.reduce((acc, curr) => acc + Number(curr?.shifts?.length), 0);

  const getFilteredSummary = (shiftData: any) => {
    const filteredSummary = [...shiftsSummary]
      ?.map(summary => {
        const shiftsUnitPositions = [...shiftData].reduce((acc: any, data: any) => {
          data.shifts.forEach((shift: {unitId: any; positionDetail: any[]}) => {
            const existingUnit = acc.find((item: {unitId: any}) => item.unitId === shift.unitId);
            if (existingUnit) {
              // Add position ids to existing unit
              shift.positionDetail.forEach(position => {
                if (!existingUnit.positions.includes(position.id)) {
                  existingUnit.positions.push(position.id);
                }
              });
            } else {
              // Create a new unit entry
              acc.push({
                unitId: shift.unitId,
                positions: shift.positionDetail.map(position => position.id),
              });
            }
          });
          return acc;
        }, []);

        const unit: any = shiftsUnitPositions?.find((unitPosition: any) => unitPosition?.unitId === summary?.id);

        return {
          ...summary,
          positions: [...summary.positions]?.filter(e => unit?.positions?.includes(e?.id)),
        };
      })
      .filter(unit => unit.positions.length > 0);

    // if (filteredSummary?.length !== selectedUnitFilter?.length)
    //   handleSelectedUnitFilter(filteredSummary?.map(e => ({unitId: e?.id, positions: e?.positions?.map(x => x?.id)})));

    return filteredSummary;
  };

  // shifts for count

  const weeklyShiftsForUnitFilter = [...weeklyShifts].map(shift => ({
    ...shift,
    shifts: shift.shifts.filter(innerShift =>
      statusFilter.includes(checkShiftStatus(innerShift, sessionUserTimeZone || 'UTC'))
    ),
  }));

  const monthlyShiftsForUnitFilter = [...monthlyShifts].map(shift => ({
    ...shift,
    shifts: shift.shifts.filter(innerShift =>
      statusFilter.includes(checkShiftStatus(innerShift, sessionUserTimeZone || 'UTC'))
    ),
  }));

  const monthlyShiftForStatusFilter = [...monthlyShifts]?.map(shift => ({
    ...shift,
    shifts: shift.shifts.filter(
      innerShift =>
        selectedUnitFilter.find(unit => unit.unitId === innerShift.unitId) &&
        selectedUnitFilter
          ?.find(unit => unit.unitId === innerShift.unitId)
          ?.positions?.includes(innerShift?.positionDetail?.[0]?.id)
    ),
  }));

  const weeklyShiftsForStatusFilter = [...weeklyShifts]?.map(shift => ({
    ...shift,
    shifts: shift.shifts.filter(
      innerShift =>
        selectedUnitFilter.find(unit => unit.unitId === innerShift.unitId) &&
        selectedUnitFilter
          ?.find(unit => unit.unitId === innerShift.unitId)
          ?.positions?.includes(innerShift?.positionDetail?.[0]?.id)
    ),
  }));

  return (
    <Flex
      className="month-range-container"
      justifyContent="space-between"
      gap="0.5rem"
      alignItems="center"
      pb="3"
      maxWidth={isMonthlyFilter ? '100%' : '68rem'}
      width="100%"
    >
      <Flex gap="0.5rem" alignItems="center">
        {!isMonthlyFilter && (
          <Button
            padding="0"
            variant="ghost"
            onClick={() => {
              setPrintModalData({isOpen: true});
            }}
            _hover={{bg: 'transparent'}}
            height="min-content"
          >
            <Flex alignItems="center">
              <IoPrintOutline />
              Print
            </Flex>
          </Button>
        )}
      </Flex>

      <Flex>{rest.calenderComponent}</Flex>

      <Flex gap="0.5rem">
        {(shiftsSummary?.[0]?.positions?.length > 1 || shiftsSummary?.length > 1) && (
          <ShiftUnitFilter
            shiftsSummary={isMonthlyFilter ? getFilteredSummary(monthlyShifts) : getFilteredSummary(weeklyShifts)}
            filteredShiftCount={isMonthlyFilter ? monthlyFilteredShiftCount : weeklyFilteredShiftCount}
            totalShiftCount={isMonthlyFilter ? monthlyTotalShiftCount : weeklyTotalShiftCount}
            selectedUnitFilter={selectedUnitFilter}
            handleSelectedUnitFilter={handleSelectedUnitFilter}
            shifts={isMonthlyFilter ? monthlyShiftsForUnitFilter : weeklyShiftsForUnitFilter}
            startDate={startDate}
          />
        )}

        <ShiftStatusFilter
          filteredShiftCount={isMonthlyFilter ? monthlyFilteredShiftCount : weeklyFilteredShiftCount}
          totalShiftCount={isMonthlyFilter ? monthlyTotalShiftCount : weeklyTotalShiftCount}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          shifts={isMonthlyFilter ? monthlyShiftForStatusFilter : weeklyShiftsForStatusFilter}
          timeZone={sessionUserTimeZone}
        />

        <Menu autoSelect={false} isOpen={isOpen === 'legend'}>
          <MenuButton
            height="28px"
            width="28px"
            onMouseEnter={() => setIsOpen('legend')}
            onMouseLeave={() => setIsOpen('')}
          >
            <img src={InfoIcon} alt=" " />
          </MenuButton>
          <MenuList>
            <MenuGroup title="Legend">
              {legends?.map(legend => (
                <MenuItem _hover={{background: 'transparent'}}>
                  <Flex width="100%" alignItems="center" justifyContent="space-between">
                    <Text fontSize="0.8rem">{legend?.legend}</Text>
                    <Box
                      background={legend.background}
                      height="14px"
                      width="14px"
                      borderRadius="50%"
                      border={legend.border}
                    >
                      {' '}
                    </Box>
                  </Flex>
                </MenuItem>
              ))}
            </MenuGroup>
          </MenuList>
        </Menu>
      </Flex>
      {PrintModalData?.isOpen && (
        <PrintPreviewModal
          handleIsModalOpen={setPrintModalData}
          shifts={shifts?.filter(shift => datesOfWeek?.includes(shift?.date))}
          units={shiftsSummary}
          selectedUnitFilter={selectedUnitFilter}
          timeZone={sessionUserTimeZone || 'UTC'}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </Flex>
  );
};

export default CalenderFilters;
