import {Flex, Text, Box, Skeleton} from '@chakra-ui/react';
import React from 'react';

const BreakSkeleton = () => (
  <Flex flexDirection="column" gap=".5rem">
    <Text fontWeight="600">Break</Text>
    <Box marginBottom="1.5rem" width={['inherit', '12.5rem']}>
      <Skeleton height="2.5rem" w="12.5rem" borderRadius=".5rem" />
    </Box>
  </Flex>
);

export default BreakSkeleton;
