import React from 'react';

import {Box, Flex, Text} from '@chakra-ui/react';
import NDReactSelect from '../NDReactSelect/NDReactSelect';
import QualificationsSkeleton from './QualificationsSkeleton/QualificationsSkeleton';

import {SingleValue} from '../../../routes/ShiftTemplates/ShiftTemplates.types';

import {Props} from './QualificationsSelector.types';

const QualificationsSelector = ({
  qualificationsDefaultValues,
  groupedQualificationOptions,
  shiftTemplate,
  isEditable,
  errors,
  register,
  currentQualifications,
  setValue,
  loading,
}: Props) => {
  const handleQualificationSelection = (qualificationArray: SingleValue[]) => {
    const tempQualification = qualificationArray.map((qualificationObject: SingleValue) => ({
      id: qualificationObject.value,
      name: qualificationObject.label,
    }));

    setValue('qualifications', tempQualification, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
  };

  if (loading) {
    return <QualificationsSkeleton />;
  }

  return (
    <Flex flexDirection="column" gap=".5rem" w="100%">
      <Flex gap=".5rem">
        <Text fontWeight="600">Qualifications</Text>
        <Text color="#718096">(Optional)</Text>
      </Flex>

      <Box w={['inherit', '24rem']}>
        <NDReactSelect
          {...register('qualifications')}
          name="qualifications"
          defaultValue={qualificationsDefaultValues}
          placeholder="Select Qualifications"
          isMulti
          value={currentQualifications}
          isDisabled={!!shiftTemplate && !isEditable}
          options={groupedQualificationOptions}
          onChange={(e: SingleValue[]) => {
            handleQualificationSelection(e);
          }}
        />
      </Box>
      {errors?.qualifications && (
        <Text color="red.500" fontSize="sm">
          {errors?.qualifications?.message}
        </Text>
      )}
      <Text width="20rem" marginBottom="1.5rem" color="#718096">
        Choose the credentials needed to work the shift.
      </Text>
    </Flex>
  );
};

export default QualificationsSelector;
