import {Flex, Text, Box, Skeleton} from '@chakra-ui/react';
import React from 'react';

const TimeSkeleton = () => (
  <Flex flexDirection="column" gap=".5rem">
    <Text fontWeight="600">Time</Text>
    <Flex marginBottom="1.5rem" gap={['3rem', '2rem']} alignItems="center">
      <Flex flexDirection="column" gap=".5rem">
        <Box
          w="fit-content"
          border="0.0625rem solid 
rgba(226, 232, 240, 1)"
          padding=".5rem"
          borderRadius="0.375rem"
        >
          <Skeleton height="2.5rem" width="3.75rem" borderRadius=".375rem" />
        </Box>
      </Flex>
      <Text>to</Text>
      <Flex flexDirection="column" gap=".5rem">
        <Box
          w="fit-content"
          border="0.0625rem solid 
rgba(226, 232, 240, 1)"
          padding=".5rem"
          borderRadius="0.375rem"
        >
          <Skeleton height="2.5rem" width="3.75rem" borderRadius=".375rem" />
        </Box>
      </Flex>
    </Flex>
  </Flex>
);

export default TimeSkeleton;
