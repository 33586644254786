import React from 'react';
import {Alert, AlertTitle, AlertIcon, AlertDescription, Flex, Box, CloseButton, Button} from '@chakra-ui/react';

import {AlertTheme, Props} from './ActionableAlert.types';

const ActionableAlert = ({
  btnText,
  alertType = 'primary',
  title,
  bodyText,
  secondaryBtnText,
  isDismissed,
  dismissAlert,
  handleClick,
  hasButton = false,
}: Props): JSX.Element => {
  const alertTheme: AlertTheme = {
    danger: {
      bg: '#FFF5F5',
      color: '#C53030',
    },
    warning: {
      bg: '#FFFAF0',
      color: '#DD6B20',
    },
    secondary: {
      bg: '#E4F2FF',
      color: '#3377DC',
    },
    primary: {
      bg: '#E8EAF1',
      color: '#38427E',
    },
    success: {
      bg: '#C6F6D5',
      color: '#38A169',
    },
  };

  return (
    <Box>
      {!isDismissed && (
        <Alert
          bg={alertTheme[alertType].bg}
          borderRadius="6px"
          borderLeft={`4px solid ${alertTheme[alertType].color}`}
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          paddingBottom="1.5rem"
        >
          <Flex>
            <AlertIcon color={alertTheme[alertType].color} marginRight="1rem" />
            <Flex flexDirection="column">
              <AlertTitle>{title}</AlertTitle>
              <Flex flexDirection="column">
                <AlertDescription>{bodyText}</AlertDescription>
                {hasButton && (
                  <Box mt="1rem">
                    <Button bg={alertTheme[alertType].color} onClick={handleClick}>
                      {btnText}
                    </Button>
                    <Button variant="ghost" color={alertTheme[alertType].color} onClick={dismissAlert}>
                      {secondaryBtnText}
                    </Button>
                  </Box>
                )}
              </Flex>
            </Flex>
          </Flex>
          <CloseButton onClick={dismissAlert} size="sm" alignSelf="flex-start" />
        </Alert>
      )}
    </Box>
  );
};

export default ActionableAlert;
