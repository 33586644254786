import {Box} from '@chakra-ui/react';
import React from 'react';
// import {MapShift} from '../../Shift.types';
import {checkShiftStatus} from './helpers';

const renderStatusIndicator = (shift: any, timeZone: string) => {
  const legends = [
    {legend: 'upcomingUnfilled', background: '#f1ddcc', border: ''},
    {legend: 'actionRequired', background: '#2970FF', border: ''},
    {legend: 'filledCompletely', background: '#5AC68D', border: ''},
    {legend: 'filledPartially', background: 'white', border: '3px solid #5AC68D'},
    {legend: 'cancelled', background: '#D9D9D980', border: ''},
    {legend: 'pastUnfilled', background: '#f1ddcc', border: ''},
  ];

  const legend = legends?.find(legendItem => legendItem.legend === checkShiftStatus(shift, timeZone || 'UTC'));
  return (
    <Box background={legend?.background} height="14px" width="14px" borderRadius="50%" border={legend?.border}>
      {' '}
    </Box>
  );
};

export default renderStatusIndicator;
