/* eslint-disable no-nested-ternary */
import React from 'react';

import {useFlags} from 'launchdarkly-react-client-sdk';
import {Box, Flex, Icon, Text, useDisclosure} from '@chakra-ui/react';

import uuid from 'react-uuid';
import moment from 'moment';
import {MdOutlineHistory} from 'react-icons/md';
import {Props} from './ShiftTemplateCard.types';
import {getFullDayName} from '../constants';
import {formatToDollarAmount} from '../../../shared/utils/helperFunctions';
import {useTimeZone} from '../../Shifts/CreateShift/appSelectors';
import ActivityHistoryDrawer from '../../../shared/components/ActivityHistory/ActivityHistoryDrawer';

const ShiftTemplateCard: React.FC<Props> = ({
  onClick,
  templateName,
  position,
  bonus,
  specificDay,
  startTime,
  endTime,
  numOfOpenings,
  qualifications,
  unit,
  templateId,
}: Props) => {
  const timeZone = useTimeZone();

  const getOpeningsMessage = (openings: number) => {
    if (!openings) {
      return 'Not set';
    }
    if (openings === 1) {
      return `${openings} opening`;
    }
    return `${openings} openings`;
  };

  const openingsText = getOpeningsMessage(numOfOpenings);
  const {showRates} = useFlags();

  const standardOffset = moment.tz('2024-10-01', timeZone).utcOffset();

  // const startTimeToDisplay = moment.utc(startTime, 'HH:mm').tz(timeZone)?.format('hh:mm A')
  // const endTimeToDisplay = moment.utc(startTime, 'HH:mm').tz(timeZone)?.format('hh:mm A')

  const startTimeToDisplay = moment.utc(startTime, 'HH:mm').add(standardOffset, 'minutes')?.format('hh:mm A');
  const endTimeToDisplay = moment.utc(endTime, 'HH:mm').add(standardOffset, 'minutes')?.format('hh:mm A');

  const {isOpen: isHistoryDrawerOpen, onOpen: onHistoryDrawerOpen, onClose: onHistoryDrawerClose} = useDisclosure();

  return (
    <Box
      background="linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #E8EAF1"
      borderRadius="4px"
      padding={['1rem', '1rem 1.5rem']}
      onClick={onClick}
      cursor="pointer"
    >
      <Flex justifyContent="space-between" marginBottom="1.5rem">
        <Box width="100%">
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <Text color="#313871" fontWeight="600" fontSize="16px" marginBottom=".2rem" maxW="12rem">
              {templateName}
            </Text>
            <Flex
              gap="0.3rem"
              cursor="pointer"
              onClick={e => {
                e.stopPropagation();
                onHistoryDrawerOpen();
              }}
            >
              <Icon as={MdOutlineHistory} boxSize="18px" color="#313871" marginTop="0.1rem" />
            </Flex>
          </Flex>
          <Flex maxW="9rem" flexWrap="wrap">
            <Text color="#7C86B1" fontWeight="600" fontSize="12px">
              {unit.toUpperCase()} /
            </Text>
            {position.map((pos, index) => (
              <Flex key={uuid()}>
                <Text margin="0rem .1rem" color="#7C86B1" fontWeight="600" fontSize="12px">
                  {pos.name}
                  {index < position.length - 1 && ' or'}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Box>

        <Flex flexDirection="column" display={showRates ? 'inherit' : 'none'}>
          {position.map(pos => (
            <Flex key={uuid()} justifyContent="flex-end">
              <Text
                marginRight=".5rem"
                color="#313871"
                fontWeight="600"
                textAlign="end"
                fontSize="14px"
                marginBottom=".2rem"
                key={uuid()}
              >
                {`${pos.name}`}
              </Text>
              <Text
                color="#313871"
                fontWeight="600"
                textAlign="end"
                alignSelf="flex-end"
                fontSize="14px"
                marginBottom=".2rem"
                key={uuid()}
              >
                {`${formatToDollarAmount(pos.rate / 100)}/hr`}
              </Text>
            </Flex>
          ))}
          {bonus > 0 && (
            <Text
              fontSize="12px"
              color="#313871"
              fontWeight="400"
              backgroundColor="#BEDFFF"
              borderRadius="8px"
              padding="2px 8px"
              w="fit-content"
              alignSelf="flex-end"
            >
              {`${formatToDollarAmount(bonus / 100)} bonus`}
            </Text>
          )}
        </Flex>
      </Flex>
      <Text color="#A0AEC0">DAY</Text>
      <Flex alignItems="center" marginBottom=".8rem" gap=".8rem">
        <Text fontSize="14px" color="#313871" opacity={!specificDay ? '0.5' : 'inherit'}>
          {!specificDay ? 'Not set' : getFullDayName(specificDay)}
        </Text>
      </Flex>
      <Text color="#A0AEC0">TIME</Text>
      <Flex alignItems="center" marginBottom=".8rem" gap=".8rem">
        <Text fontSize="14px" color="#313871">
          {startTimeToDisplay} - {endTimeToDisplay}
        </Text>
      </Flex>
      <Text color="#A0AEC0">OPENINGS</Text>

      <Flex alignItems="center" marginBottom=".8rem" gap=".8rem">
        <Text fontSize="14px" color="#313871" opacity={!numOfOpenings ? '0.5' : 'inherit'}>
          {openingsText}
        </Text>
      </Flex>
      <Text color="#A0AEC0">QUALIFICATIONS</Text>
      <Flex alignItems="center">
        {qualifications.length ? (
          qualifications.map(qualification => (
            <Text fontSize="14px" color="#313871" key={uuid()} mr="0.5rem">
              {qualification.name}
            </Text>
          ))
        ) : (
          <Text fontSize="14px" color="#313871" opacity="0.5">
            Not set
          </Text>
        )}
      </Flex>

      <ActivityHistoryDrawer
        isOpen={isHistoryDrawerOpen}
        onClose={onHistoryDrawerClose}
        resourceId={templateId}
        resourceType="SHIFT_TEMPLATE"
      />
    </Box>
  );
};

export default ShiftTemplateCard;
