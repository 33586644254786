import moment from 'moment';

const processShifts = (shift: any) => {
  const processedShift = shift.shifts.map((innerShift: {openings: any}) => {
    const numOfCompleted =
      innerShift?.openings?.filter((opening: {status: string}) => opening?.status === 'Completed')?.length || 0;
    const numOfConfirmed =
      innerShift?.openings?.filter((opening: {status: string}) => opening?.status === 'Confirmed')?.length || 0;
    const numOfPendingReview =
      innerShift?.openings?.filter((opening: {status: string}) => opening?.status === 'PendingReview')?.length || 0;
    const numOfPendingCheckIn =
      innerShift?.openings?.filter((opening: {status: string}) => opening?.status === 'PendingCheckIn')?.length || 0;
    const numOfPendingClockIn =
      innerShift?.openings?.filter((opening: {status: string}) => opening?.status === 'PendingClockIn')?.length || 0;
    const posted =
      innerShift?.openings?.filter((opening: {status: string}) => opening?.status !== 'Cancelled')?.length || 0;
    const confirmed = numOfConfirmed + numOfCompleted + numOfPendingReview + numOfPendingCheckIn + numOfPendingClockIn;
    return {...innerShift, posted, confirmed};
  });
  return {...shift, shifts: processedShift};
};

const checkShiftStatus = (shift: any, timeZone: string) => {
  if (!shift?.openings?.length) {
    return 'cancelled';
  }
  if (shift?.openings?.every((opening: {status: string}) => opening.status === 'Cancelled')) return 'cancelled';

  const allShiftOpenings = shift?.openings ? [...shift.openings] : [];

  const nonCancelledOpenings = allShiftOpenings?.filter((opening: {status: string}) => opening.status !== 'Cancelled');
  // status : 'Filled Completely'
  if (
    nonCancelledOpenings?.every((opening: {status: string}) =>
      ['Completed', 'Confirmed', 'PendingReview', 'PendingCheckIn', 'PendingClockIn'].includes(opening.status)
    )
  )
    return 'filledCompletely';

  // status : 'Action Required'

  const pastEndTime = moment().isAfter(moment.tz(shift?.endTime, timeZone));

  if (
    !pastEndTime &&
    shift?.openings?.some((opening: {status: string}) => ['OpenedWithApplicants'].includes(opening.status))
    // (pastEndTime && shift.openings.some((opening: {status: string}) => ['PendingReview'].includes(opening.status)))
  )
    return 'actionRequired';

  // status : 'Partially Filled'

  if (
    shift?.openings?.some((opening: {status: string}) =>
      ['Completed', 'PendingReview', 'Confirmed', 'PendingCheckIn', 'PendingClockIn'].includes(opening.status)
    )
  )
    return 'filledPartially';

  // status : 'Cancelled'

  if (pastEndTime) return 'pastUnfilled';

  return 'upcomingUnfilled';
};

const renderShiftStatusText = (shift: any) => {
  if (shift?.openings?.length) {
    const isAllCancelled = shift?.openings?.every((opening: {status: string}) => opening.status === 'Cancelled');

    const pendingReviewCount = shift.openings?.filter(
      (opening: {status: string}) => opening.status === 'PendingReview'
    ).length;
    const allOpenedWithApplicants = shift.openings?.every(
      (opening: {status: string}) => opening.status === 'OpenedWithApplicants'
    );

    // Filter out cancelled openings before counting filled and total openings
    const nonCancelledOpenings =
      shift?.openings && [...shift.openings]?.filter((opening: {status: string}) => opening.status !== 'Cancelled');

    const filledCount = nonCancelledOpenings?.filter((opening: {status: string}) =>
      ['Confirmed', 'Completed', 'PendingCheckIn', 'PendingClockIn', 'PendingReview'].includes(opening.status)
    ).length;

    const completedCount = nonCancelledOpenings?.filter(
      (opening: {status: string}) => opening.status === 'Completed'
    )?.length;

    const totalOpenings = nonCancelledOpenings?.length; // Total openings excluding cancelled ones

    const cancelledOpeningsCount = shift?.openings?.filter(
      (opening: {status: string}) => opening.status === 'Cancelled'
    )?.length;

    const unfilledCount = shift?.openings?.filter((opening: {status: string}) =>
      ['Opened', 'OpenedWithApplicants', 'Selected']?.includes(opening?.status)
    )?.length;

    // applicant count for substatus
    const ndPros = shift?.openings?.map((opening: {ndProId: any}) => opening?.ndProId);
    const filteredApplicantsCount = shift?.applicants
      ?.filter(
        (applicant: {ndProId: any}, index: any, self: any[]) =>
          self.findIndex(t => t.ndProId === applicant.ndProId) === index
      )
      ?.filter((applicant: {ndProId: any}) => !ndPros?.includes(applicant?.ndProId))
      ?.filter((applicant: {status: string}) => applicant?.status === 'Applied')?.length;

    if (isAllCancelled) {
      return {status: 'Cancelled'};
    }

    if (pendingReviewCount > 0) {
      return {
        status: 'Completed-Pending Rating',
        subStatus: `${unfilledCount > 0 ? `${filledCount} of ${totalOpenings} Filled` : ''} ${
          cancelledOpeningsCount > 0 ? `( ${cancelledOpeningsCount} Cancelled )` : ''
        }`,
      };
    }

    if (completedCount > 0) {
      return {
        status: `Completed`,
        subStatus: `${unfilledCount > 0 ? `${filledCount} of ${totalOpenings} Filled` : ''} ${
          cancelledOpeningsCount > 0 ? `( ${cancelledOpeningsCount} Cancelled )` : ''
        }`,
      };
    }

    if (allOpenedWithApplicants) {
      return {
        status: `0 of ${totalOpenings} Filled`,
        subStatus: `${filteredApplicantsCount > 0 ? `${filteredApplicantsCount} Applicants` : ''} ${
          cancelledOpeningsCount > 0 ? `( ${cancelledOpeningsCount} Cancelled )` : ''
        }`,
      };
    }

    return {
      status: `${filledCount} of ${totalOpenings} Filled`,
      subStatus: `${filteredApplicantsCount > 0 ? `${filteredApplicantsCount} Applicants` : ''} ${
        cancelledOpeningsCount > 0 ? `( ${cancelledOpeningsCount} Cancelled )` : ''
      }`,
    };
  }
  return {status: ''};
};



const startOfDay = (date: Date) => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

const endOfDay = (date: Date) => {
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  date.setMilliseconds(999);
  return date;
};

export {processShifts, checkShiftStatus, renderShiftStatusText, startOfDay, endOfDay};
