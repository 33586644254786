import * as yup from 'yup';

const schema = yup.object().shape({
  unit: yup.object().required('Unit is required'),
  position: yup.array().min(1).required('Position is required'),
  startTime: yup.string().required('Start time is required'),
  endTime: yup.string().required('End time is required'),
  day: yup.array(),
  date: yup.array().of(yup.string()).min(1, 'At least one date required').required('Date is required'),
  bonus: yup.number(),
  numOfOpenings: yup.number(),
  break: yup.string(),
  description: yup.string().nullable(),
  clockInInstruction: yup.string().nullable(),
  clockOutInstruction: yup.string().nullable(),
  dayAndOpenings: yup.array(),
});

export default schema;
