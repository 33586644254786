import {extendTheme, withDefaultColorScheme} from '@chakra-ui/react';

import ndColors from './colors/ndColors';
import {ButtonStyles as Button} from './components/buttonStyles';

//  ToDo: create a color palate file
const newTheme = extendTheme(
  {
    colors: {
      ...ndColors,
    },
    components: {
      Button,
      ReactSelect: {
        variants: {
          ndReactSelect: {
            styles: {
              control: (baseStyles: any) => ({
                ...baseStyles,
                background: 'red',
                color: 'black',
              }),
            },
          },
        },
      },
      Table: {
        variants: {
          ndTable: {
            tr: {
              _odd: {
                background: '#F8F8F8',
              },
            },
          },
        },
      },
    },
  },
  withDefaultColorScheme({colorScheme: 'primary'})
);

export default newTheme;
