import {useMutation, gql} from '@apollo/client';
import {MutationHookOptions} from '@apollo/react-hooks';
import {
  cancelShiftOpeningFields,
  createFavoriteFields,
  deleteShiftFields,
  getSearchShiftFields,
  removeFavoriteFields,
  updateApplicantStatusFields,
} from './fields';
import {
  DeleteShiftData,
  DeleteShiftVariables,
  CancelShiftOpeningResult,
  GetSearchShiftsResult,
  ShiftInput,
  CancelShiftInput,
  CreateFavoriteResult,
  CreateFavoriteInput,
  RemoveFavoriteResult,
  RemoveFavoriteInput,
} from './types';
import useAppDispatch from '../../hooks/useAppDispatch';
import {
  getCancelShiftOpening,
  getCreateFavorite,
  getRemoveFavorite,
  getSearchShifts,
  setCancelShiftOpening,
  setCreateFavorite,
  setRemoveFavorite,
  setSearchShifts,
} from '../../slices/calShiftSlice';

import {useApolloClients} from '../../../core/providers/ApolloContextProvider';

const searchShift = (fields: string = getSearchShiftFields) => gql`
    mutation SearchShift($searchShift: ShiftInput!){
        searchShift(newShift: $searchShift){
            ${fields}
        }
    }`;

export const cancelShiftOpening = (fields: string = cancelShiftOpeningFields) => gql`
    mutation CancelShiftOpening($shiftId: String!, $shiftOpeningId: String!, $input: CancelShiftInput!) {
      cancelShiftOpening(shiftId: $shiftId, shiftOpeningId: $shiftOpeningId, input: $input) {
        ${fields}
      }
    }
  `;

export const createFavorite = (fields: string = createFavoriteFields) => gql`
  mutation CreateFavorite($cf: CreateFavoriteInput!) {
    createFavorite(input: $cf) {
      ${fields}
    }
  }
`;

export const removeFavorite = (fields: string = removeFavoriteFields) => gql`
    mutation RemoveFavorite($facilityId: String!, $ndProId: String!) {
      removeFavorite(facilityId: $facilityId, ndProId: $ndProId) {
        ${fields}
      }
    }
`;

export const useCreateFavorite = (
  options?: MutationHookOptions<CreateFavoriteResult, CreateFavoriteInput>,
  fields?: string
) => {
  const {client} = useApolloClients();
  const dispatch = useAppDispatch();
  dispatch(getCreateFavorite());

  return useMutation<CreateFavoriteResult, CreateFavoriteInput>(createFavorite(fields), {
    ...options,
    client,
    onCompleted: data => {
      dispatch(setCreateFavorite(data.getSummaryOfCreateFavorite));
    },
    onError: err => {
      console.log('err from createFavoritem', err);
    },
  });
};

export const useRemoveFavorite = (
  options?: MutationHookOptions<RemoveFavoriteResult, RemoveFavoriteInput>,
  fields?: string
) => {
  const {client} = useApolloClients();
  const dispatch = useAppDispatch();
  dispatch(getRemoveFavorite());

  return useMutation<RemoveFavoriteResult, RemoveFavoriteInput>(removeFavorite(fields), {
    ...options,
    client,
    onCompleted: data => {
      dispatch(setRemoveFavorite(data.getSummaryOfRemoveFavorite));
    },
    onError: err => {
      console.log('err from removeFavorite', err);
    },
  });
};

export const useCancelShiftOpening = (
  options?: MutationHookOptions<CancelShiftOpeningResult, CancelShiftInput>,
  fields?: string
) => {
  const {client} = useApolloClients();
  const dispatch = useAppDispatch();
  dispatch(getCancelShiftOpening());

  return useMutation<CancelShiftOpeningResult, CancelShiftInput>(cancelShiftOpening(fields), {
    ...options,
    client,
    onCompleted: data => {
      dispatch(setCancelShiftOpening(data.getSummaryOfCancelShiftOpening));
    },
    onError: err => {
      console.log('err from cancelShiftOpening', err);
    },
  });
};

export const useSearchShifts = (options?: MutationHookOptions<GetSearchShiftsResult, ShiftInput>, fields?: string) => {
  const {client} = useApolloClients();
  const dispatch = useAppDispatch();
  dispatch(getSearchShifts());

  return useMutation<GetSearchShiftsResult, ShiftInput>(searchShift(fields), {
    ...options,
    client,
    onCompleted: data => {
      dispatch(setSearchShifts(data.searchShift));
    },
    onError: err => {
      console.log('err from searchShifts', err);
    },
  });
};

const deleteShifts = (fields: string = deleteShiftFields) => gql`

    mutation DeleteShift($deleteShift: DeleteShiftInput!)
    {
        deleteShift(deleteShiftInput: $deleteShift)
        {
            ${fields}
        }
    }`;

export const useDeleteShift = (
  options?: MutationHookOptions<DeleteShiftData, DeleteShiftVariables>,
  fields?: string,
  onCompletedFn?: () => void
) => {
  const {client} = useApolloClients();
  return useMutation<DeleteShiftData, DeleteShiftVariables>(deleteShifts(fields), {
    ...options,
    client,
    onCompleted: () => {
      if (onCompletedFn) {
        onCompletedFn();
      }
    },
    onError: err => {
      console.log('err from createUser', err);
    },
  });
};

export const updateApplicantStatus = (fields: string = updateApplicantStatusFields) => gql`
  mutation UpdateApplicantStatus($id: Int!, $select: Boolean!, $shiftIds: [Int!]!) {
    updateApplicantStatus(id: $id, select: $select, shiftIds: $shiftIds) {
     ${fields}
    }
  }
`;

export const useUpdateApplicantStatus = (options?: MutationHookOptions<any, any>, onCompletedFn?: () => void) => {
  const {legacyClient} = useApolloClients();
  return useMutation<any, any>(updateApplicantStatus(), {
    ...options,
    client: legacyClient,
    onCompleted: () => {
      if (onCompletedFn) {
        onCompletedFn();
      }
    },
    onError: err => {
      console.log('err from updateApplicant Status', err);
    },
  });
};

const setRelationToFacility = () => gql`
  mutation SetRelationToFacility($nurseId: Int, $reason: String, $relation: String) {
    data: setRelationToFacility(nurseId: $nurseId, reason: $reason, relation: $relation) {
      success
      relation
      reason
      nurseId
    }
  }
`;

export const useSetRelationToFacility = (options?: MutationHookOptions<any, any>, onCompletedFn?: () => void) => {
  const {legacyClient} = useApolloClients();
  return useMutation<any, any>(setRelationToFacility(), {
    ...options,
    client: legacyClient,
    onCompleted: () => {
      if (onCompletedFn) {
        onCompletedFn();
      }
    },
    onError: err => {
      console.log('err from set relation', err);
    },
  });
};

const removeRelationToFacility = () => gql`
  mutation RemoveRelationToFacility($nurseId: Int, $reason: String, $relation: String) {
    data: removeRelationToFacility(nurseId: $nurseId, reason: $reason, relation: $relation) {
      success
      relation
      reason
      nurseId
    }
  }
`;

export const useRemoveRelationToFacility = (options?: MutationHookOptions<any, any>, onCompletedFn?: () => void) => {
  const {legacyClient} = useApolloClients();
  return useMutation<any, any>(removeRelationToFacility(), {
    ...options,
    client: legacyClient,
    onCompleted: () => {
      if (onCompletedFn) {
        onCompletedFn();
      }
    },
    onError: err => {
      console.log('err from remove relation', err);
    },
  });
};

const archiveApplicants = () => gql`
  mutation updateApplicationArchiveDate($id: Int, $archiveDate: DateTime, $shiftIds: [Int]) {
    data: updateApplicationArchiveDate(id: $id, archiveDate: $archiveDate, shiftIds: $shiftIds) {
      success
      message
    }
  }
`;

export const useArchiveApplicants = (options?: MutationHookOptions<any, any>, onCompletedFn?: () => void) => {
  const {legacyClient} = useApolloClients();
  return useMutation<any, any>(archiveApplicants(), {
    ...options,
    client: legacyClient,
    onCompleted: () => {
      if (onCompletedFn) {
        onCompletedFn();
      }
    },
    onError: err => {
      console.log('err from remove relation', err);
    },
  });
};

export default {
  useDeleteShift,
  useSearchShifts,
  useCancelShiftOpening,
  useCreateFavorite,
  useRemoveFavorite,
  useUpdateApplicantStatus,
  useSetRelationToFacility,
  useRemoveRelationToFacility,
  useArchiveApplicants,
};
