import React, {createContext, useContext, ReactNode, useMemo} from 'react';
import {ApolloClient, NormalizedCacheObject} from '@apollo/client';

type ApolloClientsContextValue = {
  client: ApolloClient<NormalizedCacheObject>;
  legacyClient: ApolloClient<NormalizedCacheObject>;
};

type ApolloClientsProviderProps = {
  children: ReactNode;
  client: ApolloClient<NormalizedCacheObject>;
  legacyClient: ApolloClient<NormalizedCacheObject>;
};

// Create a context for the Apollo Clients with an initial undefined value
const ApolloClientsContext = createContext<ApolloClientsContextValue | undefined>(undefined);

// ApolloClientsProvider component that uses the React Context to provide Apollo Client instances to the component tree
export const ApolloClientsProvider: React.FC<ApolloClientsProviderProps> = ({children, client, legacyClient}) => {
  // Use useMemo to memoize the context value to prevent unnecessary re-renders
  const value = useMemo(() => ({client, legacyClient}), [client, legacyClient]);
  // Provide the Apollo Client instances to the component tree
  return <ApolloClientsContext.Provider value={value}>{children}</ApolloClientsContext.Provider>;
};
// Custom hook to access the Apollo Client instances from anywhere within the component tree
export const useApolloClients = (): ApolloClientsContextValue => {
  // Use useContext to access the Apollo Clients context
  const context = useContext(ApolloClientsContext);
  if (context === undefined) {
    throw new Error('useApolloClients must be used within a ApolloClientsProvider');
  }
  return context;
};
