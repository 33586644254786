import {useEffect, useMemo} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';

const useURLParamsUpdate = (params: Record<string, any>): void => {
  const navigate = useNavigate();
  const location = useLocation();

  const dependencies = useMemo(() => Object.values(params), [params]);

  useEffect(() => {
    const url = Object.entries(params)
      .filter(arr => arr?.[1] !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    if (location.search !== `?${url}`) {
      navigate(`${location.pathname}?${url}`, {replace: true});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname, location?.search,dependencies]);
};

export default useURLParamsUpdate;
