import React, {useEffect} from 'react';
import useLogout from "../../shared/hooks/useLogout";

const Logout = () => {
  const logout = useLogout()

  useEffect(() => {
    (async () => {
      await logout()
    })();
  }, [logout]);

  return <> </>;
};

export default Logout;
