/* eslint-disable no-underscore-dangle */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UpdateSettingsData} from 'shared/gql/Settings/types';

export type SettingState = {
  data: {
    covidVaccineRequired: boolean;
    allowSingleDoseVaccine: boolean;
    covidMedicalExemption: boolean;
    covidReligiousExemption: boolean;
    covidStatus: string;
    orientationMaterials?: {
      src?: string;
      title: string;
      data?: string;
      uuid?: string;
    };
  emailList: {email: string; __typename?: string}[];
  image: {
    src: string;
    data: string;
    title: string;
    __typename?: string;
  };
  phones: {
    phone: string;
    __typename?: string;
  }[];
  rates: any[];
  unitDescriptions : any
  };
  loading: boolean;
  error: string | null;
};

const initialState: SettingState = {
  data: {
    covidVaccineRequired: true,
    allowSingleDoseVaccine: false,
    covidMedicalExemption: false,
    covidReligiousExemption: false,
    covidStatus: '',
    orientationMaterials: {
      src: '',
      title: '',
      data: '',
    },
    emailList: [{ email: '' }],
    image: {
      src: '',
      data: '',
      title: '',
    },
    phones: [{ phone: '' }],
    rates: [],
    unitDescriptions: null
  },
  loading: false,
  error: null,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    getSettingsData: state => {
      state.loading = true;
      state.error = null;
    },
    setSettingsData: (state, action: PayloadAction<UpdateSettingsData>) => {
      const {emailList , image , orientationMaterials , unitDescriptions , phones, rates} = action.payload;
      state.data = {
        ...state.data,
        ...action.payload,
        emailList : emailList?.map(emailData =>({email : emailData.email})),
        unitDescriptions: unitDescriptions?.map((e: {__typename: any}) => {
          delete e?.__typename;
          return e;
        }),
        phones: phones?.map(phoneData => {
          delete phoneData?.__typename;
          return phoneData;
        }),
        rates: rates?.map(rate => {
          delete rate?.__typename;
          return rate;
        }),
        image: {
          src: image?.src,
          data: image?.data,
          title: image?.title,
        },
        orientationMaterials : {
          data: orientationMaterials.data,
          src: orientationMaterials.src,
          title: orientationMaterials?.title,
          uuid: orientationMaterials?.uuid,
        },

      };
      state.loading = false;
      state.error = null;
    },
  },
});

export const {getSettingsData, setSettingsData} = settingsSlice.actions;

export default settingsSlice.reducer;
