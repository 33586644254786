import React from 'react';
import {FormLabel, Input, Box, Text} from '@chakra-ui/react';

type Props = {
  register: any;
  label: string;
  placeholder: string;
  name: string;
  errors: any;
};

const FormInput = ({register, label, placeholder, name, errors}: Props) => (
  <>
    <FormLabel fontWeight="400" htmlFor={name}>
      {label}
    </FormLabel>
    <Box marginBottom="1rem">
      <Input
        placeholder={placeholder}
        _placeholder={{color: '#718096'}}
        border={errors?.[name] ? '1px solid #C53030' : '1px solid #E2E8F0'}
        borderRadius="6px"
        {...register(name)}
      />
      {errors?.[name] && (
        <Text fontSize="12px" color="#C53030" marginTop=".5rem">
          {errors[name].message as string}
        </Text>
      )}
    </Box>
  </>
);

export default FormInput;
