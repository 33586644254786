import {useMutation, gql} from '@apollo/client';
import {MutationHookOptions} from '@apollo/react-hooks';
import {createUserFields, deleteUserFields, updateUserFields, changePasswordFields} from './fields';
import {
  CreateUserVariables,
  CreateUserData,
  DeleteUserVariables,
  DeleteUserData,
  UpdateUserVariables,
  UpdateUserData,
  ChangePasswordData,
  ChangePasswordVariables,
} from './types';
import useAppDispatch from '../../hooks/useAppDispatch';
import {
  createUser as createUserAction,
  deleteUser as deleteUserAction,
  updateUser as updateUserAction,
} from '../../slices/usersSlice';
import {useApolloClients} from '../../../core/providers/ApolloContextProvider';

const createUser = (fields: string = createUserFields) => gql`
    
    mutation CreateUser($createUser: CreateUserInput!)
    {
        createUser(createUserInput: $createUser)
        {
            ${fields}
        }
    }`;

const useCreateUser = (
  options?: MutationHookOptions<CreateUserData, CreateUserVariables>,
  fields?: string,
  onCompletedFn?: () => void
) => {
  const {client} = useApolloClients();
  const dispatch = useAppDispatch();
  dispatch(createUserAction());
  return useMutation<CreateUserData, CreateUserVariables>(createUser(fields), {
    ...options,
    client,
    onCompleted: () => {
      if (onCompletedFn) {
        onCompletedFn();
      }
    },
    onError: err => {
      console.log('err from createUser', err);
    },
  });
};

const deleteUser = (fields: string = deleteUserFields) => gql`
    
    mutation DeleteUser($deleteUser: DeleteUserInput!)
    {
        deleteUser(deleteUserInput: $deleteUser)
        {
            ${fields}
        }
    }`;

export const useDeleteUser = (
  options?: MutationHookOptions<DeleteUserData, DeleteUserVariables>,
  fields?: string,
  onCompletedFn?: () => void
) => {
  const {client} = useApolloClients();
  const dispatch = useAppDispatch();
  dispatch(deleteUserAction());
  return useMutation<DeleteUserData, DeleteUserVariables>(deleteUser(fields), {
    ...options,
    client,
    onCompleted: () => {
      if (onCompletedFn) {
        onCompletedFn();
      }
    },
    onError: err => {
      console.log('err from createUser', err);
    },
  });
};

const updateUser = (fields: string = updateUserFields) => gql`
    
    mutation UpdateUser($updateUser: UpdateUserInput!)
    {
        updateUser(updateUserInput: $updateUser)
        {
            ${fields}
        }
    }`;

export const useUpdateUser = (
  options?: MutationHookOptions<UpdateUserData, UpdateUserVariables>,
  fields?: string,
  onCompletedFn?: () => void
) => {
  const {client} = useApolloClients();
  const dispatch = useAppDispatch();
  dispatch(updateUserAction());
  return useMutation<UpdateUserData, UpdateUserVariables>(updateUser(fields), {
    ...options,
    client,
    onCompleted: () => {
      if (onCompletedFn) {
        onCompletedFn();
      }
    },
    onError: err => {
      console.log('err from createUser', err);
    },
  });
};

const changePassword = (fields: string = changePasswordFields) => gql`
    
    mutation ResetPassword($resetPassword: ResetUserPasswordInput!)
    {
      resetPassword(resetUserPasswordInput: $resetPassword) 
        {
            ${fields}
        }
    }`;

export const useChangePassword = (
      options?: MutationHookOptions<ChangePasswordData, ChangePasswordVariables>,
      fields?: string,
      onCompletedFn?: () => void
    ) => {
      const {client} = useApolloClients();
      const dispatch = useAppDispatch();
      dispatch(updateUserAction());
      return useMutation<ChangePasswordData, ChangePasswordVariables>(changePassword(fields), {
        ...options,
        client,
        onCompleted: () => {
          if (onCompletedFn) {
            onCompletedFn();
          }
        },
        onError: err => {
          console.log('err from reset password', err);
        },
      });
    };

export default useCreateUser;
