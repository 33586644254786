import {Skeleton, Flex, Text} from '@chakra-ui/react';
import React from 'react';

const PositionSkeleton = () => (
  <>
    <Flex gap=".5rem" marginBottom=".5rem">
      <Skeleton height="2.5rem" width="3.75rem" borderRadius="1.25rem" />
      <Skeleton height="2.5rem" width="3.75rem" borderRadius="1.25rem" />
      <Skeleton height="2.5rem" width="3.75rem" borderRadius="1.25rem" />
      <Skeleton height="2.5rem" width="3.75rem" borderRadius="1.25rem" />
    </Flex>
    <Text color="#718096">Choose the position(s) that need to be covered</Text>
  </>
);

export default PositionSkeleton;
