import React from 'react';

import {Flex, Text, Button} from '@chakra-ui/react';
import uuid from 'react-uuid';
import {Props} from './DaySelector.types';

const DaySelector = ({
  days,
  shiftTemplate,
  isEditable,
  watch,
  handleDaySelection,
  errors,
  setValue,
  getValues,
}: Props) => (
  <>
    <Flex gap=".5rem">
      <Text fontWeight="600">Day</Text>
      <Text color="#718096">(Optional)</Text>
    </Flex>
    <Flex gap={['inherit', '1rem']} justifyContent={['space-between', 'flex-start']}>
      {days.map(day => (
        <Button
          type="button"
          key={uuid()}
          isDisabled={!!shiftTemplate && !isEditable}
          _disabled={{
            opacity: '0.6',
          }}
          variant="circle"
          color={day === watch('day') ? '#25285A' : '#616CA1'}
          border={day === watch('day') ? '2px solid #25285A' : 'none'}
          onClick={() => {
            handleDaySelection(day, setValue, getValues);
          }}
        >
          {day[0]}
        </Button>
      ))}
    </Flex>
    {errors.day && (
      <Text color="red.500" fontSize="sm">
        Day required.
      </Text>
    )}
    <Text width="20rem" color="#4A5568" marginBottom="1.5rem">
      You can choose only one day at a time.
    </Text>
  </>
);

export default DaySelector;
