import {configureStore} from '@reduxjs/toolkit';
import {
  authReducer,
  exampleReducer,
  shiftTemplateReducer,
  sidebarReducer,
  userReducer,
  shiftsReducer,
  shiftReducer,
  mobileReducer,
  messagesReducer,
  settingsReducer
} from './shared/slices';

const store = configureStore({
  reducer: {
    auth: authReducer,
    sidebar: sidebarReducer,
    shiftTemplates: shiftTemplateReducer,
    example: exampleReducer,
    usersData: userReducer,
    shifts: shiftReducer,
    calShifts: shiftsReducer,
    mobile: mobileReducer,
    allChannels: messagesReducer,
    settings: settingsReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export default store;
