import React from 'react';
import {Controller, useForm} from 'react-hook-form';

import Select, {GroupBase, OptionProps} from 'react-select';
import Option from './Option/Option';

import {Props} from './NDReactSelect.types';

const NDReactSelect = React.forwardRef(
  (
    {
      name,
      placeholder,
      options,
      isMulti,
      onChange,
      isDisabled,
      isReadOnly,
      defaultValue,
      isClearable,
      onInputChange,
      value,
      errors,
      loading,
      closeMenuOnSelect,
      ...props
    }: Props,
    ref: any
  ) => {
    const {control} = useForm();
    return (
      <Controller
        control={control}
        name={name}
        render={({field}) => (
          <Select
            {...props}
            {...field}
            ref={ref}
            closeMenuOnSelect={closeMenuOnSelect}
            isMulti={isMulti}
            isClearable={isClearable}
            placeholder={placeholder}
            {...(defaultValue && {defaultValue})}
            {...(isDisabled && {isDisabled})}
            {...(isReadOnly && {isReadOnly})}
            components={{
              Option: Option as React.ComponentType<OptionProps<any, boolean, GroupBase<any>>>,
            }}
            isLoading={loading}
            value={value}
            onChange={onChange}
            onInputChange={onInputChange}
            options={options}
            styles={{
              control: baseStyles => ({
                ...baseStyles,
                color: '#475392',
                minHeight: '2.5rem',
                _hover: {
                  cursor: 'pointer',
                },
                borderRadius: '0.375rem',
                borderColor: errors?.[name] ? '#C53030' : '#E2E8F0',
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem',
              }),
              singleValue: baseStyles => ({
                ...baseStyles,
                color: '#475392',
                backgroundColor: '#E8EAF1',
                borderRadius: '6px',
                width: 'fit-content',
                padding: '0.2rem 0.5rem',
              }),

              multiValue: baseStyles => ({
                ...baseStyles,
                color: '#475392',
                backgroundColor: '#E8EAF1',
                borderRadius: '6px',
              }),
              indicatorSeparator: baseStyles => ({
                ...baseStyles,
                display: 'none',
              }),
              option: baseStyles => ({
                ...baseStyles,
                fontWeight: '600',
              }),
              placeholder: baseStyles => ({
                ...baseStyles,
                color: '#718096',
              }),
            }}
          />
        )}
      />
    );
  }
);

export default NDReactSelect;
