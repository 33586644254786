import {createColumnHelper} from '@tanstack/react-table';
import {User} from '../../shared/gql/Users/types';

const columnHelper = createColumnHelper<User>();

const columns = [
  columnHelper.accessor('firstName', {
    cell: info => `${info.row.original.firstName} ${info.row.original.lastName}`,
    header: () => 'NAME',
    id: 'userName',
  }),
  columnHelper.accessor('email', {
    cell: info => info.getValue(),
    header: () => 'EMAIL',
    id: 'email',
  }),
  columnHelper.accessor('facilityName', {
    cell: info => info.getValue(),
    header: () => 'FACILITY',
    id: 'facility',
  }),
  columnHelper.accessor('role', {
    cell: info => info.getValue(),
    header: () => 'ROLE',
    id: 'role',
  }),
];

export default columns;
