import React from 'react';

import {Flex, Text, Checkbox, NumberInput, NumberInputField, Box} from '@chakra-ui/react';

import {Props} from './BonusSelector.types';

const BonusSelector = ({
  shiftTemplate,
  isEditable,
  hasBonus,
  handleHasBonus,
  formatToDollarAmount,
  defaultValues,
  watch,
  handleBonusChange,
  setValue,
  setHasBonus,
}: Props) => (
  <Flex flexDirection="column" gap=".5rem">
    <Text fontWeight="600">Bonus</Text>
    <Checkbox
      isDisabled={!!shiftTemplate && !isEditable}
      _disabled={{
        opacity: '0.8',
      }}
      isChecked={hasBonus}
      marginBottom={['inherit', '1rem']}
      onChange={() => handleHasBonus(setHasBonus, hasBonus, setValue)}
    >
      This shift has a bonus
    </Checkbox>
    {hasBonus && (
      <>
        <Text>Bonus amount</Text>
        <NumberInput
          precision={2}
          step={0.2}
          defaultValue={formatToDollarAmount(defaultValues.bonus)}
          value={typeof watch('bonus') === 'number' ? (watch('bonus') / 100).toFixed(2) : watch('bonus')}
          width="6.5rem"
          textAlign="center"
          isDisabled={!!shiftTemplate && !isEditable}
          _disabled={{
            opacity: '0.6',
          }}
          onChange={(e: any) => handleBonusChange(e, setValue)}
        >
          <NumberInputField />
          <Box
            opacity={!!shiftTemplate && !isEditable ? '0.4' : 'inherit'}
            position="absolute"
            left="0.5rem"
            top="50%"
            transform="translateY(-50%)"
          >
            $
          </Box>
        </NumberInput>
      </>
    )}
  </Flex>
);

export default BonusSelector;
