import {Flex, Text, Box, Skeleton} from '@chakra-ui/react';
import React from 'react';

const QualificationsSkeleton = () => (
  <Flex flexDirection="column" gap=".5rem" w="100%">
    <Flex gap=".5rem">
      <Text fontWeight="600">Qualifications</Text>
      <Text color="#718096">(Optional)</Text>
    </Flex>

    <Box w={['inherit', '24rem']}>
      <Skeleton height="2.5rem" w="24rem" borderRadius=".5rem" />
    </Box>

    <Text width="20rem" marginBottom="1.5rem" color="#718096">
      Choose the credentials needed to work the shift.
    </Text>
  </Flex>
);

export default QualificationsSkeleton;
