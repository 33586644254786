import React from 'react';
import {Flex, Text, Textarea} from '@chakra-ui/react';
import DescriptionInputSkelton from './DescriptionInputSkeleton/DescriptionInputSkelton';

import {Props} from './DescriptionInput.types';

const DescriptionInput = ({
  shiftTemplate,
  isEditable,
  register,
  title,
  name,
  defaultValue,
  subText,
  placeholder,
  loading,
}: Props) => {
  if (loading) {
    return <DescriptionInputSkelton title={title} subText={subText} />;
  }

  return (
    <Flex flexDirection="column" gap=".5rem">
      <Flex gap=".5rem">
        <Text fontWeight="600">{title}</Text>
        <Text color="#718096">(Optional)</Text>
      </Flex>
      <Textarea
        {...register(`${name}`)}
        defaultValue={defaultValue}
        height="7.5rem"
        placeholder={placeholder}
        w={['inherit', '25rem']}
        isDisabled={!!shiftTemplate && !isEditable}
        _disabled={{
          opacity: '0.6',
        }}
      />
      <Text color="#718096">{subText}</Text>
    </Flex>
  );
};

export default DescriptionInput;
