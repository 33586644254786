import {Skeleton, Flex, Text} from '@chakra-ui/react';
import React from 'react';

const EditDateSkeleton = () => (
  <>
    <Flex gap=".5rem">
      <Text fontWeight="600">Date</Text>
    </Flex>
    <Flex justifyContent="space-between" w="98%" height="5rem" paddingTop="1rem">
      <Skeleton height="4rem" width="3.75rem" borderRadius=".375rem" />
      <Skeleton height="4rem" width="3.75rem" borderRadius=".375rem" />
      <Skeleton height="4rem" width="3.75rem" borderRadius=".375rem" />
      <Skeleton height="4rem" width="3.75rem" borderRadius=".375rem" />
      <Skeleton height="4rem" width="3.75rem" borderRadius=".375rem" />
      <Skeleton height="4rem" width="3.75rem" borderRadius=".375rem" />
      <Skeleton height="4rem" width="3.75rem" borderRadius=".375rem" />
    </Flex>
  </>
);

export default EditDateSkeleton;
