import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Card,
  Divider,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Spinner,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import {FaAnglesRight} from 'react-icons/fa6';
import moment from 'moment';
import {capitalize} from 'lodash';
import {useGetActivityHistory} from '../../gql/shiftTemplates/queries';
import {useTimeZone} from '../../../routes/Shifts/CreateShift/appSelectors';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  resourceId: string;
  resourceType: string;
};

const ActivityHistoryDrawer = ({isOpen, onClose, resourceId, resourceType}: Props) => {
  const timeZone = useTimeZone();
  const handleInfoDrawerClose = () => {
    onClose();
  };

  const {data, loading} = useGetActivityHistory({
    variables: {input: {resourceId, resourceType}},
    skip: !resourceId || !isOpen,
  });

  const formatFieldChanges = (fieldChanges: object) =>
    Object.entries(fieldChanges).map(([field, values]) => ({
      label: capitalize(field),
      previousValue: values?.previous,
      updatedValue: values?.updated,
    }));

  const isTimeString = (value: string) => /^([01]\d|2[0-3]):[0-5]\d$/.test(value);

  const formatString = (value: any,) => {
    const formatTime = (time: string) => {
      const standardOffset = moment.tz('2024-10-01', timeZone).utcOffset();
      return moment.utc(time, 'HH:mm').add(standardOffset, 'minutes').format('hh:mm A');
    };
  
    const renderObject = (obj: Record<string, any>) => (
      <Flex flexDirection="column">
        {Object.keys(obj).map((key) => (
          <Text key={key}>
            {capitalize(key)} : {obj[key]}
          </Text>
        ))}
      </Flex>
    );
  
    if (typeof value === 'string') {
      return isTimeString(value) ? formatTime(value) : value;
    }
  
    if (Array.isArray(value)) {
      return (
        <div>
          {value.map((item) => (
            <React.Fragment key={item}>{renderObject(item)}</React.Fragment>
          ))}
        </div>
      );
    }
  
    if (typeof value === 'object' && value !== null) {
      return renderObject(value);
    }
  
    return null;
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={handleInfoDrawerClose} size="md">
      <DrawerOverlay style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}} />
      <DrawerContent overflow="auto">
        <DrawerHeader background="#F9FAFB">
          <Flex flexDir="row" alignItems="center">
            <Box ml="-10px" cursor="pointer" onClick={handleInfoDrawerClose}>
              <FaAnglesRight size="14px" />
            </Box>
            <Text fontSize="1rem" fontWeight="600" pl="10px">
              Activity History
            </Text>
          </Flex>
        </DrawerHeader>
        <DrawerCloseButton />

        {loading ? (
          <Box height="80%">
            <Flex margin="0" position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
              <Spinner thickness="0.25rem" speed="0.65s" emptyColor="gray.200" color="#38427E" size="xl" />
            </Flex>
          </Box>
        ) : (
          <Box>
            {data?.activityHistoryGet?.map(
              (activity: {
                resourceId: string;
                activityDate: Date;
                type: string;
                resourceType: string;
                title: string;
                description: string;
                rawData: {
                  fieldChanged?: object;
                  shiftTemplate?: object;
                };
              }) => {
                const changedFields = activity?.rawData?.fieldChanged
                  ? formatFieldChanges(activity?.rawData?.fieldChanged)
                  : [];

                return (
                  <Accordion allowToggle>
                    <AccordionItem border="none">
                      <Card
                        direction={{base: 'column'}}
                        variant="outline"
                        margin=".9375rem"
                        padding="0.5rem 1rem 0.5rem 0.5rem"
                      >
                        <Flex>
                          {activity?.rawData?.fieldChanged && (
                            <AccordionButton
                              border="none"
                              width="fit-content"
                              _hover={{
                                cursor: 'pointer',
                              }}
                              padding="0 8px 0 0"
                            >
                              <AccordionIcon boxSize="4" color="#313871" />
                            </AccordionButton>
                          )}

                          <Flex
                            flexDirection="column"
                            gap="0.2rem"
                            flex="1"
                            paddingLeft={!activity?.rawData?.fieldChanged ? '20px' : ''}
                          >
                            <Flex alignItems="center" justifyContent="space-between">
                              <Text color="#313871" fontSize="12px">
                                {moment.tz(activity.activityDate, moment.tz.guess())?.format('YYYY-MM-DD hh:mm A')} |
                                <span style={{fontWeight: '600', color: '#313871'}}>{activity.title}</span>
                              </Text>
                            </Flex>

                            <Text color="#7c86b1" fontSize="10px" textAlign="justify">
                              {activity.description}
                            </Text>
                          </Flex>
                        </Flex>

                        <AccordionPanel
                          background="#EDF2F7"
                          borderRadius="0.3rem"
                          padding="0.5rem 1rem"
                          marginTop="0.8rem"
                        >
                          {changedFields?.length
                            ? changedFields?.map(fields => (
                                <Flex flexDirection="column" gap="0.1rem">
                                  <Text color="#A0AEC0" fontWeight="600" fontSize="12px">
                                    {fields?.label}
                                  </Text>
                                  <Flex alignItems="center" gap="0.5rem" justifyContent="space-between">
                                    <Text fontSize="10px" color="#313871">
                                      Previous : {formatString(fields?.previousValue)}
                                    </Text>
                                    <Text fontSize="10px" color="#313871">
                                      Updated : {formatString(fields?.updatedValue)}
                                    </Text>
                                  </Flex>
                                  <Divider borderColor="#7c86b1" />
                                </Flex>
                              ))
                            : null}
                        </AccordionPanel>
                      </Card>
                    </AccordionItem>
                  </Accordion>
                );
              }
            )}
          </Box>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default ActivityHistoryDrawer;
