import { useLocation } from 'react-router-dom';

const useQueryParams = (): Record<string, string> => {
  const search = new URLSearchParams(useLocation().search);
  const params: Record<string, string> = {};

  Array.from(search.entries()).forEach(([k, v]) => {
    params[k] = v;
  });

  return params;
};

export default useQueryParams;
