import {Alert, AlertDescription, AlertIcon, Box, CloseButton, Flex} from '@chakra-ui/react';
import React from 'react';

type Props = {
  onClose: () => void;
  description: string;
  toastStatus: 'error' | 'info' | 'warning' | 'success' | 'loading';
};

const UsersToast = ({onClose, description, toastStatus}: Props) => (
  <Box>
    <Alert
      bg="#F1F1F1"
      borderRadius="6px"
      borderLeft={toastStatus === 'success' ? '4px solid #404B89' : '4px solid #C53030'}
      display="flex"
      justifyContent="space-between"
      status={toastStatus}
    >
      <Flex>
        <AlertIcon color={toastStatus === 'success' ? '#404B89' : '#C53030'} marginRight="1rem" />
        <Flex flexDirection="column">
          <AlertDescription>{description}</AlertDescription>
        </Flex>
      </Flex>
      <CloseButton onClick={onClose} size="sm" alignSelf="flex-start" />
    </Alert>
  </Box>
);

export default UsersToast;
