import {UseFormSetValue} from 'react-hook-form';
import moment from 'moment';
import {ShiftTemplateModel} from '../../shared/gql/shiftTemplates/types';
import {Position} from '../../shared/types/position';
import {Qualification} from '../../shared/types/qualifications';
import {FacilityUnit, PositionRate, ShiftDescription, SingleValue} from './ShiftTemplates.types';
import {FormValues, PositionWithExtendedQualifications} from './TemplateForm/TemplateForm.types';

import 'moment-timezone';

type SelectedPositionQualificationsArguments = {
  selectedUnitPositions: PositionWithExtendedQualifications[] | undefined;
  getValues: any;
};

export const getSelectedPositionQualifications = ({
  selectedUnitPositions,
  getValues,
}: SelectedPositionQualificationsArguments) =>
  selectedUnitPositions?.filter(position =>
    getValues('position')?.some((positionObject: any) => positionObject.name === position.name)
  );

export const getSelectedUnitPositions = (
  facilityUnits: FacilityUnit[] | undefined,
  unitName: string
): any | undefined => {
  const selectedUnit = facilityUnits?.find((unit: FacilityUnit) => unit.name === unitName);
  return selectedUnit?.positions;
};

const getPositionData = (shiftTemplate: ShiftTemplateModel | null) =>
  shiftTemplate
    ? shiftTemplate.content.positions.map(({name, rate, adjustmentRate, id}: Position) => ({
        name,
        rate,
        adjustmentRate,
        id,
      }))
    : undefined;

export type GetValuesFunction = (key: string) => Position[] | undefined;

export const isInPositionArray = (getValues: GetValuesFunction, name: string) =>
  getValues('position')?.some((positionObject: Position) => positionObject.name === name);

export const getDefaultValues = (shiftTemplate: ShiftTemplateModel | null = null) => {
  if (!shiftTemplate) {
    return {
      templateName: undefined,
      unit: undefined,
      startTime: undefined,
      stopTime: undefined,
      numOfOpenings: 1,
      day: undefined,
      bonus: 0,
      position: undefined,
      qualifications: undefined,
      description: undefined,
      clockInInstruction: undefined,
      clockOutInstruction: undefined,
    };
  }

  const {
    name: templateName,
    content: {
      unit,
      startTime,
      endTime: stopTime,
      openings,
      bonusAmount: bonus,
      break: shiftBreak,
      qualifications,
      description,
      clockInInstruction,
      clockOutInstruction,
    },
  } = shiftTemplate;

  const {total: numOfOpenings = 1, dayOfWeek: day = undefined} = openings[0] ?? {};

  return {
    templateName,
    unit: {value: unit.id, label: unit.name},
    startTime,
    stopTime,
    numOfOpenings,
    day,
    bonus,
    position: getPositionData(shiftTemplate),
    ...(shiftBreak && {break: shiftBreak}),
    qualifications,
    description,
    clockInInstruction,
    clockOutInstruction,
  };
};

//  Default values for react-select. react-select can only take an array of objects with a value and label property. This is to convert the array of strings to an array of objects.
export const getStartTimeDefaultValues = (shiftTemplate: ShiftTemplateModel | null) =>
  shiftTemplate ? {value: shiftTemplate?.content.startTime, label: shiftTemplate?.content.startTime} : undefined;

export const getUnitDefaultValues = (shiftTemplate: ShiftTemplateModel | null) =>
  shiftTemplate ? {value: shiftTemplate?.content.unit.id, label: shiftTemplate?.content.unit.name} : undefined;

export const getEndTimeDefaultValues = (shiftTemplate: ShiftTemplateModel | null) =>
  shiftTemplate ? {value: shiftTemplate?.content.endTime, label: shiftTemplate?.content.endTime} : undefined;

export const getBreakDefaultValues = (shiftTemplate: ShiftTemplateModel | null) =>
  (shiftTemplate?.content?.break && {
    value: shiftTemplate?.content.break,
    label: shiftTemplate?.content.break,
  }) ||
  undefined;

// ToDo: Fix any type
export const getCurrentBreakValues = (watch: any, shiftTemplate: any) =>
  watch('break') === undefined
    ? getBreakDefaultValues(shiftTemplate)
    : {
        value: watch('break'),
        label: watch('break'),
      };

export const getQualificationsDefaultValues = (shiftTemplate: ShiftTemplateModel | null) =>
  shiftTemplate?.content.qualifications.map((qualification: {id: string; name: string}) => ({
    value: qualification.id,
    label: qualification.name,
  }));

export const getGroupedQualificationOptions = (
  selectedPositionQualifications: PositionWithExtendedQualifications[],
  otherQualifications: {id: string; name: string}[] | undefined
) => {
  const emptyGuid = '00000000-0000-0000-0000-000000000000';
  const otherQualificationsMapped = {
    label: 'Other',
    options: otherQualifications?.map((qualification: Qualification) => ({
      label: qualification.name,
      value: qualification.id,
    })),
  };
  const selectedPositionQualificationsMapped = selectedPositionQualifications?.map((item: any) => ({
    label: item.name,
    options: item.qualifications.map((qualification: Qualification) => ({
      label: qualification.name,
      value: qualification.id,
    })),
  }));

  const selectedPositionQualificationsFiltered = selectedPositionQualificationsMapped?.map((item: any) => ({
    ...item,
    options: item.options.filter((option: any) => option.value !== emptyGuid),
  }));

  return [...selectedPositionQualificationsFiltered, otherQualificationsMapped];
};

export const getCurrentQualifications = (watch: any, qualificationsDefaultValues: SingleValue[] | undefined) =>
  watch('qualifications') === undefined
    ? qualificationsDefaultValues
    : watch('qualifications')?.map((qualification: Qualification) => ({
        value: qualification.id,
        label: qualification.name,
      }));

export const getMissingIds = (
  watch: any,
  groupedQualificationOptions:
    | {
        label: any;
        options: any;
      }[]
    | undefined
): string[] | undefined =>
  watch('qualifications')?.reduce((missing: string[], item: any) => {
    const found = groupedQualificationOptions?.some((group: any) =>
      group.options.some((option: any) => option.value === item.id)
    );
    if (!found) {
      missing.push(item.id);
    }
    return missing;
  }, []);

export const checkRateOutOfRange = (positionArray: Position[], selectedPositionBaseRate: PositionRate[]) =>
  positionArray?.some((position: Position) => {
    const positionToUpdate: PositionRate | undefined = selectedPositionBaseRate?.find(
      (positionObject: {name: string; baseRate: number}) => positionObject.name === position.name
    );

    if (positionToUpdate === undefined) {
      return false;
    }

    return typeof position.rate === 'string'
      ? Number(position.rate * 100) < positionToUpdate?.minBaseRate ||
          Number(position.rate * 100) > positionToUpdate?.maxBaseRate
      : position.rate < positionToUpdate?.minBaseRate || position.rate > positionToUpdate?.maxBaseRate;
  });

export const getSelectedPositionBaseRate = (
  selectedPositionQualifications: PositionWithExtendedQualifications[] | undefined
): PositionRate[] | undefined =>
  selectedPositionQualifications?.map((position: PositionWithExtendedQualifications) => ({
    name: position.name,
    baseRate: position.rate.baseRate,
    maxBaseRate: position.rate.maxBaseRate,
    minBaseRate: position.rate.minBaseRate,
  }));

export const getFirstPositionSelectedDescriptionAndInstructions = (
  selectedPositionQualifications: PositionWithExtendedQualifications[] | undefined
): ShiftDescription | null =>
  selectedPositionQualifications && selectedPositionQualifications?.length > 0
    ? {
        clockInInstruction: selectedPositionQualifications[0]?.qualifications[0]?.clockInInstruction ?? '',
        clockOutInstruction: selectedPositionQualifications[0]?.qualifications[0]?.clockOutInstruction ?? '',
        description: selectedPositionQualifications[0]?.qualifications[0]?.description ?? '',
      }
    : null;

export const handleBonusChange = (event: number, setValue: UseFormSetValue<FormValues>) => {
  setValue('bonus', event, {
    shouldValidate: true,
    shouldDirty: true,
    shouldTouch: true,
  });
};

export const handleHasBonus = (
  setHasBonus: React.Dispatch<React.SetStateAction<boolean>>,
  hasBonus: boolean,
  setValue: any
) => {
  setHasBonus(!hasBonus);
  setValue('bonus', 0, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
};

export const handleStartTimeSelection = (value: string, setValue: any) => {
  setValue('startTime', moment(value, 'HH:mm').utc().format('HH:mm'), {
    shouldValidate: true,
    shouldDirty: true,
    shouldTouch: true,
  });
};

export const handleStopTimeSelection = (value: string, setValue: any) => {
  setValue('endTime', moment(value, 'HH:mm').utc().format('HH:mm'), {
    shouldValidate: true,
    shouldDirty: true,
    shouldTouch: true,
  });
};

export const handleBreakSelection = (breakTime: SingleValue, setValue: any) => {
  setValue('break', breakTime.value, {
    shouldValidate: true,
    shouldDirty: true,
    shouldTouch: true,
  });
};

export const handleDecrementNumOfOpenings = (getValues: any, setValue: any, watch: any) => {
  if (getValues('numOfOpenings') === 1) {
    setValue('numOfOpenings', 0, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
    return;
  }
  const updatedNumOfOpenings = watch('numOfOpenings') - 1;
  setValue('numOfOpenings', updatedNumOfOpenings, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
};

export const handleIncrementNumOfOpenings = (setValue: any, watch: any) => {
  const updatedNumOfOpenings = watch('numOfOpenings') + 1;
  setValue('numOfOpenings', updatedNumOfOpenings, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
};

export const checkOpeningButNoDay = (watch: any, getValues: any) => watch('numOfOpenings') > 0 && !getValues('day');

export const handleDeleteDay = (setValue: any) => {
  setValue('day', undefined, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
  setValue('numOfOpenings', 0, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
};

export const handleDaySelection = (day: string, setValue: any, getValues: any) => {
  setValue('day', day, {shouldDirty: true, shouldValidate: true, shouldTouch: true});
  if (getValues('numOfOpenings') === 0) {
    setValue('numOfOpenings', 1);
  }
};
