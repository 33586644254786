import {gql, useQuery} from '@apollo/client';
import {QueryHookOptions} from '@apollo/react-hooks';
import {useEffect, useState} from 'react';
import {useErrorBoundary} from 'react-error-boundary';
import {getAllUsersFields} from './fields';
import {GetUsers, SearchUserVariables, GetUser} from './types';
import useAppDispatch from '../../hooks/useAppDispatch';
import {setUsers, getUsers} from '../../slices/usersSlice';
import {useFacilityId} from '../../../routes/Shifts/CreateShift/appSelectors';
import {useApolloClients} from '../../../core/providers/ApolloContextProvider';

const getAllUsers = (orderBy: string, fields: string = getAllUsersFields) => gql`query {
    searchUser(searchUserInput:{
      orderBy: "${orderBy}"
    }) {
        ${fields}
    }
  }`;

const useGetAllUsers = (options?: QueryHookOptions<GetUsers, SearchUserVariables>, fields?: string) => {
  const {client} = useApolloClients();
  const facilityId = useFacilityId();

  const [orderBy, setOrderBy] = useState('role desc');

  const {
    data,
    loading,
    error: queryError,
    refetch: refetchQuery,
  } = useQuery<GetUsers, SearchUserVariables>(getAllUsers(orderBy, fields), {
    ...options,
    skip: !facilityId,
    client,
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUsers());
    if (!loading) {
      if (queryError) {
        console.log('error from useGetAllUsers', queryError);
      } else if (data) {
        dispatch(setUsers(data.searchUser));
      }
    }
  }, [data, loading, queryError, dispatch]);

  const refetch = async () => {
    setOrderBy('updatedAt desc');
    const {data: refetchData, error} = await refetchQuery();

    if (error) {
      console.log('error from refetch', error);
    } else if (refetchData) {
      dispatch(setUsers(refetchData.searchUser));
    }

    return refetchData;
  };

  const refetchAfterDelete = async () => {
    setOrderBy('role desc');
    const {data: refetchData, error} = await refetchQuery();

    if (error) {
      console.log('error from refetch', error);
    } else if (refetchData) {
      dispatch(setUsers(refetchData.searchUser));
    }

    return refetchData;
  };

  return {data, loading, queryError, refetch, refetchAfterDelete};
};

const getLoggedInUserDetailsFields = `
firstName
lastName
email
type
id
access {
  role
  facilityName
}
`;

const getLoggedInUserDetails = (userId: string, fields: string = getLoggedInUserDetailsFields) => gql`query {
  getUser(userId: "${userId}") {
      ${fields}
  }
}`;

export const useGetLoggedInUserDetails = (userId: string, options?: QueryHookOptions<GetUser>, fields?: string) => {
  const {client} = useApolloClients();
  const {showBoundary} = useErrorBoundary();

  const {
    data,
    loading,
    error: queryError,
  } = useQuery<GetUser>(getLoggedInUserDetails(userId, fields), {
    ...options,
    client,
    onError: (error: any) => {
      if (error?.message?.includes('503') || error?.message?.includes('Failed to fetch')) {
        showBoundary('Under Maintenance');
      }
    },
  });
  return {data, loading, queryError};
};

export default useGetAllUsers;
