import jwt_decode from 'jwt-decode';
import {useSelector} from 'react-redux';

type DecodedToken = {
  'https://nursedash.com/custom': {
    iuaId: string;
    legacyFacilityId: string;
    nextGenFacilityId: string;
    params: Record<string, any>;
    role: string;
    session: string;
    status: string;
    type: string;
  };
  iss: string;
  sub: string;
  aud: string[];
  iat: number;
  exp: number;
  azp: string;
  scope: string;
};

const useFacilityIdFromToken = (): string | null => {
  const token: string = useSelector((state: {auth: {token: string}}) => state.auth.token);

  if (!token) {
    window.location.href = `${process.env.REACT_APP_AUTH0_DOMAIN}`;
    console.error('No token found');
    return null;
  }

  const decodedToken: DecodedToken = jwt_decode(token);
  return decodedToken['https://nursedash.com/custom'].nextGenFacilityId || null;
};

export default useFacilityIdFromToken;
