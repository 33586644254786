import React, {useEffect, useRef, useState} from 'react';

import uuid from 'react-uuid';
import {Button, Flex, Text} from '@chakra-ui/react';
import {ChevronDownIcon} from '@chakra-ui/icons';
import DatePicker from 'react-multi-date-picker';
import Icon from 'react-multi-date-picker/components/icon';
import moment from 'moment';
import {getDays} from '../../../routes/Shifts/CreateShift/CreateShiftForm/helperFunctions';
import {Props} from './DateSelector.types';

const DateSelector = React.forwardRef(
  (
    {
      handleDaySelection,
      setValue,
      getValues,
      errors,
      shiftTemplateDay,
      watch,
      selectedTemplateDaysAsDates,
      setSelectedTemplateDaysAsDates,
      setFocus,
      shiftTemplate,
      register,
      datePickerIndex,
      setDatePickerIndex,
    }: Props,
    ref
  ) => {
    const [datePickerValuesMetaData, setDatePickerValuesMetaData] = useState<any>([]);

    let selectedDayFromShiftTemplate: string | undefined;

    switch (shiftTemplateDay) {
      case 'Tues':
        selectedDayFromShiftTemplate = 'Tue';
        break;
      case 'Thurs':
        selectedDayFromShiftTemplate = 'Thu';
        break;
      default:
        selectedDayFromShiftTemplate = shiftTemplateDay;
    }

    const datepickerRef = useRef<HTMLDivElement>();

    const currentDayAndOpenings = watch('dayAndOpenings');
    const currentDayAndOpeningsStartDates = currentDayAndOpenings?.map((entry: any) => entry.localStartDate);

    useEffect(() => {
      const daysOfWeek = getDays();
      const selectedDaysArray = daysOfWeek
        .filter(day => day.dayOfWeek === selectedDayFromShiftTemplate)
        .map(day => day.fullDate);

      setSelectedTemplateDaysAsDates(selectedDaysArray);
    }, [selectedDayFromShiftTemplate, shiftTemplateDay, setSelectedTemplateDaysAsDates]);

    useEffect(() => {
      const startDates = currentDayAndOpenings?.map((entry: any) => entry.localStartDate);

      setSelectedTemplateDaysAsDates(startDates);
    }, [currentDayAndOpenings, setSelectedTemplateDaysAsDates]);

    const dayButtonColors = (day: any) => {
      if (currentDayAndOpeningsStartDates?.includes(day.fullDate)) {
        return '#25285A';
      }
      return '#616CA1';
    };
    const dayButtonBorderColor = (day: any) =>
      currentDayAndOpeningsStartDates?.includes(day.fullDate) ? '2px solid #25285A' : 'none';

    const handleDatePickerChange = (date: any) => {
      const isDateAlreadySelected = (
        datePickerDate: string,
        dayAndOpenings: Array<{localStartDate: string; openings: number}>
      ) =>
        dayAndOpenings.some(item => {
          const startDate = item.localStartDate;
          return datePickerDate === startDate;
        });

      const newSelectedDates = date.map((d: any) => d.format('YYYY-MM-DD'));
      setValue('date', newSelectedDates, {
        shouldValidate: true,
        shouldDirty: true,
      });
      const isNotInGetDays = newSelectedDates.filter(
        (selectedDate: string) =>
          !getDays()
            .map(day => day.fullDate)
            .includes(selectedDate)
      );
      setDatePickerIndex(isNotInGetDays.length);

      const existingDates = getValues('dayAndOpenings')?.map((item: {localStartDate: string}) => item.localStartDate);

      const deselectedDates = existingDates?.filter((existingDate: any) => !newSelectedDates.includes(existingDate));

      if (deselectedDates?.length > 0) {
        const updatedDayAndOpenings = getValues('dayAndOpenings').filter(
          (item: {localStartDate: string}) => !deselectedDates.includes(item.localStartDate)
        );
        const updatedSelectedDays = selectedTemplateDaysAsDates.filter(
          (dayInSelectedDays: string) => !deselectedDates.includes(dayInSelectedDays)
        );
        setSelectedTemplateDaysAsDates(updatedSelectedDays);

        setValue('dayAndOpenings', updatedDayAndOpenings, {
          shouldValidate: true,
          shouldDirty: true,
        });

        const updatedDayAndOpeningsStartDates = updatedDayAndOpenings.map((entry: any) => entry.localStartDate);

        setValue('date', updatedDayAndOpeningsStartDates, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }

      if (!date || date.length === 0) return;

      const newlySelectedDate = date[date.length - 1]?.format('YYYY-MM-DD');
      const newlySelectedDateTimestamp = new Date(newlySelectedDate).getTime();

      if (
        !datePickerValuesMetaData.includes(newlySelectedDateTimestamp) ||
        !isDateAlreadySelected(newlySelectedDate, currentDayAndOpenings)
      ) {
        handleDaySelection(newlySelectedDate, setValue, watch);
        const updatedDatePickerValuesMetaData = date.map((d: any) => (d instanceof Date ? d.getTime() : d));

        setDatePickerValuesMetaData(updatedDatePickerValuesMetaData);
      }
    };

    useEffect(() => {
      if (shiftTemplate) {
        setFocus('date');
      }
    }, [shiftTemplate, setFocus]);

    return (
      <Flex flexDirection="column">
        <Flex gap=".5rem">
          <Text fontWeight="600">Date</Text>
        </Flex>
        <Flex overflowX="auto" overflowY="hidden" whiteSpace="nowrap" css={{'&::-webkit-scrollbar': {display: 'none'}}}>
          <Flex justifyContent="space-between" w="98%" height="5rem" paddingTop="1rem">
            {getDays().map(day => (
              <Button
                {...register('date')}
                ref={ref}
                type="button"
                key={uuid()}
                _disabled={{
                  opacity: '0.6',
                }}
                variant="square"
                onClick={() => {
                  const selectedDate = day.fullDate;

                  if (selectedTemplateDaysAsDates.includes(selectedDate)) {
                    setSelectedTemplateDaysAsDates(selectedTemplateDaysAsDates.filter(date => date !== selectedDate));
                    const updatedDayAndOpenings = currentDayAndOpenings?.filter(
                      (entry: any) => entry.localStartDate !== selectedDate
                    );
                    setValue('dayAndOpenings', updatedDayAndOpenings, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                    const updatedDayAndOpeningsStartDates = updatedDayAndOpenings?.map(
                      (entry: any) => entry.localStartDate
                    );
                    setValue('date', updatedDayAndOpeningsStartDates, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  } else {
                    setSelectedTemplateDaysAsDates([...selectedTemplateDaysAsDates, selectedDate]);
                    handleDaySelection(selectedDate, setValue, getValues);
                  }
                }}
                color={dayButtonColors(day)}
                border={dayButtonBorderColor(day)}
              >
                {day.date}
                <br />
                {day.dayOfWeek}
              </Button>
            ))}
            <Flex
              backgroundColor="#E2E8F0"
              color="#616CA1"
              height="4rem"
              width="4rem"
              borderRadius="0.375rem"
              fontSize="16px"
              fontWeight="400"
              flexDirection="column"
              justifyContent="flex-end"
              display={['none', 'inherit']}
            >
              <Flex justifyContent="flex-end">
                <Flex
                  marginRight="-.5rem"
                  visibility={datePickerIndex > 0 ? 'inherit' : 'hidden'}
                  borderRadius="50%"
                  backgroundColor="#475392"
                  color="white"
                  height="1.5rem"
                  width="1.5rem"
                  zIndex="20"
                  justifyContent="center"
                >
                  {datePickerIndex}
                </Flex>
              </Flex>
              <Flex flexDirection="column" gap=".8rem" alignItems="center" overflow="visible" margin="-.3rem">
                <DatePicker
                  multiple
                  render={<Icon />}
                  minDate={moment().format('YYYY-MM-DD')}
                  ref={datepickerRef}
                  value={selectedTemplateDaysAsDates}
                  onChange={e => handleDatePickerChange(e)}
                  style={{zIndex: 1000, position: 'relative'}}
                />
                <Flex position="relative" top="-0.625rem">
                  <ChevronDownIcon />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        {errors.dayAndOpenings && (
          <Text color="red.500" fontSize="sm">
            {errors.dayAndOpenings.message}
          </Text>
        )}
      </Flex>
    );
  }
);

export default DateSelector;
