import MixpanelConstants from '../../constants';
import trackEvent from '../../events/trackEvent';

const trackShiftView = (metadata: Record<string, any>, shiftId: string) =>
  trackEvent(MixpanelConstants.SHIFT_DRAWER_VIEW, {metadata, shiftId});
const trackShiftPageCreate = () => trackEvent(MixpanelConstants.CREATE_SHIFT_VIEW);
const trackShiftPageEdit = (metadata: Record<string, any>, shiftId: string, openingId: string) =>
  trackEvent(MixpanelConstants.SHIFT_EDIT_VIEW, {metadata, shiftId, openingId});

export {trackShiftView, trackShiftPageCreate, trackShiftPageEdit};
