//  ToDo: figure out why I cannot export as default.

// eslint-disable-next-line import/prefer-default-export
export const ButtonStyles = {
  // baseStyle: {},

  //   sizes: {},
  variants: {
    primary: {
      bgColor: '#38427E',
      color: 'white',
    },
    secondary: {
      bgColor: '#E2E8F0',
      color: '#2D3748',
    },
    danger: {
      bgColor: '#E53E3E',
      color: 'white',
    },

    outline: {
      bgColor: 'white',
      color: 'gray.400',
      border: '1px',
      borderColor: 'gray.200',
      fontWeight: '400',
      textAlign: 'left',
      _placeHolder: {textAlign: 'left'},
    },
    pagination: {
      bgColor: 'gray.100',
      color: 'black',
      fontWeight: '600',
      boxSize: '2rem',
    },
    filter: {
      color: 'indigo.300',
      bgColor: '#FFFFFF',
      border: '1px solid #7C86B1',
    },
    circle: {
      backgroundColor: '#E2E8F0',
      color: '#616CA1',
      borderRadius: '20px',
      padding: '10px 16px',
      fontSize: '16px',
      fontWeight: '600',
      _hover: {
        cursor: 'pointer',
      },
    },
    square: {
      backgroundColor: '#E2E8F0',
      color: '#616CA1',
      height: '4rem',
      width: '4rem',
      borderRadius: '0.375rem',
      padding: '0.625rem 1.5rem',
      fontSize: '16px',
      fontWeight: '400',
      _hover: {
        cursor: 'pointer',
      },
    },
  },
  //   defaultProps: {},
};
