import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Card,
  CardBody,
  Flex,
  Heading,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import {useFlags} from 'launchdarkly-react-client-sdk';
import React, {useState} from 'react';
import uuid from 'react-uuid';
import ShiftInformationBox from './ShiftInformationBox';
import {ShiftDetails} from '../../Shift.types';

type Props = {
  unitName: string;
  position: string;
  shift: ShiftDetails;
  convertedEndTime: string;
  convertedStartTime: string;
  newDate: string;
  isExpanded: boolean;
};

const commonTextSecondaryProps = {
  fontSize: '0.8125rem',
  fontWeight: '500',
};

const commonHeaderProps = {
  color: '#8c8c8c',
  fontWeight: '600',
  fontSize: '0.875rem',
};

const ShiftInformationCard = ({
  unitName,
  position,
  shift,
  convertedEndTime,
  convertedStartTime,
  newDate,
  isExpanded: isAccodionExpanded,
}: Props) => {
  const {showRates} = useFlags();
  const [isExpanded, setIsExpanded] = useState({
    description: false,
    clockIn: false,
    clockOut: false,
  });

  const formatBreak = (inputString: string) => {
    const numbers = inputString.match(/\d+/g)?.map(Number);
    const strings = inputString.match(/[a-zA-Z]+/g);

    if (numbers?.[0]) {
      // Remove the 's' from "mins" if it exists, making it "min"
      const timeUnit = strings?.[0].replace(/mins$/, 'min');
      return `${numbers[0]} ${timeUnit} break`;
    }
    return 'No break';
  };
  return (
    <Card direction={{base: 'column'}} variant="outline" background="#EDF2F7" margin=".9375rem">
      <Flex className="top-header-info">
        <Box>
          <Heading size="sm" fontWeight="600" padding="0.625rem" color="#313871">
            {unitName} / {position}
          </Heading>
          <Box className="con">
            <Flex flexDir="column" pl="0.625rem" mt="-0.5rem">
              <Text sx={commonTextSecondaryProps} display="flex" flexDir="row" color="#4A5568">
                {convertedStartTime} - {convertedEndTime}{' '}
                <Text sx={commonTextSecondaryProps} display="flex" flexDir="row" pl="0.125rem" color="#4A5568">
                  ( {formatBreak(shift?.break)} )
                </Text>
              </Text>
            </Flex>
          </Box>
        </Box>
        <Spacer />
        <Flex className="boxbox" flexDir="column" alignItems="flex-end" paddingRight="3">
          <Heading size="sm" fontWeight="600" paddingTop="0.625rem" color="#313871">
            {newDate}
          </Heading>
          <Box>
            <Flex paddingTop="0.5rem">
              {showRates ? (
                <Box display="flex" flexDir="column" alignItems="flex-end">
                  <Text sx={commonHeaderProps}>RATE DETAILS:</Text>
                  {shift?.rate && shift.rate.length > 0
                    ? shift.rate.map(detail => {
                        const rate = detail.baseRate + detail.adjustmentRate;
                        const rateStr = rate.toString();
                        const formattedRate = `${rateStr.slice(0, -2)}.${rateStr.slice(-2)}`;
                        return (
                          <Text sx={commonTextSecondaryProps} key={uuid()}>
                            {detail.name}: ${formattedRate}/hour
                          </Text>
                        );
                      })
                    : 'Loading...'}
                </Box>
              ) : null}
            </Flex>
          </Box>
        </Flex>
      </Flex>

      <Accordion defaultIndex={isAccodionExpanded ? 0 : undefined} allowToggle>
        <AccordionItem border="none">
          <AccordionPanel>
            <Stack spacing="5" align="stretch">
              <CardBody
                padding="0"
                margin="-0.5rem"
                paddingTop="0.5rem"
                className="cardbody"
                display="flex"
                flexDir="row"
                justifyContent="space-between"
              >
                <Flex flexDir="column" width="50%">
                  <Box>
                    <Text fontSize="0.75rem" color="#718096" fontWeight="600">
                      Qualifications
                    </Text>
                    {(() => {
                      if (!shift?.qualifications) {
                        return 'Loading...';
                      }
                      if (shift.qualifications.length === 0) {
                        return (
                          <Text fontSize="0.8125rem" fontWeight="650">
                            None
                          </Text>
                        );
                      }
                      return (
                        <Stack direction="row" spacing={2} flexWrap="wrap">
                          {shift.qualifications.map(quali => (
                            <Badge key={uuid()} bg="#e5e5eb" fontSize="0.9em">
                              {quali.name}
                            </Badge>
                          ))}
                        </Stack>
                      );
                    })()}
                  </Box>
                  <Box pt="0.9375rem">
                    <ShiftInformationBox
                      title="Description"
                      content={shift.description}
                      isExpanded={isExpanded.description}
                      setExpanded={value => setIsExpanded(prev => ({...prev, description: value}))}
                      alignItems="flex-start"
                    />
                  </Box>
                </Flex>
                <Flex flexDir="column" width="50%" className="80">
                  <Stack>
                    <ShiftInformationBox
                      title="Clock In Instructions"
                      content={shift.clockIn}
                      isExpanded={isExpanded.clockIn}
                      setExpanded={value => setIsExpanded(prev => ({...prev, clockIn: value}))}
                      alignItems="flex-end"
                    />
                    <Flex pt="0.9375rem">
                      <ShiftInformationBox
                        title="Clock Out Instructions"
                        content={shift.clockOut}
                        isExpanded={isExpanded.clockOut}
                        setExpanded={value => setIsExpanded(prev => ({...prev, clockOut: value}))}
                        alignItems="flex-end"
                      />
                    </Flex>
                  </Stack>
                </Flex>
              </CardBody>
            </Stack>
          </AccordionPanel>
          <Flex justifyContent="center">
            <Box flex="1" background="#F7FAFC">
              <AccordionButton
                display="flex"
                alignItems="center"
                justifyContent="center"
                border="none"
                paddingTop="0.5rem"
                _hover={{
                  backgroundColor: '#F7FAFC',
                  cursor: 'pointer',
                }}
              >
                <Text fontSize="0.75rem" color="#2A46AA" fontWeight="600">
                  View More
                </Text>
                <AccordionIcon boxSize="4" color="#2A46AA" />
              </AccordionButton>
            </Box>
          </Flex>
        </AccordionItem>
      </Accordion>
    </Card>
  );
};

export default ShiftInformationCard;
