import React from 'react';
import NDReactSelect from '../../../shared/components/NDReactSelect/NDReactSelect';

type Props = {
  selectedReason: string;
  setSelectedReason: (reason: string) => void;
};

const reasonOptions = [
  { value: 'No Applicants', label: 'No Applicants' },
  { value: 'Filled Internally', label: 'Filled Internally' },
  { value: 'Applicant Withdrew', label: 'Applicant Withdrew' },
  { value: 'Filled By External Source', label: 'Filled By External Source' },
  { value: 'Census Change', label: 'Census Change' },
];

const ReasonDropdown = ({ selectedReason, setSelectedReason }: Props) => {
  const handleReasonChange = (selectedOption: any) => {
    setSelectedReason(selectedOption ? selectedOption.value : '');
  };

return (
  <NDReactSelect
    name="reason"
    placeholder='Select a reason'
    options={reasonOptions}
    onChange={handleReasonChange}
    value={reasonOptions.find(option => option.value === selectedReason)}
    isClearable
    closeMenuOnSelect
    isMulti={false}
  />
)
};

export default ReasonDropdown;
