import {InfoIcon} from '@chakra-ui/icons';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import {UseFormHandleSubmit} from 'react-hook-form';
import {FormValues} from '../EditShift.types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formData: FormValues) => Promise<void>;
  handleSubmit: UseFormHandleSubmit<FormValues, undefined>;
};

const SubmitModal = ({isOpen, onClose, handleSubmit, onSubmit}: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} isCentered>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader fontWeight="600" w="98%">
        Edit shift opening
      </ModalHeader>
      <ModalCloseButton onClick={() => onClose()} />
      <ModalBody
        display="flex"
        alignSelf="baseline"
        alignItems="center"
        border="0.125rem solid rgba(64, 75, 137, 1)"
        borderRadius="0.375rem"
        marginLeft="1.5rem"
        width="85%"
        paddingLeft=".7rem"
      >
        <InfoIcon marginRight=".7rem" boxSize={5} color="rgba(64, 75, 137, 1)" />
        <Box color="rgba(74, 85, 104, 1)">Since the opening has changed, it will be displayed as its own shift.</Box>
      </ModalBody>

      <ModalFooter>
        <Button
          variant="secondary"
          mr={3}
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button variant="primary" onClick={() => handleSubmit(onSubmit)()}>
          Continue
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default SubmitModal;
