import React from 'react';
import './PermissionCard.scss';
import uuid from 'react-uuid';

import { LocationPermissions } from './PermissionCard.types';
// ToDo: Figure out why typescript doesnt like the Props type I created in the types file.

const PermissionCard = ({ ndUserPermissions }: any) => (
    <div className="permission-card">
      <div className="permission-card__title">Facilities & Permissions</div>
      <div className="permission-card__sub-title">
        See the Facilities that you can access from your personal NurseDash
        account
      </div>
      <div className="permission-card__card-container">
        <div className="permission-card__label-container">
          <div className="permission-card__labels">FACILITY</div>
          <div className="permission-card__labels">ROLE</div>
          <div className="permission-card__labels">PERMISSION</div>
        </div>
        {ndUserPermissions.map((group: LocationPermissions) => (
            <div
              key={uuid()}
              className={
                ndUserPermissions.indexOf(group) % 2 !== 0
                  ? 'permission-card__permission-container bg'
                  : 'permission-card__permission-container'
              }
            >
              <div className="permission-card__permissions">
                {group.facility}
              </div>
              <div className="permission-card__permissions">{group.role}</div>
              <div className="permission-card__permissions">
                {group.permissions}
              </div>
            </div>
          ))}
      </div>
    </div>
  );

export default PermissionCard;
