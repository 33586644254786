import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Shift} from '../gql/shifts/types';

type CreateShiftState = {
  data: Shift[];
  loading: boolean;
  error: string | null;
  isRefreshNeeded: boolean;
};

const initialState: CreateShiftState = {
  data: [],
  loading: false,
  error: null,
  isRefreshNeeded: false,
};

export const shiftsSlice = createSlice({
  name: 'shifts',
  initialState,
  reducers: {
    setShifts: (state, action: PayloadAction<Shift[]>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    addShift: (state, action: PayloadAction<Shift>) => {
      state.data.push(action.payload);
      state.loading = false;
      state.error = null;
    },
    setNeedRefresh: (state, action: PayloadAction<boolean>) => {
      state.isRefreshNeeded = action.payload;
    },
  },
});

export const {setShifts, addShift, setNeedRefresh} = shiftsSlice.actions;

export default shiftsSlice.reducer;
