import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Alert, AlertIcon, AlertTitle, CloseButton} from '@chakra-ui/react';
import {ReduxState} from '../../types';

const MobileNotification = () => {
  const isMobile = useSelector((state: ReduxState) => state.mobile.isMobile);
  const [show, setShow] = useState(true);

  if (!isMobile || !show) return null;

  return (
    <Alert
      bg="#F1F1F1"
      borderRadius="6px"
      borderLeft="4px solid #404B89"
      display="flex"
      justifyContent="space-between"
      status="info"
      w="90%"
    >
      <AlertIcon color="#404B89" />
      <AlertTitle>Mobile version coming soon!</AlertTitle>
      <CloseButton position="absolute" right="8px" top="8px" onClick={() => setShow(false)} />
    </Alert>
  );
};

export default MobileNotification;
