/* eslint-disable no-plusplus */
/* eslint-disable import/prefer-default-export */
export const timeOptions: {value: string; label: string}[] = [];

for (let hour = 1; hour <= 12; hour++) {
  for (let minute = 0; minute < 60; minute++) {
    const formattedHour = hour.toString().padStart(2, '0');
    const formattedMinute = minute.toString().padStart(2, '0');
    const time = `${formattedHour}:${formattedMinute}`;
    const option = {value: time, label: time};
    timeOptions.push(option);
  }
}

export const days = ['Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun'];

export const getFullDayName = (dayAbbreviation: string | undefined) => {
  switch (dayAbbreviation) {
    case 'Mon':
      return 'Monday';
    case 'Tues':
      return 'Tuesday';
    case 'Wed':
      return 'Wednesday';
    case 'Thurs':
      return 'Thursday';
    case 'Fri':
      return 'Friday';
    case 'Sat':
      return 'Saturday';
    case 'Sun':
      return 'Sunday';
    default:
      return dayAbbreviation;
  }
};
