import React from 'react';

import {Modal, ModalOverlay, ModalContent} from '@chakra-ui/react';
import ActionableAlert from '../../../../shared/components/ActionableAlert/ActionableAlert';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  handleClick: () => void;
};

const ConfirmationModal = ({isOpen, handleClick, onClose}: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} isCentered>
    <ModalOverlay />
    <ModalContent>
      <ActionableAlert
        btnText="Delete"
        alertType="danger"
        title="Delete template"
        bodyText="Are you sure you want to delete this shift template?"
        secondaryBtnText="Cancel"
        isDismissed={false}
        dismissAlert={onClose}
        handleClick={handleClick}
        hasButton
      />
    </ModalContent>
  </Modal>
);

export default ConfirmationModal;
