import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {OpeningsV3, PositionV2, Qualification, CompletedShiftDetails} from 'shared/types';
import {AllOverviewShiftsDetails} from 'shared/types/allOverviewShiftsDetails';
import {ApplicantFacilityInfoDetails} from 'shared/types/applicantFacilityInfoDetails';
import {RootState} from 'store';

export type SearchShiftsSummary =
  | {
      date: string;
      shifts: {
        shiftId: string;
        unitId: string;
        position: string;
        startTime: string;
        endTime: string;
        bonus: number;
        numOfCompleted: number;
        numOfConfirmed: number;
        numOfCancelled: number;
        numOfOpened: number;
        numOfOpenedWithApplicants: number;
        numOfPendingCheckIn: number;
        numOfPendingClockIn: number;
        numOfPendingReview: number;
        numOfNoCallNoShow: number;
        numOfSelected: number;
        positionDetail: PositionV2[];
      }[];
    }[]
  | null;

export type ShiftsSummary =
  | {
      facilityId: string;
      unitId: string;
      unitName: string;
    }[]
  | null;

export type ShiftDetailsSummary =
  | {
      shiftId: string;
      facilityId: string;
      shiftUnitId: string;
      startTime: string;
      endTime: string;
      break: string;
      rate: PositionV2[];
      bonus: number;
      description: string;
      clockIn: string;
      clockOut: string;
      qualifications: Qualification[];
      openingDetails: OpeningsV3[];
    }[]
  | null;

type PositionRate = {
  id: string;
  name: string;
  baseRate: number;
  subsidy: number;
  margin: number;
  adjustmentRate: number;
};

type ShiftOpening = {
  shiftId: string;
  unitId: string;
  startDateTime: string;
  endDateTime: string;
  break: string;
  positionRate: PositionRate[];
  bonus: number;
  description: string;
  clockInInstruction: string;
  clockOutInstruction: string;
  qualificationIds: string[];
  __typename: 'ShiftOpeningResult';
};

type EditedShift = {
  updateShiftOpening: ShiftOpening;
};

export type CancelShiftOpeningSummary =
  | {
      seqId: number;
      shiftId: string;
      unitId: string;
      startDateTime: string;
      endDateTime: string;
      break: string;
      positionRate: string[];
      bonus: number;
      description: string;
      clockInInstruction: string;
      clockOutInstruction: string;
      assignedNurseId: string;
      status: string;
      qualificationIds: string[];
      deletedAt: string;
      deletedBy: string;
      refShiftId: number;
      failure: string;
    }[]
  | null;

export type CreateFavoriteSummary = {
  faciltiyId: string;
  ndProId: string;
  relationship: string;
  relationshipScope: string;
  reason: string;
  comment: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deletedAt: string;
  deletedBy: string;
  failure: string;
} | null;

export type RemoveFavoriteSummary = {
  faciltiyId: string;
  ndProId: string;
  relationship: string;
  relationshipScope: string;
  reason: string;
  comment: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deletedAt: string;
  deletedBy: string;
  failure: string;
} | null;

export type CompletedShiftSummary = CompletedShiftDetails | null;

export type AllOverviewShiftsSummary = AllOverviewShiftsDetails | null;

export type ApplicantFacilityInfoSummary = ApplicantFacilityInfoDetails | null;

export type ShiftsState = {
  data: ShiftsSummary[];
  loading: boolean;
  error: string | null;
  shifts: any;
  shiftsSummary: ShiftsSummary;
  searchShifts: SearchShiftsSummary | null;
  shiftDetails: ShiftDetailsSummary | null;
  editedShift: EditedShift | null;
  cancelShiftOpening: CancelShiftOpeningSummary | null;
  createFavorite: CreateFavoriteSummary | null;
  removeFavorite: RemoveFavoriteSummary | null;
  completedShift: CompletedShiftSummary | null;
  allOverviewShifts: AllOverviewShiftsSummary | null;
  applicantFacilityInfo: ApplicantFacilityInfoSummary | null;
};

const initialState: ShiftsState = {
  data: [],
  loading: false,
  error: null,
  shifts: [],
  shiftsSummary: null,
  searchShifts: null,
  shiftDetails: null,
  editedShift: null,
  cancelShiftOpening: null,
  createFavorite: null,
  removeFavorite: null,
  completedShift: null,
  allOverviewShifts: null,
  applicantFacilityInfo: null,
};

export const shiftsSlice = createSlice({
  name: 'shiftsData',
  initialState,
  reducers: {
    showShifts: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    getShifts: state => {
      state.loading = true;
      state.error = null;
    },
    setShifts: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    getShiftSummary: state => {
      state.loading = true;
      state.error = null;
    },
    setShiftSummary: (state, action: PayloadAction<any>) => {
      state.shiftsSummary = action.payload;
      state.loading = false;
      state.error = null;
    },
    deleteShift: (state: ShiftsState) => {
      state.loading = true;
      state.error = null;
    },
    getSearchShifts: state => {
      state.loading = true;
      state.error = null;
    },
    setSearchShifts: (state, action: PayloadAction<any>) => {
      state.searchShifts = action.payload;
      state.loading = false;
      state.error = null;
    },
    getShiftDetails: state => {
      state.loading = true;
      state.error = null;
    },
    setShiftDetails: (state, action: PayloadAction<any>) => {
      state.shiftDetails = action.payload;
      state.loading = false;
      state.error = null;
    },
    setEditedShift: (state, action: PayloadAction<any>) => {
      state.editedShift = action.payload;
      state.loading = false;
      state.error = null;
    },
    getEditedShift: state => {
      state.loading = true;
      state.error = null;
    },
    getCancelShiftOpening: state => {
      state.loading = true;
      state.error = null;
    },
    setCancelShiftOpening: (state, action: PayloadAction<any>) => {
      state.cancelShiftOpening = action.payload;
      state.loading = false;
      state.error = null;
    },
    getCreateFavorite: state => {
      state.loading = true;
      state.error = null;
    },
    setCreateFavorite: (state, action: PayloadAction<any>) => {
      state.createFavorite = action.payload;
      state.loading = false;
      state.error = null;
    },
    getRemoveFavorite: state => {
      state.loading = true;
      state.error = null;
    },
    setRemoveFavorite: (state, action: PayloadAction<any>) => {
      state.removeFavorite = action.payload;
      state.loading = false;
      state.error = null;
    },
    getCompletedShift: state => {
      state.loading = true;
      state.error = null;
    },
    setGetCompletedShift: (state, action: PayloadAction<any>) => {
      state.completedShift = action.payload;
      state.loading = false;
      state.error = null;
    },
    getAllOverviewShifts: state => {
      state.loading = true;
      state.error = null;
    },
    setGetAllOverviewShifts: (state, action: PayloadAction<any>) => {
      state.allOverviewShifts = action.payload;
      state.loading = false;
      state.error = null;
    },
    getApplicantFacilityInfo: state => {
      state.loading = true;
      state.error = null;
    },
    setGetApplicantFacilityInfo: (state, action: PayloadAction<any>) => {
      state.completedShift = action.payload;
      state.loading = false;
      state.error = null;
    },
  },
});

export const {
  showShifts,
  getShifts,
  setShifts,
  getShiftSummary,
  setShiftSummary,
  deleteShift,
  getSearchShifts,
  setSearchShifts,
  getShiftDetails,
  setShiftDetails,
  setEditedShift,
  getEditedShift,
  getCancelShiftOpening,
  setCancelShiftOpening,
  getCreateFavorite,
  setCreateFavorite,
  getRemoveFavorite,
  setRemoveFavorite,
  getCompletedShift,
  setGetCompletedShift,
  getAllOverviewShifts,
  setGetAllOverviewShifts,
  getApplicantFacilityInfo,
  setGetApplicantFacilityInfo,
} = shiftsSlice.actions;

export const selectShifts = (state: RootState) => state.shifts;

export default shiftsSlice.reducer;