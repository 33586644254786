import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type MobileState = {
  isMobile: boolean;
};

const initialState: MobileState = {
  isMobile: window.innerWidth < 768,
};

const mobileSlice = createSlice({
  name: 'mobile',
  initialState,
  reducers: {
    setIsMobile: (state, action: PayloadAction<boolean>) => {
      state.isMobile = action.payload;
    },
  },
});

export const {setIsMobile} = mobileSlice.actions;
export default mobileSlice.reducer;
