import React, { useState, useEffect } from 'react';
//  ToDo: What does the icon in the top right corner display when a user has not submitted their profile?
//  ToDo: Figure out what is to be displayed on the facility selector and where that is coming from.
//  ToDo: Fix tab index for form.
//  ToDo: Figure out how/if to display users info inside the form input when there is a profile.
//  ToDo: Formatting for phone numbers
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useForm } from 'react-hook-form';
import {
  FormLabel,
  FormControl,
  Input,
  Button,
  Avatar,
  InputGroup,
  InputLeftAddon,
  Select,
  Flex,
  IconButton,
} from '@chakra-ui/react';

import { EditIcon } from '@chakra-ui/icons';

import './CreateProfile.scss';
import { FormData, NDPermissionsArray } from './CreateProfile.types';
import PermissionCard from './PermissionCard/PermissionCard';

const CreateProfile = () => {
  const [isProfileCreated, setIsProfileCreated] = useState(false);
  // const [userData, setUserData] = useState(null)

  useEffect(() => {
    // axios
    //   .get(`http://localhost:8000/users/${userId}`, {
    //     withCredentials: true,
    //   })
    //   .then((res) => {
    //     const ndUserPermissions= res.data;
    //     setUserData(ndUserPermissions);
    //   });
  }, []);

  const ndUserPermissions: NDPermissionsArray = [
    {
      facility: 'Houston Senior Care Center',
      role: 'Scheduler',
      permissions: 'Post shifts, select clincians, manage schedule updates.',
    },
    {
      facility: 'Los Alamos Hospital ',
      role: 'Billing Specialist',
      permissions: 'View shift schedule.',
    },
    {
      facility: 'St. Louis',
      role: 'Clinical Leadership',
      permissions: 'Post shifts, select clincians, manage schedule updates.',
    },
  ];
  //   const phoneNumberRegExp =
  //     /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const schema = yup.object().shape({
    firstName: yup.string().required('First Name is required.'),
    jobTitle: yup.string().required('Job Title is required.'),
    officePhone: yup.string().min(10).required(),
    lastName: yup.string().required('Last Name is required.'),
    jobFunction: yup.string().required('Job Function is required.'),
    mobilePhone: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ resolver: yupResolver(schema) });
  const onSubmit = (data: {}) => {
    // eslint-disable-next-line no-console
    console.log(data);

    // axios
    //   .post(
    //     `http://localhost:8000/users`,
    //     {
    //       firstName: data.firstName,
    //       jobFunction: data.jobFunction,
    //       jobTitle: data.jobTitle,
    //       lastName: data.lastName,
    //       mobilePhone: data.mobilePhone,
    //       officePhone: data.officePhone,
    //     },
    //     { withCredentials: true }
    //   )
    //   .then((res) => {
    //     const newNdUserPermissions = res.data;
    //     setUserData(newNdUserPermissions);
    //   });
    //   .catch((err) => {
    //     console.log('Error creating profile:', err);
    //   });
    //  ToDo: remove setIsProfileCreated and change the check on line 122 to be && userData
    setIsProfileCreated(true);
  };
  return (
    <div className="create-profile">
      <h1 className="create-profile__title">Profile</h1>
      {isProfileCreated && (
        <div className="create-profile__name-and-edit-box">
          <div className="create-profile__name-box">
            <div>
              <Avatar size="xs" bg="#313971" name="B R" color="white" />
            </div>
            <div className="create-profile__name">Belinda Reynolds</div>
          </div>
          <IconButton
            className="create-profile__icon-btn"
            aria-label="Edit Profile"
            icon={<EditIcon />}
          />
          <Button className="create-profile__edit-btn">Edit info</Button>
        </div>
      )}
      {!isProfileCreated && (
        <div className="create-profile__description-box">
          <div className="create-profile__description-header">
            Set up your profile
          </div>
          <div className="create-profile__description-sub-header">
            Complete the following fields with your information to personalize
            your NurseDash experience.
          </div>
        </div>
      )}
      <form className="create-profile__form" onSubmit={handleSubmit(onSubmit)}>
        <FormControl className="create-profile__form-control">
          <div className="create-profile__form-split">
            <FormLabel htmlFor="firstName">First Name *</FormLabel>
            <Input
              className="create-profile__input"
              id="firstName"
              {...register('firstName')}
            />
            <p className="create-profile__form-error">
              {errors.firstName?.type === 'required' && (
                <p>First Name is required</p>
              )}
            </p>
            <FormLabel htmlFor="jobTitle">Job Title *</FormLabel>
            <Input
              className="create-profile__input"
              id="jobTitle"
              placeholder='ex: "Nurse"'
              {...register('jobTitle')}
            />
            <p className="create-profile__form-error">
              {errors.jobTitle?.type === 'required' && (
                <p>Job Title is required</p>
              )}
            </p>

            <FormLabel htmlFor="officePhone">Office Phone *</FormLabel>
            <InputGroup
              className="create-profile__input-group"
              flexDirection="column">
              <Flex w="100%">
                <InputLeftAddon className="create-profile__input-addon">
                  +1
                </InputLeftAddon>
                <Input
                  className="create-profile__input"
                  id="officePhone"
                  placeholder="Office Phone"
                  {...register('officePhone')}
                />
              </Flex>
              <p className="create-profile__form-error">
                {errors.officePhone?.type === 'min' && (
                  <p>Minimum of 10 digits required</p>
                )}
              </p>
            </InputGroup>
          </div>
          <div className="create-profile__form-split">
            <FormLabel htmlFor="lastName">Last Name *</FormLabel>
            <Input
              className="create-profile__input"
              id="lastName"
              {...register('lastName')}
            />
            <p className="create-profile__form-error">
              {errors.lastName?.type === 'required' && (
                <p>Last Name is required</p>
              )}
            </p>
            <FormLabel htmlFor="jobFunction">Job Function *</FormLabel>

            <Select
              id="jobFunction"
              {...register('jobFunction')}
              className="create-profile__input"
              placeholder="Select">
              <option value="option1">Scheduler</option>
              <option value="option2">Billing Specialist </option>
              <option value="option3">Clinical Leadership</option>
              <option value="option4">Facility Leadership</option>
              <option value="option5">Corporate Leadership</option>
            </Select>

            <p className="create-profile__form-error">
              {errors.jobFunction?.type === 'required' && (
                <p>Job Function is required</p>
              )}
            </p>

            <FormLabel htmlFor="mobilePhone">Mobile Phone</FormLabel>
            <InputGroup>
              <InputLeftAddon className="create-profile__input-addon">
                +1
              </InputLeftAddon>

              <Input
                className="create-profile__input"
                id="mobilePhone"
                placeholder="Mobile Phone"
                {...register('mobilePhone')}
              />
            </InputGroup>
          </div>
        </FormControl>
        <div className="create-profile__btn-box">
          <Button mt={4} type="submit" variant="secondary" w="8rem">
            Cancel
          </Button>
          <Button mt={4} type="submit" variant="primary" w="8rem">
            Save
          </Button>
        </div>
      </form>
      {isProfileCreated && (
        <PermissionCard ndUserPermissions={ndUserPermissions} />
      )}
    </div>
  );
};

export default CreateProfile;
