import React, {ReactNode, useEffect} from 'react';
import {ApolloClient, DefaultOptions, InMemoryCache} from '@apollo/client';
import {useAuth0} from '@auth0/auth0-react';
import jwt_decode from 'jwt-decode';
import {RootState} from 'store';
import useLogout from "../../shared/hooks/useLogout";
import {ApolloClientsProvider} from './ApolloContextProvider';
import {setDecodedToken, setToken} from '../../shared/slices/authSlice';
import useAppDispatch from '../../shared/hooks/useAppDispatch';
import useAppSelector from '../../shared/hooks/useAppSelector';

const uri = process.env.REACT_APP_API_URL ?? '';
const legacyUri = process.env.REACT_APP_LEGACY_API_URL ?? '';
const fetchPolicy = 'no-cache';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy,
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy,
    errorPolicy: 'all',
  },
};

interface IAuth0TokenError extends Error{
  error: string
}

const ApolloGqlProvider = ({children}: {children?: ReactNode}) => {
  const {getAccessTokenSilently, isAuthenticated,  isLoading} = useAuth0();
  const urlParams = localStorage.getItem('legacyFidParam');
  const ndAdminUserId = localStorage.getItem('legacyNDAdminUserId');
  const {token} = useAppSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();
  const logout = useLogout()
  const isAdminUser = urlParams && ndAdminUserId

  useEffect(() => {
    (async () => {
      try {
        if (!token && isAuthenticated && !isLoading) {
          const accessToken = await getAccessTokenSilently({
            cacheMode: isAdminUser ? 'cache-only' : 'off', // cache mode off while normal facility user and cached mode if admin with backdoor entry from admin panel
          });// When 'off' ignores the cache and always sends a request to Auth0
            
          if (accessToken) {
            dispatch(setToken(accessToken));
            dispatch(setDecodedToken(jwt_decode(accessToken)));
          }
        }
      } catch (e: unknown ) {
        const error = e as IAuth0TokenError
        if (error && error.error && (error?.error === 'missing_refresh_token' || error?.error === 'invalid_grant')) {
          await logout()
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading, isAuthenticated, token]);

  // Initialization of the main Apollo Client instance
  const client = new ApolloClient({
    uri,
    cache: new InMemoryCache(),
    defaultOptions,
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  });

  // Initialization of the legacy Apollo Client instance
  const legacyClient = new ApolloClient({
    uri: legacyUri,
    cache: new InMemoryCache(),
    defaultOptions,
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  });

  // Provide the Apollo Client instances to the component tree
  return (
    <ApolloClientsProvider client={client} legacyClient={legacyClient}>
      {children}
    </ApolloClientsProvider>
  );
};

export default ApolloGqlProvider;
