import React, {useEffect, useRef} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  Flex,
} from '@chakra-ui/react';
import uuid from 'react-uuid';
import IndividualMessage from './IndividualMessage';
import MessageInput from './MessageInput';
import ModalSkeleton from './ModalSkeleton';

import useGetAllLegacyChannels, {useGetLegacyChannel} from '../../shared/gql/messages/queries';
import useResetLegacyMemberUnreadCount from '../../shared/gql/messages/mutations';
import useAppDispatch from '../../shared/hooks/useAppDispatch';
import {setMessages} from '../../shared/slices/messagesSlice';

// ToDo: Fix any type
const PreviewCardModal = ({isOpen, onClose, cardClickedDetails, handleSuccessResponse , setActionDone}: any) => {
  const {nurseName, shiftStartTime, shiftEndTime, shiftRole} = cardClickedDetails;
  const {data, loading, refetch} = useGetLegacyChannel({variables: {id: cardClickedDetails.id}, skip: !isOpen});
  const [resetLegacyMemberUnreadCount] = useResetLegacyMemberUnreadCount({
    variables: {channelId: cardClickedDetails.id},
  })
  const dispatch = useAppDispatch();
  const {refetch: refetchAllChannels} = useGetAllLegacyChannels();
  

  const refetchChannels = () => {
    setTimeout(async() => {
      const dataAfter = await refetchAllChannels();
      if(dataAfter.data && dataAfter.data.allChannels) {
        dispatch(setMessages(dataAfter.data.allChannels));
      }
    }, 500);
  };

  const modalBodyRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const resetCount = async () => {
      if (cardClickedDetails.id) {
        try {
          await resetLegacyMemberUnreadCount({
            variables: {channelId: cardClickedDetails.id},
          });
        } catch (error) {
          console.error('Failed to reset member unread count', error);
        }
      }
    };
    resetCount().then(() => {
      refetch();
      refetchChannels();
    });
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardClickedDetails.id]);

  useEffect(() => {
    const scrollToBottom = () => {
      if (modalBodyRef.current) {
        modalBodyRef.current.scrollTop = modalBodyRef.current.scrollHeight;
      }
    };

    scrollToBottom();
  }, [loading, isOpen]);

  const messages = data?.Channel?.messages || [];
  const sortedMessages = [...messages]?.sort(
    (a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered>
      <ModalOverlay />
      <ModalContent>
        {loading ? (
          <ModalSkeleton />
        ) : (
          <>
            <ModalHeader>
              <Box>{nurseName}</Box>
              <Flex gap=".5rem" alignItems="center">
                <Text fontWeight="600" fontSize="0.875rem" color="rgba(113, 128, 150, 1)">
                  {`${shiftRole} | ${shiftStartTime}`}
                </Text>
                <Text fontWeight="600" fontSize="0.875rem" color="rgba(113, 128, 150, 1)">
                  -
                </Text>
                <Text fontWeight="600" fontSize="0.875rem" color="rgba(113, 128, 150, 1)">
                  {shiftEndTime}
                </Text>
              </Flex>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody ref={modalBodyRef}>
              <Box minHeight="20rem">
                <Flex flexDir="column" height="100%" minHeight="20rem" justifyContent="flex-end">
                  {sortedMessages?.map((message: any) => (
                    <Box key={uuid()}>
                      <IndividualMessage
                        message={message.text}
                        isSent={message.member.user.name !== nurseName}
                        isAdmin={message.member.user.userModel === 'user'}
                        createdAt={message.createdAt}
                      />
                    </Box>
                  ))}
                </Flex>
              </Box>
            </ModalBody>
            <Flex width="100%" paddingBottom="1.5">
              <MessageInput
                onClose={onClose}
                channelId={cardClickedDetails.id}
                handleSuccessResponse={handleSuccessResponse}
                setActionDone={setActionDone}
              />
            </Flex>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default PreviewCardModal;
