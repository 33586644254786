import React, {useEffect} from 'react';
import {
  Modal as ChakraModal,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

type Props = {
  handleIsModalOpen: (value: {isOpen: boolean; data: {src: string; title?: string} | null}) => void;
  data: {
    src: string;
    title?: string;
  } | null;
};

const PdfModal = ({handleIsModalOpen, data}: Props) => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  const handleClose = () => {
    onClose();
    handleIsModalOpen({data: null, isOpen: false});
  };

  useEffect(() => {
    onOpen();
  }, [onOpen]);

  return (
    <ChakraModal isOpen={isOpen} onClose={handleClose} isCentered size="6xl">
      <ModalOverlay />
      <ModalContent style={{maxWidth: 'calc(100% - 120px)', minHeight: 'calc(100% - 140px)'}}>
        <ModalHeader>Pdf</ModalHeader>
        <ModalCloseButton size="sm" marginTop={['.2rem', '.6rem']} />
        <ModalBody width="100%" height='100%'>
          {data?.src ? (
            <iframe src={`${data.src}#toolbar=0`} frameBorder={0} title="demo" width="100%" height='700px' />
          ) : (
            <Flex width="100%" minHeight="15rem" alignItems="center" justifyContent="center">
              <Text fontWeight="600">No Pdf Found</Text>
            </Flex>
          )}
        </ModalBody>

        <ModalFooter> </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};

export default PdfModal;
