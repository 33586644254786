import {useMutation, gql} from '@apollo/client';
import {MutationHookOptions} from '@apollo/react-hooks';
import {useApolloClients} from '../../../core/providers/ApolloContextProvider';

const resetLegacyMemberUnreadCount = () => gql`
  mutation resetMemberUnreadCount($channelId: Int!) {
    resetMemberUnreadCount(channelId: $channelId) {
      result
    }
  }
`;

const useResetLegacyMemberUnreadCount = (options?: MutationHookOptions<any, any>) => {
  const {legacyClient} = useApolloClients();

  return useMutation<any, any>(resetLegacyMemberUnreadCount(), {
    ...options,
    client: legacyClient,
  });
};

const addLegacyMessage = () => gql`
  mutation addMessage($channelId: Int!, $text: String!) {
    addMessage(channelId: $channelId, text: $text) {
      createdAt
      text
      channelId
    }
  }
`;

export const useAddLegacyMessage = (options?: MutationHookOptions<any, any>) => {
  const {legacyClient} = useApolloClients();
  return useMutation<any, any>(addLegacyMessage(), {
    ...options,
    client: legacyClient,
    
  });
};

export default useResetLegacyMemberUnreadCount;
