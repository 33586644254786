export type OpeningStatusOrderType = {
    PendingReview: number;
    Confirmed: number;
    Selected: number;
    PendingCheckIn: number;
    PendingClockIn: number;
    Completed: number;
    NoCallNoShow: number;
    OpenedWithApplicants: number;
    Opened: number;
    Cancelled: number;
  };

export const openingStatusOrder: OpeningStatusOrderType = {
    PendingReview: 1,
    Confirmed: 2,
    PendingCheckIn: 3,
    PendingClockIn: 4,
    Completed: 5,
    NoCallNoShow: 6,
    Selected: 7,
    OpenedWithApplicants: 8,
    Opened: 9,
    Cancelled: 10,
  };