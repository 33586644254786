import trackEvent from './trackEvent';
import MixpanelConstants from '../constants';



// from profile 
const trackNdproFavEventFromProfile = (metadata: any, nurseId: number) =>
  trackEvent(MixpanelConstants.FAVORITE_NDPRO_FROM_PROFILE, {metadata, nurseId});
const trackNdproRemoveFavEventFromProfile = (metadata: any, nurseId: number) =>
  trackEvent(MixpanelConstants.UNFAVORITE_NDPRO_FROM_PROFILE, {metadata, nurseId});
const trackNdproSelectFromProfile = (metadata: any, nurseId: number) =>
  trackEvent(MixpanelConstants.SELECT_NDPRO_FROM_PROFILE, {metadata, nurseId});
const trackNdproUnSelectFromProfile = (metadata: any, nurseId: number) =>
  trackEvent(MixpanelConstants.UNSELECT_NDPRO_FROM_PROFILE, {metadata, nurseId}); // remianing
const trackArchiveApplicantFromProfile = (metadata: any, nurseId: number) =>
  trackEvent(MixpanelConstants.ARCHIVE_NDPRO_FROM_PROFILE, {metadata, nurseId});
const trackUnArchiveApplicantFromProfile = (metadata: any, nurseId: number) =>
  trackEvent(MixpanelConstants.UNARCHIVE_NDPRO_FROM_PROFILE, {metadata, nurseId});

export {
  trackNdproRemoveFavEventFromProfile,
  trackNdproFavEventFromProfile,
  trackNdproSelectFromProfile,
  trackNdproUnSelectFromProfile,
  trackUnArchiveApplicantFromProfile,
  trackArchiveApplicantFromProfile,
};
