const settingFields = `
  address
  allowedQualifications
  areaId
  areaName
  segmentId
  segmentName
  selectArea
  checkReliability
  covidMedicalExemption
  covidReligiousExemption
  covidStatus
  covidStatusCheck
  covidSurveyActionName
  createdAt
  crypted_password
  deletedAt
  email
  emailList {
    email
  }
  emails
  end_time
  exempt_from_holiday_pay
  geofenceRadius
  id
  image {
    src
    data
    title
  }
  isOrientationMaterialsShowForAll
  isSignatureRequired
  isSuper
  isSupervisorEmailRequired
  isSupervisorPhoneRequired
  lat
  lng
  login
  min_bonus_amount
  max_bonus_amount
  name
  orientationMaterials {
    uuid
    src
    data
    title
    pages {
      src
      page
    }
    preparing {
      status
    }
  }
  passwordHash
  phones {
    phone
  }
  rates {
    type
    unit
    description
    rate
    netPay
    weekendDiff
    clockinInstruction
    clockoutInstruction
    defaultShiftDescription
    defaultShiftQualifications
  }
  short_name
  start_time
  status
  timezone
  trackSignatureVerificationPrompt
  trackCovidSurveyPopup
  autoSelectType
  selectionType
  unitDescriptions {
    unit
    description
  }
  ucw_feature_flag
  updateFutureAutoselectShifts
  updatedAt
  uuid
  covidVaccineRequired
  allowSingleDoseVaccine
  shifts {
    id
  }
`;

export default settingFields;