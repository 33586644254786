import React from 'react';
import './SideBarAnnouncement.scss';

const SideBarAnnouncement = () => (
    <div className="side-bar">
      <div className="side-bar__text-container">
        <span className="side-bar__title">
          Create your personal NurseDash account
        </span>
        <div className="side-bar__text">
          <span className="side-bar__text--show side-bar__line-break">
            NurseDash is upgrading its site just for you!
          </span>

          <span className=" side-bar__line-break">
            {' '}
            In order to get access to new and exclusive features,{' '}
            <span className="side-bar__text--bold ">
              we need you to create your own personal NurseDash account.
            </span>
          </span>

          <div className="side-bar__text--show side-bar__line-break">
            <span className="side-bar__text--bold ">Don’t worry! </span>
            <span>
              None of the information on the current account will be lost.
            </span>
          </div>
        </div>
      </div>
    </div>
  );

export default SideBarAnnouncement;
