import React from 'react';

import {Box, Button, Flex, Heading, Text} from '@chakra-ui/react';
import {AddIcon, InfoIcon} from '@chakra-ui/icons';

type Props = {
  handleIsNewTemplateModalOpen: () => void;
};

const Info = ({handleIsNewTemplateModalOpen}: Props) => (
  <Box textAlign="center" py={10} px={6}>
    <InfoIcon boxSize="50px" color="#38427E" />
    <Heading fontFamily="open-sans" fontWeight="700" fontSize="1.5rem" mt={6} mb={2}>
      No shift templates found
    </Heading>
    <Text color="#4A5568">
      We’re sorry, we can’t find any shift templates that match your filters. Why not create one now!
    </Text>
    <Flex justifyContent="center" marginTop="2rem">
      {/* Mobile Button */}
      <Button
        as={AddIcon}
        display={['inherit', 'none']}
        variant="primary"
        onClick={() => handleIsNewTemplateModalOpen()}
        type="button"
        width="2.5rem"
        height="2.5rem"
        padding="0.7rem"
      />
      {/* Desktop Button */}
      <Button
        rightIcon={<AddIcon />}
        variant="primary"
        onClick={() => handleIsNewTemplateModalOpen()}
        type="button"
        w="10rem"
        h="2.5rem"
        padding="0.7rem"
        display={['none', 'inherit']}
      >
        New Template
      </Button>
    </Flex>
  </Box>
);

export default Info;
