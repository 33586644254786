import React from 'react';
import './LoginForm.scss';
import NDLogo from '../../../assets/logo/nd-logo.svg';
import RedirectToAuth0Button from '../RedirectToAuth0Button/RedirectToAuth0Button';

const LoginForm: React.FC = () => (
  <div className="form">
    <div className="form__text-container">
      <img className="form__logo" src={NDLogo} alt="Nurse Dash Logo" />
      <div className="form__title">Welcome</div>
      <span className="form__sub-title">Your personalized NurseDash account is just a few clicks away.</span>
      <span className="form__auth-btn-container">
        <RedirectToAuth0Button />
      </span>
    </div>
  </div>
);
export default LoginForm;
