import {useMutation, gql} from '@apollo/client';
import {MutationHookOptions} from '@apollo/react-hooks';
// import {setErrorData} from 'shared/slices/testSlice';
import shiftTemplateFields from './fields';
import {
  CreateShiftTemplateData,
  CreateShiftTemplateVariables,
  UpdateShiftTemplateVariables,
  UpdateShiftTemplateData,
} from './types';
import useAppDispatch from '../../hooks/useAppDispatch';
import {
  createShiftTemplate as createShiftTemplateAction,
  setShiftTemplates,
  updateShiftTemplate as updateShiftTemplateAction,
} from '../../slices/shiftTemplateSlice';
import useAppSelector from '../../hooks/useAppSelector';

import {useApolloClients} from '../../../core/providers/ApolloContextProvider';

const createShiftTemplate = (fields: string = shiftTemplateFields) => gql`
  mutation CreateShiftTemplate($cst: CreateShiftTemplateInput!){
    createShiftTemplate(shiftTemplateInput: $cst){
      ${fields}
    }
  }`;

const useCreateShiftTemplate = (
  options?: MutationHookOptions<CreateShiftTemplateData, CreateShiftTemplateVariables>,
  fields?: string
) => {
  const {client} = useApolloClients();
  const shiftTemplates = useAppSelector((state: any) => state.shiftTemplates.data);

  const dispatch = useAppDispatch();
  dispatch(createShiftTemplateAction());

  return useMutation<CreateShiftTemplateData, CreateShiftTemplateVariables>(createShiftTemplate(fields), {
    ...options,
    client,
    onCompleted: data => {
      const newTemplate = data.createShiftTemplate;
      const newTemplates = [...shiftTemplates, newTemplate];
      dispatch(setShiftTemplates(newTemplates));
    },
    onError: err => {
      console.log('err from createShiftTemplate', err);
    },
  });
};

const updateShiftTemplate = (fields: string = shiftTemplateFields) => gql`
  mutation UpdateShiftTemplate($shiftTemplateInput: UpdateShiftTemplateInput!){
    updateShiftTemplate(shiftTemplateInput: $shiftTemplateInput){
      ${fields}
    }
  }`;

const useUpdateShiftTemplate = (
  options?: MutationHookOptions<UpdateShiftTemplateData, UpdateShiftTemplateVariables>,
  fields?: string
) => {
  const {client} = useApolloClients();
  const shiftTemplates = useAppSelector((state: any) => state.shiftTemplates.data);

  const dispatch = useAppDispatch();
  dispatch(updateShiftTemplateAction());

  return useMutation<UpdateShiftTemplateData, UpdateShiftTemplateVariables>(updateShiftTemplate(fields), {
    ...options,
    client,
    onCompleted: data => {
      const updatedTemplate = data.updateShiftTemplate;
      const updatedTemplates = shiftTemplates.filter((template: any) => template.id !== updatedTemplate.id);

      updatedTemplates.push(updatedTemplate);
      dispatch(setShiftTemplates(updatedTemplates));
    },
    onError: ({graphQLErrors, networkError}) => {
      if (graphQLErrors) {
        graphQLErrors.map(({message, locations, path}) =>
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        );
      }
      if (networkError) console.log(`[Network error]: ${networkError}`);
    },
  });
};

const deleteShiftTemplate = (fields: string = shiftTemplateFields) => gql`
  mutation DeleteShiftTemplate($shiftTemplateId: String!, $facilityId: String!){
    deleteShiftTemplate(shiftTemplateId: $shiftTemplateId, facilityId: $facilityId){
      ${fields}
    }
  }`;

const useDeleteShiftTemplate = (options?: MutationHookOptions, fields?: string) => {
  const {client} = useApolloClients();
  const shiftTemplates = useAppSelector((state: any) => state.shiftTemplates);
  const dispatch = useAppDispatch();

  return useMutation(deleteShiftTemplate(fields), {
    ...options,
    client,
    onCompleted: data => {
      const newTemplates = shiftTemplates.data.filter((template: any) => template.id !== data.deleteShiftTemplate.id);
      dispatch(setShiftTemplates(newTemplates));
    },
    onError: err => {
      console.log('err from useDeleteShiftTemplate', err);
    },
    ...options,
  });
};

export {useCreateShiftTemplate, useUpdateShiftTemplate, useDeleteShiftTemplate};
