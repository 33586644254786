const allChannelsFields = `
id
members {
  id
  unreadCount
  user {
    id
    name
    userId
    userModel
    image{
      src
    }
  }
}
lastMessage {
  text
  createdAt
  member {
    user {
      name
      userModel
    }
  }
}
applicant {
  nurse {
    name
  }
  shift {
    end_time
    start_time
    role
    shiftApplications {
        shift{
            role
        }
  }
}
}
`;

export default allChannelsFields;
