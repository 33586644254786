import {createSlice, PayloadAction} from '@reduxjs/toolkit';
// ToDo: fix any types
export type MessagesState = {
    data: any[];
    loading: boolean;
    error: string | null;
    message: any | null;
}

const initialState: MessagesState = {
    data: [],
    loading: false,
    error: null,
    message: null
};

export const messagesSlice = createSlice({
    name: 'allChannels',
    initialState,
    reducers: {
        setMessages: (state, action: PayloadAction<any>) => {
            state.data = action.payload;
            state.loading = false;
            state.error = null;
        },
        setLastMessage: (state, action: PayloadAction<any>) => {
            state.data = state.data.map((channel: any) => {
                if (channel.id === action.payload.channelId) {
                    const updatedLastMessage = {
                        ...channel.lastMessage,
                        createdAt: action.payload.createdAt,
                        text: action.payload.text,
                    };
                    return {
                        ...channel,
                        lastMessage: updatedLastMessage,
                    };
                }
                return channel;
            });
        }
    },
});

export const {setMessages, setLastMessage} = messagesSlice.actions;

export default messagesSlice.reducer;