import {useEffect} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {useNavigate, useLocation} from 'react-router-dom';
import {RootState} from 'store';
import useAppSelector from './useAppSelector';
import useAppDispatch from './useAppDispatch';

// This hook is a precaution to prevent users from accessing pages they are
// not authorized to access in the event their token expires at any point
// while the app remains open in a browser tab
// TODO: Extend this login to a auth guard on the route itself.
const loginRoute = '/login';
const homeRoute = '/home';

const useIsAuthenticated = () => {
  const {user, isAuthenticated, isLoading} = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const {token} = useAppSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLoading) return;
    if (isAuthenticated && location.pathname === loginRoute) navigate(homeRoute);
    if (!isAuthenticated && location.pathname !== loginRoute) navigate(loginRoute);
  }, [dispatch, isAuthenticated, isLoading, location.pathname, navigate, token, user]);
};

export default useIsAuthenticated;
