/* eslint-disable no-nested-ternary */
import React from 'react';
import {Flex, Text, IconButton, Input, Box, NumberInput, NumberInputField} from '@chakra-ui/react';
import {AddIcon, MinusIcon} from '@chakra-ui/icons';
import {Position} from '../../types/position';

import {Props} from './RateSelector.types';

const RateSelector = ({getValues, shiftTemplate, isEditable, selectedPositionBaseRate, setValue}: Props) => {
  const handleRateChange = (e: string, position: Position) => {
    const userInput = e;
    const userInputAsNumber = parseInt(userInput.replace('.', ''), 10);

    const positionToUpdate = selectedPositionBaseRate?.find(
      (positionObject: {name: string; baseRate: number}) => positionObject.name === position.name
    );

    let updateAdjustmentRate = 0;

    if (positionToUpdate && !Number.isNaN(userInputAsNumber)) {
      updateAdjustmentRate = Math.abs(positionToUpdate.baseRate - userInputAsNumber);
    }

    const updatedPositionArray = getValues('position')?.map((positionObject: Position) => {
      if (positionObject.name === position.name) {
        return {
          ...positionObject,
          rate: userInput.toString(),
          adjustmentRate: updateAdjustmentRate,
        };
      }
      return positionObject;
    });

    setValue('position', updatedPositionArray as Position[], {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const handleIncrementRate = (position: Position) => {
    if (typeof position.rate === 'string') {
      position = {...position, rate: Number(position.rate) * 100};
    }

    const positionToUpdate = selectedPositionBaseRate?.find(
      (positionObject: {name: string; baseRate: number}) => positionObject.name === position.name
    );

    const updatePositionRate = typeof position.rate === 'string' ? Number(position.rate) + 100 : position.rate + 100;

    // eslint-disable-next-line no-unsafe-optional-chaining
    const updateAdjustmentRate = positionToUpdate && Math.abs(positionToUpdate?.baseRate - updatePositionRate);

    const updatedPositionArray = getValues('position')?.map((positionObject: Position) => {
      if (positionObject.name === position.name) {
        return {
          ...positionObject,
          rate: updatePositionRate,
          adjustmentRate: Number(updateAdjustmentRate?.toFixed(2)),
        };
      }

      return positionObject;
    });

    setValue('position', updatedPositionArray, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
  };

  const handleDecrementRate = (position: Position) => {
    if (typeof position.rate === 'string') {
      position = {...position, rate: Number(position.rate) / 100};
    }

    const positionToUpdate = selectedPositionBaseRate?.find(
      (positionObject: {name: string; baseRate: number}) => positionObject.name === position.name
    );

    const updatePositionRate = typeof position.rate === 'string' ? Number(position.rate) - 100 : position.rate - 100;

    // eslint-disable-next-line no-unsafe-optional-chaining
    const updateAdjustmentRate = positionToUpdate && Math.abs(positionToUpdate?.baseRate - updatePositionRate);
    if (updatePositionRate < 0) {
      return;
    }

    const updatedPositionArray = getValues('position')?.map((positionObject: Position) => {
      if (positionObject.name === position.name) {
        return {
          ...positionObject,
          rate: updatePositionRate,
          adjustmentRate: Number(updateAdjustmentRate?.toFixed(2)),
        };
      }

      return positionObject;
    });

    setValue('position', updatedPositionArray, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
  };

  return (
    <Flex flexDirection="column" w={['inherit', '100%']} gap=".5rem">
      <Text fontWeight="600">Rates</Text>
      <Flex flexDirection={['column', 'row']} gap={['inherit', '3rem']}>
        {getValues('position')?.length ? (
          getValues('position')?.map((position: Position) => (
            <Flex flexDirection="column" gap=".5rem" key={position.id}>
              <Text fontWeight="600" color="#4A5568" marginBottom=".5rem">
                {position.name} Rate
              </Text>
              <Flex gap=".5rem">
                <IconButton
                  width=".5rem"
                  variant="outline"
                  color="#718096"
                  aria-label="Minus"
                  isDisabled={!!shiftTemplate && !isEditable}
                  _disabled={{
                    opacity: '0.6',
                  }}
                  borderRadius="50%"
                  icon={<MinusIcon />}
                  onClick={() => handleDecrementRate(position)}
                />
                <NumberInput
                  precision={2}
                  step={0.2}
                  defaultValue={position?.rate || 0}
                  value={typeof position.rate === 'number' ? (position.rate / 100).toFixed(2) : position?.rate || 0}
                  width="6.5rem"
                  textAlign="center"
                  isDisabled={!!shiftTemplate && !isEditable}
                  _disabled={{
                    opacity: '0.6',
                  }}
                  onChange={(e: string) => handleRateChange(e, position)}
                >
                  <NumberInputField />
                  <Box
                    opacity={!!shiftTemplate && !isEditable ? '0.4' : 'inherit'}
                    position="absolute"
                    left="0.5rem"
                    top="50%"
                    transform="translateY(-50%)"
                  >
                    $
                  </Box>
                </NumberInput>
                <IconButton
                  width=".5rem"
                  variant="outline"
                  color="#718096"
                  aria-label="Add"
                  borderRadius="50%"
                  isDisabled={!!shiftTemplate && !isEditable}
                  _disabled={{
                    opacity: '0.6',
                  }}
                  icon={<AddIcon />}
                  onClick={() => handleIncrementRate(position)}
                />
              </Flex>
              <Text color="#718096" marginBottom={['1.5rem', 'inherit']} maxW={['inherit', '12rem']}>
                Set the hourly rate for {`${position.name}'s`} working this shift
              </Text>
            </Flex>
          ))
        ) : (
          <Box>
            <Flex gap=".5rem">
              <IconButton
                width=".5rem"
                variant="outline"
                color="#718096"
                aria-label="Add"
                borderRadius="50%"
                icon={<MinusIcon />}
                isDisabled
                _disabled={{
                  opacity: '0.6',
                }}
              />
              <Input
                isDisabled
                _disabled={{
                  opacity: '0.6',
                }}
                width="4rem"
                textAlign="center"
              />
              <IconButton
                width=".5rem"
                variant="outline"
                color="#718096"
                aria-label="Add"
                borderRadius="50%"
                isDisabled
                _disabled={{
                  opacity: '0.6',
                }}
                icon={<AddIcon />}
              />
            </Flex>
            <Text color="#718096" marginBottom={['1.5rem', 'inherit']}>
              Select a position to set the hourly rate for your shift
            </Text>
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default RateSelector;
