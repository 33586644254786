import React from 'react';
import {Box, Text} from '@chakra-ui/react';
import formatTimeWithZone from './helpers/helpers';
import {useTimeZone} from '../Shifts/CreateShift/appSelectors';

type Props = {
  message: string;
  isSent: boolean;
  isAdmin?: boolean;
  createdAt: string;
};

const IndividualMessage = ({message, isSent, createdAt, isAdmin}: Props) => {
  const timeZone = useTimeZone();
  let bgColor = isSent ? 'rgba(49, 57, 113, 1)' : '#EDF2F7';
  if (isAdmin) {
    bgColor = '#40ba7c';
  }

  const messageSentTime = formatTimeWithZone(createdAt, 'M/D/YYYY h:mma', timeZone);
  return (
    <Box marginBottom="1.5rem">
      <Box
        maxWidth="80%"
        bg={bgColor}
        color={isSent ? 'white' : 'black'}
        padding="0.5rem 0.75rem"
        borderRadius="1.25rem"
        marginLeft={isSent ? 'auto' : '0.625rem'}
        marginRight={isSent ? '0.625rem' : 'auto'}
        marginBottom="0.3rem"
        w="fit-content"
      >
        <Text>{message}</Text>
      </Box>
      <Box
        marginLeft={isSent ? 'auto' : '0.625rem'}
        marginRight={isSent ? '0.625rem' : 'auto'}
        fontWeight="600"
        fontSize="0.75rem"
        textAlign={isSent ? 'right' : 'left'}
      >
        {messageSentTime}
      </Box>
    </Box>
  );
};

export default IndividualMessage;
