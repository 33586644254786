import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {RootState} from '../../store';

// Define a type for the slice state
export type SidebarState = {
  display: boolean;
};

// Define the initial state using that type
const initialState: SidebarState = {
  display: true,
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    toggleSidebar: (state: {display: boolean}, action: PayloadAction<boolean>) => {
      state.display = action.payload;
    },
  },
});

export const {toggleSidebar} = sidebarSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectDisplaySidebar = (state: RootState) => state.sidebar.display;

export default sidebarSlice.reducer;
