/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';

import {DefaultValues} from '../TemplateForm.types';
import {ShiftTemplate} from '../../../../shared/slices/shiftTemplateSlice';
import {Position} from '../../../../shared/types/position';

export const getShiftTemplateNames = (shiftTemplates: {}[]) => shiftTemplates?.map((template: any) => template.name);

export const getDefaultValues = (template: ShiftTemplate | null, positions: Position[] | undefined): DefaultValues => {
  if (!template) {
    return {
      templateName: undefined,
      unit: undefined,
      startTime: undefined,
      endTime: undefined,
      numOfOpenings: 0,
      day: undefined,
      bonus: 0,
      position: positions,
      qualifications: undefined,
      description: '',
      clockInInstruction: '',
      clockOutInstruction: '',
    };
  }

  const {
    name: templateName,
    content: {
      unit,
      startTime,
      endTime,
      openings,
      bonusAmount: bonus,
      qualifications,
      description,
      clockInInstruction,
      clockOutInstruction,
    },
  } = template;

  const {total: numOfOpenings = 0, dayOfWeek: day} = openings[0] ?? {};

  return {
    templateName,
    unit: {value: unit.id, label: unit.name},
    startTime,
    endTime,
    numOfOpenings,
    day,
    bonus,
    position: positions,
    ...(template.content.break && {break: template.content.break}),
    qualifications,
    description,
    clockInInstruction,
    clockOutInstruction,
  };
};

export const getSchema = (isEditable: boolean, shiftTemplateNames: string[]) =>
  yup.object().shape({
    templateName: !isEditable
      ? yup
          .string()
          .notOneOf(shiftTemplateNames, 'This name has already been taken')
          .required('Template name is required')
      : yup.string().required('Template name is required'),
    unit: yup.object().required('Unit is required'),
    position: yup.array().min(1).required('Position is required'),
    bonus: yup.number(),
    day: yup.string(),
    startTime: yup.string().required('Start time is required'),
    endTime: yup.string().required('End time is required'),
    break: yup.string(),
    numOfOpenings: yup.number(),
    qualifications: yup.array().of(yup.object().shape({name: yup.string(), id: yup.string()})),
    description: yup.string().nullable(),
    clockInInstruction: yup.string().nullable(),
    clockOutInstruction: yup.string().nullable(),
  });
