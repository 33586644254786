import React from 'react';
import {FormLabel, Input, Box, Text, InputRightElement, InputGroup, IconButton} from '@chakra-ui/react';
import {ViewIcon, ViewOffIcon} from '@chakra-ui/icons';

type Props = {
  register: any;
  label: string;
  placeholder: string;
  name: string;
  errors: any;
};

const PasswordInput = ({register, label, placeholder, name, errors}: Props) => {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  return (
    <>
      <FormLabel fontWeight="400" htmlFor={name}>
        {label}
      </FormLabel>
      <Box marginBottom="1rem">
        <InputGroup size="md">
          <Input
            placeholder={placeholder}
            _placeholder={{color: '#718096'}}
            type={show ? 'text' : 'password'}
            border={errors?.[name] ? '1px solid #C53030' : '1px solid #E2E8F0'}
            borderRadius="6px"
            variant='outline'
            autoComplete="new-password"
            {...register(name)}
          />
          <InputRightElement >
            <IconButton
              variant="outline"
              aria-label="show password"
              borderWidth="0"
              fontSize="17px"
              size="sm"
              _hover={{ bg: 'none' }}
              onClick={handleClick}
              icon={show ? <ViewOffIcon /> : <ViewIcon />}
            />
          </InputRightElement>
        </InputGroup>
        {errors?.[name] && (
          <Text fontSize="12px" color="#C53030" marginTop=".5rem">
            {errors[name].message as string}
          </Text>
        )}
      </Box>
    </>
  );
};

export default PasswordInput;
