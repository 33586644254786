import React from 'react';
import {Box, VStack, Skeleton, useColorModeValue, SkeletonText} from '@chakra-ui/react';

const ModalSkeleton = () => {
  const bg = useColorModeValue('white', 'gray.700');

  return (
    <Box width="sm" mx="auto" p={4} bg={bg} borderRadius="md" height="auto" minHeight="38rem">
      <Skeleton height="1.5rem" mb={2} width="40%" />
      <SkeletonText noOfLines={1} width="50%" height="2rem" />
      <VStack spacing="8" mt={10}>
        <Skeleton borderRadius="1.25rem" height="2rem" width="50%" alignSelf="flex-start" />
        <Skeleton borderRadius="1.25rem" height="5rem" width="50%" alignSelf="flex-end" />
        <Skeleton borderRadius="1.25rem" height="2rem" width="15%" alignSelf="flex-start" />
        <Skeleton borderRadius="1.25rem" height="5rem" width="50%" alignSelf="flex-end" />
        <Skeleton borderRadius="1.25rem" height="2rem" width="50%" alignSelf="flex-start" />
        <Skeleton borderRadius="1.25rem" height="2rem" width="15%" alignSelf="flex-end" />
      </VStack>
    </Box>
  );
};

export default ModalSkeleton;
