import React, {useEffect, useState} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  IconButton,
  Textarea,
  Box,
  useToast,
  VStack,
  Divider,
  Flex,
  Avatar,
  Switch,
  FormControl,
  FormLabel,
  Icon,
  Spinner,
} from '@chakra-ui/react';

import {FaStar, FaRegStar, FaHeart} from 'react-icons/fa6';
import uuid from 'react-uuid';
import moment from 'moment';

import {useSetShiftReview} from '../../../shared/gql/shifts/mutations';
import {SetShiftReviewPayload} from '../Shift.types';
import PdfModal from './Overlay/PdfModal';
import AddtoDNRListModal from './Overlay/AddtoDNRListModal';
import {allCompletedShifts} from '../../../shared/types';
import {useGetAllCompletedShiftsLegacyQuery} from '../../../shared/gql/shifts/queries';
import {useSessionUser} from '../CreateShift/appSelectors';

type NDProRatingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  modalData: any;
  refetchShiftOpenings: () => void;
  handleLegacyFavorite: (nurseId: number, relation: string, name: string, refetch?: () => void) => void;
  completedShiftData?: allCompletedShifts | undefined;
  ids?: number[];
  setActionDone?: (value: boolean) => void;
};

type PdfModalData = {
  isOpen: boolean;
  data: {
    src: string;
    title?: string;
  } | null;
};

// TO-DO: Change PX to REM
const RateNDProModal: React.FC<NDProRatingModalProps> = ({
  isOpen,
  onClose,
  modalData,
  refetchShiftOpenings,
  handleLegacyFavorite,
  completedShiftData: completedShiftDataFromProps,
  ids,
  setActionDone,
}) => {
  const [ratings, setRatings] = useState<number>(0);
  const [hoveredStars, setHoveredStars] = useState<number>(0);
  const [comments, setComments] = useState<string>('');
  const [pdfData, setPdfData] = useState<PdfModalData>({isOpen: false, data: null});
  const [relation, setRelation] = useState<string>('');
  const [ratingSubmitted, setRatingSubmitted] = useState<boolean>(false);
  const sessionUser = useSessionUser();

  const {data: completedShiftDataFromAPI, refetch} = useGetAllCompletedShiftsLegacyQuery({
    variables: {ids},
    skip: !ids,
  });

  const completedShiftData = completedShiftDataFromAPI || completedShiftDataFromProps;
  const applicantDataFromAPI = completedShiftDataFromAPI?.allCompletedShifts?.[0];

  const toast = useToast();
  const [DNRlistModaldata, setDNRlistModalData] = useState<{
    isOpen: boolean;
    nurseId: number | null;
    relation?: string | null;
  }>({nurseId: null, isOpen: false});

  const {
    unitName,
    position,
    date,
    startTime,
    endTime,
    shiftBreak,
    applicantData: applicantDataFromProps,
    timeZone,
    isCompleted,
    id,
  } = modalData;

  const applicantData = applicantDataFromAPI || applicantDataFromProps;

  const nurseData = applicantData?.applicantList?.find(
    (nurse: {nurseId: number}) => nurse.nurseId === applicantData?.assigned_nurse_id
  );
  const [actionPerformed, setActionPerformed] = useState(false);

  useEffect(() => {
    if (completedShiftData?.allCompletedShifts) {
      const shiftData = completedShiftData?.allCompletedShifts?.find((data: {applicantList: any[]}) =>
        data?.applicantList?.find(applicant => applicant?.applicantId === id || applicant?.id?.includes(id))
          ? data
          : null
      );

      const nurseRelation = shiftData?.applicantList?.find(
        (nurse: {nurseId: number}) => nurse.nurseId === applicantData?.assigned_nurse_id
      )?.relation;

      setRelation(nurseRelation || '');
    }
  }, [completedShiftData, id, applicantData?.assigned_nurse_id]);

  useEffect(() => {
    if (isCompleted) {
      setComments(applicantData?.review?.comment);
      setRatings(applicantData?.review?.overallRating);
    }
  }, [isCompleted, applicantData?.review]);

  const [setShiftReview, {loading}] = useSetShiftReview();

  const submitShiftReviews = async () => {
    const shiftId = Number(applicantData?.id);
    const overallRating = ratings;
    const customerService = 0;
    const nurseSkills = 0;
    const comment = comments || '';

    const setShiftReviewPayload: SetShiftReviewPayload = {
      shiftId,
      review: {
        overallRating,
        customerService,
        nurseSkills,
        comment,
      },
      relation: '',
      reason: '',
    };

    try {
      await setShiftReview({
        variables: setShiftReviewPayload,
      })
        .then(() => {
          onClose();
          setRatings(0);
          setComments('');
          toast({
            title: 'Success',
            description: 'NDPro rating successful',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });

          setTimeout(() => {
            if (setActionDone) setActionDone(true);
            refetchShiftOpenings();
          }, 3000);
        })
        .catch(() => {
          toast({
            title: 'Error',
            description: 'Error rating the NDPro',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
      setRatingSubmitted(true);
    } catch (error) {
      console.error('error while submitting review', error);
    }
  };

  const handleMouseEnter = (starIndex: number) => {
    setHoveredStars(starIndex);
  };

  const handleMouseLeave = () => {
    setHoveredStars(0);
  };

  const handleClose = () => {
    onClose();
    setRatings(0);
    setComments('');

    if (actionPerformed) {
      refetchShiftOpenings();
      if (setActionDone) setActionDone(true);
      setActionPerformed(false);
    }
  };

  const ratingTexts = ['Poor', 'Fair', 'Average', 'Great', 'Excellent'];

  const formatShiftTime = (time: Date, formattingTimeZone?: string) =>
    formattingTimeZone ? moment.utc(time).tz(formattingTimeZone).format('h:mm A') : moment.utc(time).format('h:mm A');

  const checkInTime = applicantData ? formatShiftTime(new Date(applicantData.checkInTime), timeZone) : '';
  const checkOutTime = applicantData ? formatShiftTime(new Date(applicantData.checkOutTime), timeZone) : '';

  const formatBreak = (inputString: string) => {
    const numbers = inputString?.match(/\d+/g)?.map(Number);
    const strings = inputString?.match(/[a-zA-Z]+/g);

    if (numbers?.[0]) return `${numbers?.[0]} ${strings?.[0]} break`;

    return 'No break';
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} size="md">
        <ModalOverlay />
        <ModalContent margin="auto">
          <ModalHeader bg="#E8EAF1" borderTopRadius="0.5rem">
            <Text fontSize="16px">
              {unitName} / {position}
            </Text>
            <Text fontSize="13px" fontWeight="400">
              {date}
            </Text>
            <Text fontSize="13px" fontWeight="400">
              {startTime} - {endTime} ({formatBreak(shiftBreak)})
            </Text>
          </ModalHeader>
          <ModalCloseButton />

          {!nurseData ? (
            <Flex justifyContent="center" alignItems="center" height="40vh">
              <Spinner />
            </Flex>
          ) : (
            <ModalBody padding="1.5rem">
              <Flex width="100%" gap="0.7rem" flexDir="column" height="100%">
                <Flex className="modalbox" flexDir="column" height="100%" justifyContent="space-around" gap="0.7rem">
                  <Flex flexDir="row" justifyContent="center">
                    <Flex flexDir="column" justifyContent="center" alignItems="center" gap="0.3125rem">
                      <Avatar
                        size="md"
                        bg="#313971"
                        color="white"
                        bgImage={nurseData?.image}
                        name={!nurseData?.image ? nurseData?.name : ''}
                      />
                      {relation === 'facility-favorite' && (
                        <Box position="absolute" right="196px" color="red.500" top="150px">
                          <Icon as={FaHeart} boxSize="16px" color="#FC8181" />
                        </Box>
                      )}
                      <Box textAlign="center">
                        <Text mb={1} fontWeight="600">
                          {nurseData?.name}
                        </Text>

                        <Text fontSize="12px" mb={2}>
                          {position}
                        </Text>
                      </Box>
                    </Flex>
                  </Flex>

                  <Flex flexDir="column" alignItems="center" justifyContent="center" gap="0.3125rem">
                    <Flex gap="0.3125rem">
                      <Text fontSize="0.75rem" color="#0000007A">
                        In at {moment(applicantData?.checkInTime).format('MM/DD')} at {checkInTime} - Verified by{' '}
                      </Text>
                      <Text
                        fontSize="0.75rem"
                        color="#405FF2"
                        textDecoration="underline"
                        cursor="pointer"
                        onClick={
                          sessionUser?.role === 'Viewer'
                            ? () => {}
                            : () =>
                                setPdfData({
                                  isOpen: true,
                                  data: {src: applicantData?.checkInSupervisorSignature?.[0]?.src},
                                })
                        }
                      >
                        {applicantData?.checkInSupervisorName}
                      </Text>
                    </Flex>

                    <Flex gap="0.3125rem">
                      <Text fontSize="0.75rem" color="#0000007A">
                        In at {moment(applicantData?.checkOutTime).format('MM/DD')} at {checkOutTime} - Verified by{' '}
                      </Text>
                      <Text
                        fontSize="0.75rem"
                        color="#405FF2"
                        textDecoration="underline"
                        cursor="pointer"
                        onClick={
                          sessionUser?.role === 'Viewer'
                            ? () => {}
                            : () =>
                                setPdfData({
                                  isOpen: true,
                                  data: {src: applicantData?.checkOutSupervisorSignature?.[0]?.src},
                                })
                        }
                      >
                        {applicantData?.checkOutSupervisorName}
                      </Text>
                    </Flex>
                  </Flex>
                  <Box width="100%">
                    <Flex direction="row" justifyContent="center" alignItems="center">
                      {[...Array(5)].map((_, starIndex) => (
                        <VStack key={uuid()} spacing={1} alignItems="center">
                          <IconButton
                            onMouseEnter={() => handleMouseEnter(starIndex + 1)}
                            onMouseLeave={() => handleMouseLeave()}
                            icon={
                              starIndex < (ratings || 0) || starIndex < (hoveredStars || 0) ? <FaStar /> : <FaRegStar />
                            }
                            onClick={() => setRatings(starIndex + 1)}
                            aria-label={`Rate ${starIndex + 1}`}
                            variant="ghost"
                            margin="5px"
                            isDisabled={sessionUser?.role === 'Viewer'}
                          />
                          <Text fontSize="xs">{ratingTexts[starIndex]}</Text>
                        </VStack>
                      ))}
                    </Flex>
                  </Box>
                </Flex>

                <Flex className="flex" flexDir="column" height="50%" justifyContent="flex-end">
                  <Box width="100%">
                    <Textarea
                      placeholder="Please provide some feedback"
                      value={comments || ''}
                      onChange={e => setComments(e.target.value)}
                      width="100%"
                      isDisabled={sessionUser?.role === 'Viewer'}
                    />
                  </Box>
                </Flex>
                {ratings === 5 && !ratingSubmitted && relation !== 'facility-favorite' && (
                  <Flex>
                    <FormControl>
                      <FormLabel>
                        <Flex className="toggle-container" alignItems="center">
                          <Flex>
                            <Switch
                              id="favorite-toggle"
                              onChange={() => {
                                if (nurseData) {
                                  handleLegacyFavorite(nurseData?.nurseId, relation, nurseData?.name, refetch);
                                  setActionPerformed(true);
                                }
                              }}
                              isChecked={relation === 'facility-favorite'}
                              marginRight="10px"
                            />
                          </Flex>
                          <Flex>
                            <Text>Add candidate to Favorites</Text>
                          </Flex>
                        </Flex>
                      </FormLabel>
                    </FormControl>
                  </Flex>
                )}

                {ratings === 1 && !ratingSubmitted && relation !== 'do-not-return' && (
                  <Flex>
                    <FormControl>
                      <FormLabel>
                        <Flex className="toggle-container" alignItems="center">
                          <Flex>
                            <Switch
                              id="DNR-toggle"
                              onChange={() => {
                                if (nurseData) {
                                  setDNRlistModalData({
                                    isOpen: true,
                                    nurseId: Number(nurseData?.nurseId),
                                    relation,
                                  });
                                }
                              }}
                              isChecked={relation === 'do-not-return'}
                              marginRight="10px"
                            />
                          </Flex>
                          <Flex>
                            <Text>Add candidate to &ldquo;Do not return&rdquo; list</Text>
                          </Flex>
                        </Flex>
                      </FormLabel>
                    </FormControl>
                  </Flex>
                )}
              </Flex>
            </ModalBody>
          )}
          <Divider />
          {!isCompleted && (
            <ModalFooter>
              <Button
                variant="primary"
                onClick={submitShiftReviews}
                isLoading={loading}
                isDisabled={sessionUser?.role === 'Viewer'}
              >
                Submit
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>

      {pdfData?.isOpen && <PdfModal handleIsModalOpen={setPdfData} data={pdfData?.data} />}

      {DNRlistModaldata?.isOpen && (
        <AddtoDNRListModal
          handleIsModalOpen={setDNRlistModalData}
          nurseId={DNRlistModaldata?.nurseId}
          relation={relation}
          refetchShiftOpenings={() => {
            refetch();
            setActionPerformed(true);
          }}
        />
      )}
    </>
  );
};

export default RateNDProModal;
