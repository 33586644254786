import {Flex, Skeleton} from '@chakra-ui/react';
import React from 'react';

const PreviewCardSkeleton = () => (
  <Flex
    flexDirection="column"
    bg="rgba(248, 248, 248, 1)"
    borderRadius="0.5rem"
    sx={{
      boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1)',
    }}
    border="1px solid rgba(241, 241, 241, 1)"
    padding="0.5rem 0.875rem"
    gap=".5rem"
    w="30%"
    marginBottom=".5rem"
  >
    <Flex justifyContent="space-between">
      <Skeleton fadeDuration={0.6} startColor="#EDEDED" endColor="white" height="20px" width="70%" />
      <Skeleton fadeDuration={0.6} startColor="#EDEDED" endColor="white" height="16px" width="30%" />
    </Flex>

    <Flex alignItems="flex-start" wrap="wrap">
      <Skeleton
        fadeDuration={0.6}
        startColor="#EDEDED"
        endColor="white"
        height="2rem"
        width="2rem"
        borderRadius="50%"
      />
      <Skeleton
        fadeDuration={0.6}
        startColor="#EDEDED"
        endColor="white"
        height="20px"
        width="80%"
        marginLeft="0.5rem"
      />
    </Flex>
    <Flex>
      <Skeleton fadeDuration={0.6} startColor="#EDEDED" endColor="white" height="16px" width="100%" />
    </Flex>
  </Flex>
);

export default PreviewCardSkeleton;
