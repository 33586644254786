import React, { useEffect } from 'react';
import './Login.scss';
import { useLocation } from 'react-router-dom';
import LoginForm from './LoginForm/LoginForm';
import SideBarAnnouncement from '../../shared/components/SideBarAnnouncement/SideBarAnnouncement';

const Login = () => {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const legacyFacilityId = params.get('fid');
    const adminUserId = params.get('uid');
    if (legacyFacilityId) {
      localStorage.setItem('legacyFidParam', legacyFacilityId);
    }
    if (adminUserId) {
      localStorage.setItem('legacyNDAdminUserId', adminUserId);
    }
  }, [location]);

   return (<div className="login">
      <SideBarAnnouncement />
      <div className="login__form-section">
        <LoginForm />
      </div>
    </div>
  )};

export default Login;
