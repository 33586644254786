/* eslint-disable @typescript-eslint/naming-convention */
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {useFlags} from 'launchdarkly-react-client-sdk';
//  Chakra
import {
  Text,
  Flex,
  Box,
  useToast,
  AlertIcon,
  AlertDescription,
  Alert,
  FormLabel,
  Switch,
  Tooltip,
  Icon,
} from '@chakra-ui/react';
import {LiaInfoCircleSolid} from 'react-icons/lia';
// Form
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import uuid from 'react-uuid';
import moment from 'moment';
import {useLocation} from 'react-router-dom';
import schema from './schema';
//  Components
import NDReactSelect from '../../../../shared/components/NDReactSelect/NDReactSelect';
import UnitSelector from '../../../../shared/components/UnitSelector/UnitSelector';
import PositionSelector from '../../../../shared/components/PositionSelector/PositionSelector';
import RateSelector from '../../../../shared/components/RateSelector/RateSelector';
import BonusSelector from './ShiftBonusSelector/ShiftBonusSelector';
import TimeSelector from '../../../../shared/components/TimeSelector/TimeSelector';
import BreakSelector from '../../../../shared/components/BreakSelector/BreakSelector';
import ShiftOpeningSelector from '../ShiftOpeningSelector/ShiftOpeningSelector';
import QualificationsSelector from '../../../../shared/components/QualificationsSelector/QualificationsSelector';
import DescriptionInput from '../../../../shared/components/DescriptionInput/DescriptionInput';
import DateSelector from '../../../../shared/components/DateSelector/DateSelector';
import Footer from './Footer/Footer';
//  Hooks
import useGetShiftTemplateByFacilityId, {
  useGetFacilityUnitPositionRate,
} from '../../../../shared/gql/shiftTemplates/queries';
import {useCreateShift} from '../../../../shared/gql/shifts/mutations';
import useAppDispatch from '../../../../shared/hooks/useAppDispatch';
import {setShiftTemplate} from '../../../../shared/slices/shiftTemplateSlice';
import {addShift} from '../../../../shared/slices/shiftsSlice';

//  Helper functions
import {
  generateSavedShiftOpitons,
  generateUnitOptions,
  getDefaultValues,
  handleDaySelection,
  getDays,
  getClonedShiftData,
} from './helperFunctions';
import {
  getSelectedUnitPositions,
  getSelectedPositionQualifications,
  checkRateOutOfRange,
  getSelectedPositionBaseRate,
  handleBonusChange,
  handleHasBonus,
  handleBreakSelection,
  checkOpeningButNoDay,
  getQualificationsDefaultValues,
  getGroupedQualificationOptions,
  getMissingIds,
  getCurrentQualifications,
  getCurrentBreakValues,
  getFirstPositionSelectedDescriptionAndInstructions,
} from '../../../ShiftTemplates/helperFunctions';
import {
  useFacilityId,
  useShiftTemplates,
  useTimeZone,
  useShiftTemplate,
  useFacilityUnits,
  useOtherQualifications,
  useShifts,
} from '../appSelectors';
import {formatToDollarAmount} from '../../../../shared/utils/helperFunctions';
import {FormValues, SingleValue, Facility, ShiftCardData} from './CreateShiftForm.types';
import {Position} from '../../../../shared/types/position';
import {Template} from '../../../../shared/gql/shiftTemplates/types';
import {PositionRate} from '../../../ShiftTemplates/ShiftTemplates.types';
import {Shift} from '../../../../shared/gql/shifts/types';
import ShiftCard from '../ShiftCard/ShiftCard';
import {trackCreateShiftEvent} from '../../../../shared/mixpanel/events';

const CreateShiftForm = () => {
  const {showRates, allowMultiPositions} = useFlags();
  const facilityId = useFacilityId();
  const timeZone = useTimeZone();
  const location = useLocation();
  const {search} = location;

  const [hasBonus, setHasBonus] = React.useState(false);
  const [selectedTemplateDaysAsDates, setSelectedTemplateDaysAsDates] = React.useState<string[]>([]);
  const [shiftCard, setShiftCard] = React.useState<ShiftCardData[]>([]);
  const [isMulti, setIsMulti] = React.useState(false);
  const [datePickerIndex, setDatePickerIndex] = useState<number>(0);

  const {loading} = useGetShiftTemplateByFacilityId({variables: {facilityId}});
  useGetFacilityUnitPositionRate({variables: {facilityId}});
  const dispatch = useAppDispatch();
  const shiftTemplates = useShiftTemplates();
  const shiftTemplate = useShiftTemplate();
  const facilityUnits = useFacilityUnits();
  const otherQualifications = useOtherQualifications();
  const shifts = useShifts();
  const useClonedShiftData = () => {
    if (search) {
      return getClonedShiftData(search, facilityUnits ?? [], otherQualifications ?? [], timeZone);
    }
    return null;
  };
  const clonedShiftData = useClonedShiftData();

  const positions: Position[] | [] = useMemo(
    () =>
      shiftTemplate
        ? shiftTemplate.content.positions.map((position: Position) => ({
            name: position.name,
            rate: position.minBaseRate ? Math.max(position.minBaseRate, position.rate) : position.rate,
            adjustmentRate: position.adjustmentRate,
            id: position.id,
          }))
        : [],
    [shiftTemplate]
  );

  const useDefaultValues = () => {
    if (!clonedShiftData) {
      return getDefaultValues(shiftTemplate, positions);
    }
    return {
      description: clonedShiftData?.description || '',
      clockInInstruction: clonedShiftData?.clockInInstruction || '',
      clockOutInstruction: clonedShiftData?.clockOutInstruction || '',
    };
  };
  const defaultValues = useDefaultValues();

  const {
    register,
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    reset,
    trigger,
    setFocus,
    setError,

    formState: {errors, dirtyFields, isSubmitting},
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onTouched',
  });

  // Shift Template functions
  const shiftTemplateOptions = generateSavedShiftOpitons(shiftTemplates);

  const handleShiftTemplateChange = (e: SingleValue) => {
    const currentTemplate = shiftTemplates?.find((template: Template) => template.id === e?.value);
    dispatch(setShiftTemplate(currentTemplate));
    setValue('templateName', e);
  };
  // Unit functions
  const handleUnitSelection = (unit: SingleValue) => {
    setValue('unit', unit, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
    setValue('position', []);
    setValue('qualifications', [], {shouldValidate: true, shouldDirty: true, shouldTouch: true});
  };
  const unitOptions = generateUnitOptions(facilityUnits);

  const unitName = watch('unit')?.label;
  // Position functions
  const selectedUnitPositions = getSelectedUnitPositions(facilityUnits, unitName);

  const selectedPositionQualifications = getSelectedPositionQualifications({
    selectedUnitPositions,
    getValues,
  });

  const selectedPositionBaseRate: PositionRate[] | undefined =
    getSelectedPositionBaseRate(selectedPositionQualifications);

  const useIsRateOutsideOfRange = () => {
    if (!selectedPositionBaseRate) {
      return false;
    }
    return checkRateOutOfRange(getValues('position'), selectedPositionBaseRate);
  };
  const isRateOutsideOfRange = useIsRateOutsideOfRange();
  // Break functions
  const incomingBreakValue = shiftTemplate?.content.break;
  const getBreakDefaultValues = () => {
    if (incomingBreakValue) {
      return {label: incomingBreakValue, value: incomingBreakValue};
    }
    return {label: '0 mins', value: '0mins'};
  };

  const breakDefaultValues = getBreakDefaultValues();

  const currentBreakValues = getCurrentBreakValues(watch, breakDefaultValues);
  // Day functions
  const openingButNoDay = checkOpeningButNoDay(watch, getValues);
  // Qualifications functions
  const qualificationsDefaultValues = getQualificationsDefaultValues(shiftTemplate);
  const useGroupedQualificationOptions = () => {
    if (selectedPositionQualifications) {
      return getGroupedQualificationOptions(selectedPositionQualifications, otherQualifications);
    }
    return [];
  };

  const groupedQualificationOptions = useGroupedQualificationOptions();
  const currentQualifications = getCurrentQualifications(watch, qualificationsDefaultValues);
  const missingIds = getMissingIds(watch, groupedQualificationOptions);
  // Description functions
  const firstPositionSelectedDescriptionAndInstructions =
    getFirstPositionSelectedDescriptionAndInstructions(selectedPositionQualifications);

  const getFullDateForSelectedDay = useCallback((selectedDayOfWeek: string[] | undefined) => {
    const daysOfWeek = getDays();
    const foundDay = daysOfWeek.find(day => selectedDayOfWeek?.includes(day.dayOfWeek));

    if (foundDay) {
      return foundDay.fullDate.split('T')[0];
    }

    return '';
  }, []);

  const extractRates = (currentPositions: Position[], facilities: Facility | undefined) => {
    const targetIds = currentPositions?.map(pos => pos.id);
    const selectedPositionAdjustmentRates = currentPositions?.map(pos => ({
      adjustmentRate: pos.adjustmentRate,
      id: pos.id,
    }));

    const matchedRates: {
      id: string;
      name: string;
      margin: number | undefined;
      subsidy: number;
      baseRate: number;
      adjustmentRate: number | undefined;
      weekendDiff: number;
    }[] = facilities?.positions
      ? facilities.positions
          .filter(facilityPosition => targetIds.includes(facilityPosition.id))
          .map(facilityPosition => ({
            id: facilityPosition.id,
            name: facilityPosition.name,
            margin: facilityPosition.rate.margin,
            subsidy: facilityPosition.rate.subsidy,
            baseRate: facilityPosition.rate.baseRate,
            adjustmentRate: selectedPositionAdjustmentRates?.find(
              (selectedPosition: {id: string; adjustmentRate: number}) => selectedPosition.id === facilityPosition.id
            )?.adjustmentRate,
            weekendDiff: 0,
          }))
      : [];

    return matchedRates;
  };

  const selectedUnit = getValues('unit')?.value;

  const foundUnit = facilityUnits?.find(unit => unit.id === selectedUnit);

  const selectedPositions = getValues('position');

  const extractedRates = extractRates(selectedPositions, foundUnit);

  const getQualificationIds = () => {
    if (!currentQualifications?.length) {
      return [];
    }
    return currentQualifications.map((qualification: SingleValue) => qualification.value);
  };

  const qualificationIds = getQualificationIds();

  const dayAndOpenings = watch('dayAndOpenings');
  const localStartTime = getValues('startTime');
  const localEndTime = getValues('endTime');

  const convertToUTC = (startDate: string, startTime: string, endTime: string, userTimeZone: string) => {
    const start = `${startDate} ${startTime}`;
    const end = `${startDate} ${endTime}`;

    const startDateInUsersTimeZone = moment.tz(start, 'YYYY-MM-DD HH:mm', userTimeZone);
    const endDateInUsersTimeZone = moment.tz(end, 'YYYY-MM-DD HH:mm', userTimeZone);

    if (endDateInUsersTimeZone < startDateInUsersTimeZone) {
      endDateInUsersTimeZone.add(1, 'days');
    }

    return {
      startTime: startDateInUsersTimeZone.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      endTime: endDateInUsersTimeZone.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
    };
  };

  const combinedDayAndOpenings = () => {
    if (!timeZone) {
      return [];
    }
    return dayAndOpenings?.map(({localStartDate, openings}) => {
      const {startTime, endTime} = convertToUTC(localStartDate, localStartTime, localEndTime, timeZone);
      return {
        startTime,
        endTime,
        openings,
      };
    });
  };

  const breakValue = getValues('break');
  const getBonuseFinalValue = () => {
    const bonusValue = getValues('bonus');
    const shiftTemplateAndBonusNotDirty = shiftTemplate && !dirtyFields.bonus;

    if (shiftTemplateAndBonusNotDirty) {
      return Number(bonusValue);
    }
    return Math.round(Number(bonusValue) * 100);
  };

  const descriptionValues = (fieldName: 'description' | 'clockInInstruction' | 'clockOutInstruction') => {
    if (
      !shiftTemplate &&
      !getValues(fieldName)?.length &&
      firstPositionSelectedDescriptionAndInstructions?.[fieldName]
    ) {
      return firstPositionSelectedDescriptionAndInstructions[fieldName];
    }
    return `${watch(fieldName)}` || '';
  };

  const formPayload: Shift = {
    unitId: getValues('unit')?.value,
    shiftDates: combinedDayAndOpenings(),
    break: breakValue || '0mins',
    positionRate: extractedRates,
    bonus: getBonuseFinalValue(),
    description: descriptionValues('description'),
    clockInInstruction: descriptionValues('clockInInstruction'),
    clockOutInstruction: descriptionValues('clockOutInstruction'),
    qualificationIds,
  };

  const getValuesOfDay = getValues('day');

  const selectedDate = getFullDateForSelectedDay(getValuesOfDay);

  const shiftTemplateDay = shiftTemplate?.content.openings[0]?.dayOfWeek;

  const postShiftPayload = {
    createShift: {
      facilityId,
      shifts: [...shifts, formPayload],
    },
  };

  const [createShiftFn, {error}] = useCreateShift({variables: postShiftPayload});

  const resetForm = () => {
    dispatch(setShiftTemplate(null));
    reset({
      unit: null as any,
      qualifications: [],
      description: '',
      clockInInstruction: '',
      clockOutInstruction: '',
      position: [],
      startTime: undefined,
      endTime: undefined,
      bonus: 0,
      templateName: '',
      numOfOpenings: 0,
      dayAndOpenings: [],
      break: '',
    });
    setDatePickerIndex(0);
    setHasBonus(false);
    setSelectedTemplateDaysAsDates([]);
    setIsMulti(false);
  };

  const isFormValid = async () => {
    const formIsDirty = Object.keys(dirtyFields).length !== 0;
    return shifts.length === 0 || (shifts.length > 0 && formIsDirty) ? trigger() : true;
  };

  const getShiftPayload = () => {
    const validShifts = shifts.filter((shift: any) => shift.unitId.length > 0);
    return validShifts.length === 0 ? [formPayload] : validShifts;
  };

  const toast = useToast();

  const onSubmit = async () => {
    const isInvalidShiftTime = (combinedDayAndOpeningsFn: {startTime: string}[]) => {
      const currentTime = moment.utc();

      return combinedDayAndOpeningsFn.some(({startTime}) => {
        const startMoment = moment(startTime);

        // Check if start or end time is at or before the current time
        return startMoment.isSameOrBefore(currentTime);
      });
    };

    const invalidShiftTime = isInvalidShiftTime(combinedDayAndOpenings());

    if (invalidShiftTime) {
      setError('startTime', {
        type: 'manual',
        message: 'Start time must be past current time.',
      });
      return;
    }
    if (await isFormValid()) {
      const payload = {
        createShift: {
          facilityId,
          shifts: getShiftPayload(),
        },
      };

      const response = await createShiftFn({variables: payload});
      const successfulShiftCreation = response?.data?.createShift?.successShiftCreations;
      const failedShiftCreation = response?.data?.createShift?.failShiftCreations;
      const successfulPost = successfulShiftCreation !== undefined && successfulShiftCreation.length > 0;
      const unsuccessfulPost =
        error || response.data === undefined || (failedShiftCreation !== undefined && failedShiftCreation.length > 0);

      if (successfulPost) {
        const updatedShiftCard = [
          ...shiftCard,
          {
            shiftId: successfulShiftCreation?.[0]?.id,
            shiftCardUnit: getValues('unit'),
            shiftCardOpenings: getValues('dayAndOpenings'),
            shiftCardStartTime: getValues('startTime'),
            shiftCardEndTime: getValues('endTime'),
            shiftCardPosition: getValues('position'),
          },
        ];
        setShiftCard(updatedShiftCard);

        try {
          if (response?.data) trackCreateShiftEvent(response.data, shiftTemplate?.id);
          dispatch(addShift(formPayload));
          dispatch(setShiftTemplate(null));
          resetForm();

          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });

          toast({
            title: 'Success',
            description: 'Shift created successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        } catch (onError) {
          console.log('error from onSubmit', onError);
        }
      } else if (unsuccessfulPost) {
        toast({
          title: 'Error',
          description: 'There was an error posting your shifts.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        // Remove once we create a global error handler
        console.log('error: ', error);
      }
    }
  };

  const fillClonedShift = () => {
    if (clonedShiftData) {
      const {
        unit,
        openings,
        description,
        clockInInstruction,
        clockOutInstruction,
        qualifications,
        positions: unitPositions,
        startTime,
        endTime,
        break: breakTime,
        bonusAmount,
        date,
      } = clonedShiftData.content;
      setValue('startTime', startTime);
      setValue('endTime', endTime);

      const clonedPositions =
        unitPositions.map((position: Position) => ({
          name: position.name,
          rate: position.rate,
          adjustmentRate: position.adjustmentRate,
          id: position.id,
        })) ?? [];
      setValue('position', clonedPositions);
      if (openings[0]) {
        const {total: numOfOpenings} = openings[0];
        setValue('numOfOpenings', numOfOpenings);
        // Get the full date corresponding to the day of week for the shift.
        setValue('day', [date]);
        setValue('dayAndOpenings', [{localStartDate: date, openings: numOfOpenings}]);
      }
      setValue('date', [date]);
      setValue('unit', {label: unit.name, value: unit.id});
      setValue('description', description);
      setValue('clockInInstruction', clockInInstruction);
      setValue('clockOutInstruction', clockOutInstruction);
      setValue('qualifications', qualifications);
      setValue('break', breakTime);

      if (allowMultiPositions && getValues('position').length > 1) {
        setIsMulti(true);
      }
      if (bonusAmount > 0) {
        setHasBonus(true);
        setValue('bonus', bonusAmount);
      } else {
        setValue('bonus', 0);
      }
    }
  };
  const timeSelectorBoxRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (clonedShiftData) {
      fillClonedShift();
      return;
    }
    if (!shiftTemplate) {
      setIsMulti(false);
      return;
    }

    if (timeSelectorBoxRef.current) {
      timeSelectorBoxRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }

    const {
      unit,
      bonusAmount,
      openings,
      description,
      clockInInstruction,
      clockOutInstruction,
      qualifications,
      break: breakTime,
    } = shiftTemplate.content;

    if (openings[0]) {
      const {total: numOfOpenings} = openings[0];
      setValue('numOfOpenings', numOfOpenings);
      // Get the full date corresponding to the day of week for the shift.
      const fullDateForSelectedDay = getFullDateForSelectedDay([shiftTemplate?.content.openings[0].dayOfWeek]);

      // Check if a valid date is returned for the selected day of week.
      if (fullDateForSelectedDay) {
        // Set the full date value in the component's state.
        setValue('day', [getFullDateForSelectedDay([shiftTemplate?.content.openings[0].dayOfWeek])]);

        setValue('dayAndOpenings', [{localStartDate: fullDateForSelectedDay, openings: numOfOpenings}]);
        setValue('date', [fullDateForSelectedDay]);
      }
    }

    setValue('unit', {label: unit.name, value: unit.id});
    setValue('position', positions);
    setValue('description', description);
    setValue('clockInInstruction', clockInInstruction);
    setValue('clockOutInstruction', clockOutInstruction);
    setValue('qualifications', qualifications);
    setValue('break', breakTime);

    if (allowMultiPositions && getValues('position').length > 1) {
      setIsMulti(true);
    }

    if (bonusAmount > 0) {
      setHasBonus(true);
      setValue('bonus', bonusAmount);
    } else {
      setValue('bonus', 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftTemplate, setValue, positions, getFullDateForSelectedDay, facilityUnits, getValues]);

  const onError = (formErrors: any) => console.log('formErrors', formErrors);

  const startShiftTime = moment(watch('startTime'), 'HH:mm A');
  const endShiftTime = moment(watch('endTime'), 'HH:mm A');
  type BreakMap = {
    [key: string]: string;
  };
  const breakMap: BreakMap = {
    undefined: '00:00',
    '0mins': '00:00',
    '15mins': '00:15',
    '30mins': '00:30',
    '45mins': '00:45',
  };

  const breakDuration = moment.duration(breakMap[watch('break') ?? 'undefined'] ?? '01:00');

  const duration = moment.duration(endShiftTime.diff(startShiftTime));

  const totalShiftTime = duration.subtract(breakDuration);

  if (totalShiftTime.asMilliseconds() < 0) {
    totalShiftTime.add(24, 'hours');
  }

  const longShiftAlert = () => {
    if (totalShiftTime.minutes()) {
      return `${totalShiftTime.hours()} hours and ${totalShiftTime.minutes()} minutes`;
    }
    return `${totalShiftTime.hours()} hours`;
  };

  const showRatesDisplay = () => {
    if (showRates) {
      return 'inherit';
    }
    return 'none';
  };

  const showRatesMargin = () => {
    if (showRates) {
      return '2.5rem';
    }
    return '1.5rem';
  };

  const stickyHeaderProps = () => {
    if (shiftTemplate) {
      return {
        top: '5',
        zIndex: 'sticky',
      };
    }
    return {
      top: 'inherit',
      zIndex: 'inherit',
    };
  };

  const unitSelectorKey = () => {
    if (shiftTemplate) {
      return shiftTemplate.id;
    }
    return 'initial-key';
  };

  const selectedUnitPositionsDisplay = () => {
    if (selectedUnitPositions) {
      return 'inherit';
    }
    return 'none';
  };

  return (
    <form id="#create-shift" onSubmit={handleSubmit(onSubmit, onError)}>
      <Flex width="100%" justifyContent="space-between">
        <Flex flexDirection="column" w="45rem" gap="1rem">
          <Flex
            position={shiftTemplate ? 'sticky' : 'inherit'}
            top={stickyHeaderProps().top}
            zIndex={stickyHeaderProps().zIndex}
            backgroundColor="#E8EAF1"
            flexDirection="column"
            gap=".5rem"
            w={['inherit', '100%']}
            borderRadius="0.5rem"
            border="2px solid #C5CADE"
            boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.05)"
            padding="1.7rem 3rem 2rem 3rem"
          >
            <Text fontWeight="600">Shift Templates</Text>
            <Controller
              name="templateName"
              control={control}
              render={({field}) => (
                <NDReactSelect
                  {...register('templateName')}
                  {...field}
                  loading={loading}
                  isMulti={false}
                  placeholder="Select a shift template"
                  options={shiftTemplateOptions}
                  onChange={(e: SingleValue) => handleShiftTemplateChange(e)}
                  defaultValue={{label: 'Select a shift template', value: ''}}
                  closeMenuOnSelect
                  isClearable
                />
              )}
            />
          </Flex>
          <Flex w="100%" flexDirection="column" backgroundColor="white" borderRadius="0.5rem" padding="1.5rem 3rem">
            <Box marginBottom=".5rem">
              <UnitSelector
                key={unitSelectorKey()}
                register={register}
                control={control}
                shiftTemplate={shiftTemplate}
                isEditable
                handleUnitSelection={handleUnitSelection}
                errors={errors}
                unitOptions={unitOptions}
              />
            </Box>
            <Box marginBottom={showRatesMargin()}>
              <Flex justifyContent="space-between" alignItems="center">
                <Text fontWeight="600">Position</Text>
                {allowMultiPositions && (
                  <Flex justifyContent="space-around" alignItems="center" display={selectedUnitPositionsDisplay()}>
                    <FormLabel fontWeight="400" htmlFor="multi-position" mb="0">
                      Allow multiple positions
                    </FormLabel>
                    <Flex alignItems="center" gap=".5rem">
                      <Switch
                        id="multi-position"
                        onChange={() => {
                          setIsMulti(!isMulti);
                        }}
                        disabled={getValues('position')?.length > 1}
                        isChecked={isMulti}
                      />
                      <Tooltip label="Multiple position types can apply for this shift" placement="top">
                        <Flex>
                          <Icon as={LiaInfoCircleSolid} boxSize="1.2rem" />
                        </Flex>
                      </Tooltip>
                    </Flex>
                  </Flex>
                )}
              </Flex>
              <PositionSelector
                {...register('position')}
                selectedUnitPositions={selectedUnitPositions}
                getValues={getValues}
                errors={errors}
                watch={watch}
                setValue={setValue}
                isInCardData={undefined}
                isMulti={isMulti}
              />
              {errors.position && (
                <Text color="red.500" fontSize="sm">
                  Please select a position
                </Text>
              )}
            </Box>
            <Box display={showRatesDisplay()}>
              <RateSelector
                getValues={getValues}
                setValue={setValue}
                shiftTemplate={shiftTemplate}
                selectedPositionBaseRate={selectedPositionBaseRate}
                isEditable
              />
              {isRateOutsideOfRange && (
                <Text color="red.500" fontSize="sm">
                  Rate is out of range.
                </Text>
              )}
            </Box>
          </Flex>
          <Flex
            w="100%"
            flexDirection="column"
            backgroundColor="white"
            borderRadius="0.5rem"
            padding="1.5rem 3rem"
            display={showRatesDisplay()}
          >
            <BonusSelector
              shiftTemplate={shiftTemplate}
              control={control}
              hasBonus={hasBonus}
              handleHasBonus={handleHasBonus}
              register={register}
              formatToDollarAmount={formatToDollarAmount}
              watch={watch}
              handleBonusChange={handleBonusChange}
              setValue={setValue}
              setHasBonus={setHasBonus}
            />
          </Flex>
          <Flex w="100%" flexDirection="column" backgroundColor="white" borderRadius="0.5rem" padding="1.5rem 3rem">
            <Box marginBottom=".5rem">
              <DateSelector
                {...register('date')}
                setValue={setValue}
                getValues={getValues}
                handleDaySelection={handleDaySelection}
                errors={errors}
                shiftTemplateDay={shiftTemplateDay}
                watch={watch}
                selectedTemplateDaysAsDates={selectedTemplateDaysAsDates}
                setSelectedTemplateDaysAsDates={setSelectedTemplateDaysAsDates}
                setFocus={setFocus}
                shiftTemplate={shiftTemplate}
                register={register}
                datePickerIndex={datePickerIndex}
                setDatePickerIndex={setDatePickerIndex}
              />
              {errors.date && (
                <Text color="red.500" fontSize="sm">
                  Please select a date
                </Text>
              )}

              {openingButNoDay && (
                <Text color="red.500" fontSize="sm">
                  Please select a day
                </Text>
              )}
            </Box>

            <Box ref={timeSelectorBoxRef}>
              <ShiftOpeningSelector
                shiftTemplate={shiftTemplate}
                getValues={getValues}
                watch={watch}
                isEditable
                errors={errors}
                setValue={setValue}
                selectedDate={selectedDate}
                datePickerIndex={datePickerIndex}
                setDatePickerIndex={setDatePickerIndex}
              />
            </Box>
            <Flex justifyContent="space-between">
              <TimeSelector
                register={register}
                watch={watch}
                errors={errors}
                shiftTemplate={shiftTemplate}
                setValue={setValue}
                isEditable
              />
              <BreakSelector
                breakDefaultValues={breakDefaultValues}
                shiftTemplate={shiftTemplate}
                isEditable
                handleBreakSelection={handleBreakSelection}
                setValue={setValue}
                register={register}
                currentBreakValues={currentBreakValues}
              />
            </Flex>
            {totalShiftTime.asHours() > 13 && (
              <Box>
                <Alert
                  bg="#E8EAF1"
                  borderRadius="6px"
                  borderLeft="4px solid #38427E"
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="space-between"
                  marginBottom="1.5rem"
                >
                  <Flex>
                    <AlertIcon color="#38427E" marginRight="1rem" />
                    <Flex flexDirection="column">
                      <Flex flexDirection="column">
                        <AlertDescription>
                          This shift will be scheduled for a total duration of {longShiftAlert()}.
                        </AlertDescription>
                      </Flex>
                    </Flex>
                  </Flex>
                </Alert>
              </Box>
            )}
          </Flex>

          <Flex w="100%" flexDirection="column" backgroundColor="white" borderRadius="0.5rem" padding="1.5rem 3rem">
            <QualificationsSelector
              qualificationsDefaultValues={qualificationsDefaultValues}
              groupedQualificationOptions={groupedQualificationOptions}
              shiftTemplate={shiftTemplate}
              isEditable
              errors={errors}
              register={register}
              missingIds={missingIds}
              currentQualifications={currentQualifications}
              setValue={setValue}
            />
            <DescriptionInput
              shiftTemplate={shiftTemplate}
              isEditable
              placeholder="You can enter shift descripton here"
              register={register}
              title="Shift Description"
              subText="Provide specialty requirements, desired expertise, or instructions if any."
              name="description"
              defaultValue={defaultValues.description}
            />
          </Flex>
          <Flex w="100%" flexDirection="column" backgroundColor="white" borderRadius="0.5rem" padding="1.5rem 3rem">
            <Box marginBottom="1.5rem">
              <DescriptionInput
                shiftTemplate={shiftTemplate}
                isEditable
                placeholder="You can enter shift clock in instructions here"
                register={register}
                title="Clock In Instructions"
                subText="Provide clock in instructions if any."
                name="clockInInstruction"
                defaultValue={defaultValues.clockInInstruction}
              />
            </Box>
            <Box marginBottom="1.5rem">
              <DescriptionInput
                shiftTemplate={shiftTemplate}
                isEditable
                placeholder="You can enter shift clock 0ut instructions here"
                register={register}
                title="Clock Out Instructions"
                subText="Provide clock out instructions if any."
                name="clockOutInstruction"
                defaultValue={defaultValues.clockOutInstruction}
              />
            </Box>
          </Flex>
        </Flex>
        {shiftCard.length > 0 && (
          <Flex flexDirection="column" w="20rem">
            <Text fontWeight="500" color="#000" fontSize="1.5rem" h="3rem" w="100%" marginBottom="1rem">
              Posted Shifts
            </Text>
            <Flex w="100%" gap=".5rem" flexDirection="column">
              {shiftCard.length > 0 &&
                [...shiftCard].reverse().map((shift: ShiftCardData) => <ShiftCard shift={shift} key={uuid()} />)}
            </Flex>
          </Flex>
        )}
      </Flex>

      <Footer
        formIsDirty={!!dirtyFields}
        isSubmitting={isSubmitting}
        // isRateOutsideOfRange={isRateOutsideOfRange}
        openingButNoDay={openingButNoDay}
        resetForm={resetForm}
      />
    </form>
  );
};

export default CreateShiftForm;
