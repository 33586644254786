import React, {useState, FC, useEffect} from 'react';
import {ChevronDownIcon} from '@chakra-ui/icons';
import {
  Box,
  Checkbox,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Switch,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {Shift} from '../Shift.types';
import {checkShiftStatus} from '../CalendarPage/helpers/helpers';
import {trackStatusFilterChangeEvent} from '../../../shared/mixpanel/events/calender';

const defaultStatus = [
  {legend: 'Upcoming Unfilled', value: 'upcomingUnfilled', background: '#f1ddcc', border: ''},
  {legend: 'Actions Required', value: 'actionRequired', background: '#2970FF', border: ''},
  {legend: 'Filled Completely', value: 'filledCompletely', background: '#5AC68D', border: ''},
  {legend: 'Filled Partially', value: 'filledPartially', background: 'white', border: '3px solid #5AC68D'},
  {legend: 'Cancelled', value: 'cancelled', background: '#D9D9D980', border: ''},
  {legend: 'Past Unfilled', value: 'pastUnfilled', background: '#f1ddcc', border: ''},
];

interface IShiftStatusProps {
  filteredShiftCount?: string | number;
  totalShiftCount?: string | number;
  statusFilter: string[];
  setStatusFilter: any;
  shifts: Shift[];
  timeZone: string;
}

const ShiftStatusFilter: FC<IShiftStatusProps> = ({
  filteredShiftCount,
  totalShiftCount,
  statusFilter,
  setStatusFilter,
  shifts,
  timeZone,
}) => {
  const [isOpen, setIsOpen] = useState('');
  const handleClose = () => {
    setIsOpen('');
  };

  const handleSelectLegends = (value: string) => {
    setStatusFilter((prevSelected: string[]) =>
      prevSelected?.includes(value) ? prevSelected.filter(item => item !== value) : [...prevSelected, value]
    );
  };

  const handleSelectAllLegend = () => {
    setStatusFilter(defaultStatus.slice(0, 4).map(option => option.value));
  };

  const status = [...defaultStatus]?.map(item => ({...item, count: 0}));

  shifts?.forEach((day: {shifts: any[]}) => {
    day?.shifts?.forEach((innerShift: {unitId: any; positionDetail: any[]}) => {
      const shiftStatus = checkShiftStatus(innerShift, timeZone || 'UTC');

      const statusFromArray = status.find(item => item?.value === shiftStatus);

      if (statusFromArray) {
        statusFromArray.count += 1;
      }
    });
  });

  useEffect(() => {
    trackStatusFilterChangeEvent(statusFilter);
  }, [statusFilter]);

  return (
    <Menu
      closeOnSelect={false}
      autoSelect={false}
      isOpen={isOpen === 'statusMenu'}
      key="statusFilter"
      onClose={handleClose}
    >
      <Tooltip
        label={`Based on the selected filters, we are displaying ${filteredShiftCount} of ${totalShiftCount} shifts posted for selected day.`}
        placement="bottom"
        hasArrow
        maxWidth="15rem"
      >
        <MenuButton
          px={3}
          py={1}
          transition="all 0.2s"
          borderRadius="30px"
          borderWidth="0.0625rem"
          fontWeight="600"
          height="fit-content"
          fontSize="0.8rem"
          onClick={() => setIsOpen(prev => (prev === 'statusMenu' ? '' : 'statusMenu'))}
          borderColor={
            status
              .slice(0, 4)
              ?.map(item => item.value)
              .filter(value => !statusFilter?.includes(value))?.length
              ? '#FC8181'
              : '#00000029'
          }
          background={
            status
              .slice(0, 4)
              ?.map(item => item.value)
              .filter(value => !statusFilter?.includes(value))?.length
              ? '#FFF5F5'
              : 'white'
          }
        >
          Shift Status <ChevronDownIcon ml="2" />
        </MenuButton>
      </Tooltip>

      <MenuList>
        <MenuGroup>
          <MenuItem _hover={{background: 'transparent'}}>
            <Flex width="100%" alignItems="center" justifyContent="space-between">
              <Text fontWeight="600" fontSize="0.8rem">
                Shift Status
              </Text>
              {statusFilter?.length !== status.slice(0, 4)?.length && (
                <Text
                  fontWeight="600"
                  color="#3065CA"
                  textDecoration="underline"
                  cursor="pointer"
                  fontSize="0.8rem"
                  onClick={() => {
                    handleSelectAllLegend();
                  }}
                >
                  Reset
                </Text>
              )}
            </Flex>
          </MenuItem>
          {status.slice(0, 4)?.map(legend => (
            <MenuItem _hover={{background: 'transparent'}} key={legend.value}>
              <Flex width="100%" alignItems="center" justifyContent="space-between">
                <Flex alignItems="center" gap="0.5rem">
                  <Switch
                    alignItems="center"
                    gap="0.5rem"
                    onChange={e => {
                      e?.stopPropagation();
                      handleSelectLegends(legend.value);
                    }}
                    isChecked={statusFilter?.includes(legend.value)}
                  />
                  <Text fontSize="0.8rem">
                    {legend.legend} ({legend.count})
                  </Text>
                </Flex>
                <Box
                  background={legend.background}
                  border={legend.border}
                  height="14px"
                  width="14px"
                  borderRadius="50%"
                >
                  {' '}
                </Box>
              </Flex>
            </MenuItem>
          ))}

          <Divider />

          {status.slice(4).map(legend => (
            <MenuItem _hover={{background: 'transparent'}} key={legend.value}>
              <Flex width="100%" alignItems="center" justifyContent="space-between">
                <Flex alignItems="center" gap="0.5rem">
                  <Checkbox
                    alignItems="center"
                    gap="0.5rem"
                    onChange={e => {
                      e?.stopPropagation();
                      handleSelectLegends(legend.value);
                    }}
                    isChecked={statusFilter?.includes(legend.value)}
                  />
                  <Text fontSize="0.8rem">
                    {legend.legend} ({legend.count})
                  </Text>
                </Flex>

                <Box
                  background={legend.background}
                  height="14px"
                  width="14px"
                  borderRadius="50%"
                  border={legend.border}
                >
                  {' '}
                </Box>
              </Flex>
            </MenuItem>
          ))}
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};

export default ShiftStatusFilter;
