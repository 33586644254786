import React, {Dispatch, SetStateAction, useRef, useState} from 'react';
import {Flex, IconButton, Text} from '@chakra-ui/react';
import './DatePicker.scss';
import {ChevronLeftIcon, ChevronRightIcon} from '@chakra-ui/icons';
import DatePicker, {DateObject} from 'react-multi-date-picker';
import moment from 'moment';

type Props = {
  startDate: Date;
  endDate: Date;
  setDate: Dispatch<SetStateAction<Date>>;
  setEndDate: Dispatch<SetStateAction<Date>>;
};

const CalendarViewDatePicker = ({startDate, endDate, setDate, setEndDate}: Props) => {
  const [values, setValues] = useState([new DateObject({date: startDate}), new DateObject({date: endDate})]);

  const handlePrevWeek = () => {
    const prevSunday = new Date(startDate);
    prevSunday.setDate(startDate.getDate() - 7);
    const prevSaturday = new Date(prevSunday);
    prevSaturday.setDate(prevSunday.getDate() + 6);
    setDate(prevSunday);
    setEndDate(prevSaturday);
    setValues([new DateObject(prevSunday), new DateObject(prevSaturday)]);
  };

  const handleNextWeek = () => {
    const nextSunday = new Date(startDate);
    nextSunday.setDate(startDate.getDate() + 7);
    const nextSaturday = new Date(nextSunday);
    nextSaturday.setDate(nextSunday.getDate() + 6);
    setDate(nextSunday);
    setEndDate(nextSaturday);
    setValues([new DateObject(nextSunday), new DateObject(nextSaturday)]);
  };

  const datepickerRef = useRef<any>();

  const handleChange = (
    date: DateObject | DateObject[] | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options: {validatedValue: string | string[]; input: HTMLElement; isTyping: boolean}
  ) => {
    if (Array.isArray(date)) {
      const startDay = date[0].day;
      const startMonth = date[0].month.number;
      const startYear = date[0].year;
      const newStartDate = new Date(startYear, startMonth - 1, startDay);
      newStartDate.setHours(0, 0, 0, 0);

      if (date[1]) {
        const endDay = date[1].day;
        const endMonth = date[1].month.number;
        const endYear = date[1].year;
        const newEndDate = new Date(endYear, endMonth - 1, endDay);
        newEndDate.setHours(0, 0, 0, 0);

        setValues(date);
        setDate(newStartDate);
        setEndDate(newEndDate);
      }
    } else if (date) {
      const singleDate = date as DateObject;
      setValues([singleDate, singleDate]);
    } else {
      setValues([]);
    }
  };

  const isSameWeek = moment(startDate).isSame(moment(new Date()), 'week');
  const isNextWeek = moment(startDate).isSame(moment(new Date()).add(7, 'days'), 'week');
  const isPrevWeek = moment(startDate).isSame(moment(new Date()).subtract(7, 'days'), 'week');

  const renderText = () => {
    if (isSameWeek) return 'This Week';
    if (isNextWeek) return 'Next Week';
    if (isPrevWeek) return 'Last Week';

    return `${moment(startDate)?.format('MMM Do')} - ${moment(endDate).format('MMM Do')}`;
  };

  return (
    <Flex mb="0.3125rem" alignItems="center">
      <DatePicker
        value={values}
        onChange={handleChange}
        range
        render={<div> </div>}
        ref={datepickerRef}
        style={{zIndex: 1000, position: 'relative'}}
        arrow={false}
        offsetY={15}
        weekPicker
      />
      <Flex alignItems="center">
        <IconButton
          aria-label="Previous Day"
          colorScheme="black"
          variant="ghost"
          icon={<ChevronLeftIcon />}
          onClick={handlePrevWeek}
          paddingRight="15px"
          minWidth="9px"
        />
        <Text
          cursor="pointer"
          onClick={() => {
            if (datepickerRef?.current) {
              datepickerRef.current.openCalendar();
            }
          }}
        >
          {renderText()}
        </Text>
        <IconButton
          aria-label="Next Day"
          colorScheme="black"
          variant="ghost"
          icon={<ChevronRightIcon />}
          onClick={handleNextWeek}
        />
      </Flex>
    </Flex>
  );
};

export default CalendarViewDatePicker;
