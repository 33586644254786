import React from 'react';

import {Flex, Text, Button} from '@chakra-ui/react';
import {AddIcon} from '@chakra-ui/icons';

import {Props} from './Header.types';

const Header = ({title, subTitle, onClick, btnText, withBorder}: Props) => (
  <Flex
    justifyContent="space-between"
    alignItems={subTitle ? 'center' : ''}
    width="100%"
    borderBottom={withBorder ? '1px solid #E2E8F0' : ''}
    boxShadow={withBorder ? ['0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)', 'inherit'] : ''}
    marginTop={['inherit', '.5rem']}
  >
    <Flex
      justifyContent="space-between"
      marginBottom="1.5rem"
      flexDirection={subTitle ? 'column' : 'row'}
      alignItems={subTitle ? 'baseline' : 'center'}
      width={['16rem', '40.1875rem']}
    >
      <Text fontWeight="700" fontSize="1.5rem">
        {title}
      </Text>
      <Text>{subTitle}</Text>
    </Flex>
    {/* Mobile Button */}
    <Button
      as={AddIcon}
      display={['inherit', 'none']}
      variant="primary"
      onClick={onClick}
      type="button"
      width="2.5rem"
      height="2.5rem"
      padding="0.7rem"
    />
    {/* Desktop Button */}
    <Button
      rightIcon={<AddIcon boxSize=".7rem" />}
      variant="primary"
      onClick={onClick}
      type="button"
      w="fit-content"
      h="2.5rem"
      padding="0.7rem"
      display={['none', 'inherit']}
    >
      {btnText}
    </Button>
  </Flex>
);

export default Header;
