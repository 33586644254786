import * as yup from 'yup';

const schema = yup.object().shape({
  orientationMaterials: yup.object(),
  covidVaccineRequired: yup.boolean(),
  allowSingleDoseVaccine: yup.boolean(),
  covidMedicalExemption: yup.boolean(),
  covidReligiousExemption: yup.boolean(),
  surveyUsername: yup.string().required('Name is required.'),
  surveyUseremail: yup.string().required('Email is required.'),
  surveyUsertitle: yup.string().required('Title is required.'),
  covidStatus: yup.object().required('Covid Status is required.'),
});

export default schema;
