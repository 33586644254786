import React, {useEffect, useState} from 'react';
import {
  Button,
  Modal as ChakraModal,
  Flex,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import {MdBlockFlipped} from 'react-icons/md';
import NDReactSelect from '../../../../shared/components/NDReactSelect/NDReactSelect';
import {SingleValue} from '../../../ShiftTemplates/ShiftTemplates.types';
import {useRemoveRelationToFacility, useSetRelationToFacility} from '../../../../shared/gql/CalendarShifts/mutations';

type Props = {
  nurseId: number | null;
  relation?: string | null;
  handleIsModalOpen: (value: {isOpen: boolean; nurseId: number | null}) => void;
  refetchShiftOpenings: () => void;
  setActionDone?: any;
};

type ReasonData = {
  value: string | null;
  label: string | null;
};

const AddtoDNRListModal = ({handleIsModalOpen, nurseId, relation, refetchShiftOpenings, setActionDone}: Props) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [reason, setReason] = useState<ReasonData>({value: null, label: null});

  const [setRelationToFacility] = useSetRelationToFacility();
  const [removeRelationToFacility] = useRemoveRelationToFacility();
  const toast = useToast();

  const DNRReasonOptions = [
    {value: 'pending_investigation', label: 'Pending investigation'},
    {value: 'behavioral_issue', label: 'Behavioral issue'},
    {value: 'reliability_issue', label: 'Reliability issue'},
    {value: 'other', label: 'Other'},
  ];

  const handleClose = () => {
    onClose();
    handleIsModalOpen({isOpen: false, nurseId: null});
  };

  useEffect(() => {
    onOpen();
  }, [onOpen]);

  const displayToast = (description: string, toastStatus: 'loading' | 'error' | 'info' | 'warning' | 'success') => {
    toast({
      title: description,
      status: toastStatus,
      duration: 3000,
      isClosable: true,
      position: 'top',
    });
  };

  const handleAddRemoveFromDNR = async () => {
    if (setActionDone) setActionDone(true);
    if (relation === 'do-not-return') {
      try {
        const setRelationResponse = await removeRelationToFacility({
          variables: {
            nurseId,
            reason: '',
            relation: 'do-not-return',
          },
        });
        if (setRelationResponse.data) {
          displayToast('Applicant removed from DNR list successfully!', 'error');
          handleClose();
          refetchShiftOpenings();
        } else if (!setRelationResponse.data) {
          displayToast('Remove applicant to DNR list failed!', 'error');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      try {
        const setRelationResponse = await setRelationToFacility({
          variables: {
            nurseId,
            reason: reason?.value,
            relation: 'do-not-return',
          },
        });
        if (setRelationResponse.data) {
          displayToast('Applicant added to DNR list successfully!', 'error');
          handleClose();
          refetchShiftOpenings();
        } else if (!setRelationResponse.data) {
          displayToast('Add applicant to DNR list failed!', 'error');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  return (
    <ChakraModal isOpen={isOpen} onClose={handleClose} size={['s', 'sm']} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" alignItems="center" gap="0.3125rem">
          <MdBlockFlipped color="red" />
          <Text fontWeight={700}>{relation === 'do-not-return' ? 'Remove from DNR List' : 'Add to DNR List'}</Text>
        </ModalHeader>

        <ModalBody>
          <Flex flexDirection="column" gap="0.5rem">
            <Text color="#0000007A">
              This clinician will be {relation === 'do-not-return' ? 'reinstated to' : 'withdrawn from'} all the shifts
              at your facility and will {relation === 'do-not-return' ? 'regain' : 'lose'} access to all future shifts.
            </Text>

            {relation !== 'do-not-return' && (
              <>
                <Text>Do not return reason</Text>
                <NDReactSelect
                  isMulti={false}
                  placeholder="Select reason"
                  options={DNRReasonOptions}
                  value={reason}
                  onChange={(e: SingleValue) => setReason(e)}
                  closeMenuOnSelect
                  name="reason"
                />
              </>
            )}
          </Flex>
        </ModalBody>

        <ModalFooter display="flex" width="100%" gap="0.5rem">
          <Button variant="secondary" width="100%" onClick={handleClose}>
            Cancel
          </Button>

          <Button variant="danger" width="100%" onClick={handleAddRemoveFromDNR} style={{whiteSpace: 'normal'}}>
            {relation === 'do-not-return' ? 'Remove from DNR List' : 'Add to DNR List'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};

export default AddtoDNRListModal;
