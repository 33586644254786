import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Text,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import React from 'react';
import {FaRegUser, FaStar, FaHeart} from 'react-icons/fa';
import {FaEllipsis} from 'react-icons/fa6';
import {LuArchive} from 'react-icons/lu';
import {TbMessageCircle} from 'react-icons/tb';
import uuid from 'react-uuid';
import {FilteredApplicantInfo} from '../../Shift.types';
import {useSessionUser} from '../../CreateShift/appSelectors';
import {trackSelectNdproEvent} from '../../../../shared/mixpanel/events';

const commonButtonProps = {
  size: 'sm',
  variant: 'outline',
};

const tableCellStyles = {
  borderBottom: '1px solid #EBEFF3',
};

const reliabilityColorMapping = {
  New: '#BEDFFF',
  Stellar: '#C6F6D5',
  Exceptional: '#FBD38D',
};

type Props = {
  applicant: FilteredApplicantInfo;
  updating: boolean;
  handleSelectApplicant: (applicantId: number, applicantName: string, select: boolean, shiftIds: number[]) => void;
  setApplicantModalData: any;
  handleArchiveApplicants: any;
  shiftIds: number[];
  handleOpenMessageModal: (data: {channelId: number; nurseName: string}) => void;
  isPastShift?: boolean;
};

const ApplicantListRow = ({
  applicant,
  updating,
  handleSelectApplicant,
  setApplicantModalData,
  handleArchiveApplicants,
  shiftIds,
  handleOpenMessageModal,
  isPastShift,
}: Props) => {
  const {
    name,
    image,
    total_shifts: totalShifts,
    total_facility_shifts: totalFacilityShifts,
    reliabilityScore,
    rating,
    status,
    applicantId,
    archiveDate,
    channelId,
    relation,
    isOvertime,
  } = applicant;
  const sessionUser = useSessionUser();

  const getReliabilityText = () => {
    const numericReliabilityRating = Number(reliabilityScore);
    // Check if the applicant is new (no shifts completed)
    if (totalShifts === 0) {
      return 'New';
    }
    // Check the reliability rating score and return the corresponding text
    if (numericReliabilityRating >= 89 && numericReliabilityRating <= 100) {
      return 'Stellar';
    }
    if (numericReliabilityRating >= 64 && numericReliabilityRating < 89) {
      return 'Exceptional';
    }
    // Return blank if none of the above conditions are met
    return '';
  };

  const reliabilityText = getReliabilityText();
  const badgeColor = reliabilityColorMapping[reliabilityText as keyof typeof reliabilityColorMapping] || '#C6F6D5';

  return (
    <Tr key={uuid()} fontSize="1rem" style={{opacity: status === 'Cancelled' ? 0.5 : 1}}>
      <Td width="12%" textAlign="center" sx={tableCellStyles} fontSize="14px">
        <Flex
          position="relative"
          onClick={() => setApplicantModalData({applicantId, isOpen: true, shiftIds})}
          style={{cursor: 'pointer'}}
        >
          <Flex alignItems="center" justifyContent="flex-start" position="relative">
            <Avatar size="sm" name={name} src={image} bg="blue.500" />
            {relation === 'facility-favorite' && (
              <Box position="absolute" right="-5px" bottom="0" color="red.500" top="19px">
                <Icon as={FaHeart} boxSize="14px" color="#FC8181" />
              </Box>
            )}
          </Flex>
          <Flex
            className="ndproname-container"
            flexDir="column"
            alignItems="flex-start"
            justifyContent="center"
            ml="12px"
            _hover={{fontWeight: '600', textDecoration: 'underline'}}
          >
            <Text>{name}</Text>
          </Flex>
        </Flex>
      </Td>
      <Td textAlign="center" sx={tableCellStyles} fontSize="14px" fontWeight="500">
        <Text fontWeight="600">For You: {totalFacilityShifts}</Text>
        <Text fontSize="12px" color="grey">
          Total: {totalShifts}
        </Text>
      </Td>
      <Td textAlign="center" sx={tableCellStyles} fontSize="14px" fontWeight="500">
        {reliabilityText ? (
          <Badge
            bg={badgeColor}
            fontSize="0.7rem"
            borderRadius="0.3125rem"
            fontWeight="semibold"
            padding="0.2rem 0.4rem"
            color="#22543D"
            textTransform="capitalize"
          >
            {reliabilityText}
          </Badge>
        ) : (
          '-'
        )}
      </Td>
      <Td textAlign="center" sx={tableCellStyles} fontSize="14px" fontWeight="500">
        <Flex flexDir="row" justifyContent="center" alignItems="center">
          {rating ? (
            <>
              <Text color="#404B89" fontWeight="600">
                {rating}
              </Text>
              <Flex mt="-1px" ml="2px">
                <FaStar color="#404B89" />
              </Flex>
            </>
          ) : (
            <Text fontWeight="600">N/A</Text>
          )}
        </Flex>
      </Td>
      <Td sx={tableCellStyles}>
        <Flex flexDirection="column" alignItems="center">
          <Button
            bg="#303f9f"
            size="sm"
            width="100%"
            isLoading={updating}
            onClick={() => {
              handleSelectApplicant(applicantId, name, true, shiftIds);
              trackSelectNdproEvent(applicant, shiftIds?.[0], applicantId);
            }}
            isDisabled={isPastShift || sessionUser?.role === 'Viewer'}
          >
            Select
          </Button>
          {isOvertime && (
            <Tooltip
              label="Selecting this NDPro will result in overtime rates on the Facility's invoice unless the contract explicitly indicates otherwise."
              placement="bottom"
              hasArrow
            >
              <Text color="#FC8181" fontSize="10px" fontWeight="500">
                Overtime shift
              </Text>
            </Tooltip>
          )}
        </Flex>
      </Td>
      {sessionUser?.role !== 'Viewer' && (
        <Td sx={tableCellStyles}>
          <Box className="icon-box" display="flex" flexDirection="row" justifyContent="flex-end">
            <Menu>
              <MenuButton as={IconButton} icon={<FaEllipsis />} variant="ghost" _hover={{bg: 'gray.400'}} />
              <MenuList>
                <MenuItem onClick={() => setApplicantModalData({applicantId, isOpen: true, shiftIds})}>
                  <Box>
                    <IconButton
                      sx={commonButtonProps}
                      aria-label="button"
                      colorScheme="black"
                      icon={<FaRegUser />}
                      variant="simple"
                    />
                    View Profile
                  </Box>
                </MenuItem>

                <MenuItem onClick={() => handleOpenMessageModal({channelId, nurseName: name})}>
                  <Box className="button-modal-box" paddingRight="0.625rem">
                    <IconButton
                      sx={commonButtonProps}
                      aria-label="button"
                      colorScheme="red"
                      icon={<TbMessageCircle />}
                      variant="simple"
                    />
                    Send Message
                  </Box>
                </MenuItem>

                <MenuItem color="#ED8936" onClick={() => handleArchiveApplicants(applicantId, !!archiveDate)}>
                  <Box className="button-modal-box" paddingRight="0.625rem">
                    <IconButton
                      sx={commonButtonProps}
                      aria-label="button"
                      colorScheme="red"
                      icon={<LuArchive color="#ED8936" />}
                      variant="simple"
                    />
                    {archiveDate ? 'Unarchive Applicant' : 'Archive Applicant'}
                  </Box>
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Td>
      )}
    </Tr>
  );
};

export default ApplicantListRow;
