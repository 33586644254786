import trackEvent from './trackEvent';
import MixpanelConstants from '../constants';

const trackPrintWeekEvent = (dates: Record<string, string>) => trackEvent(MixpanelConstants.PRINT_WEEK, {dates});
const trackUnitFilterChangeEvent = (unitFilters: Record<string, any>) =>
  trackEvent(MixpanelConstants.UNIT_FILTER_CHANGE, {unitFilters});
const trackStatusFilterChangeEvent = (statusFilter: Record<string, any>) =>
  trackEvent(MixpanelConstants.STATUS_FILTER_CHANGE, {statusFilter});

export {trackPrintWeekEvent, trackUnitFilterChangeEvent, trackStatusFilterChangeEvent};
