/* eslint-disable import/prefer-default-export */
export const covidStatusOption = [
  {
    value: 'free',
    label: 'Covid Free',
  },
  {
    value: 'on_site',
    label: 'On-site',
  },
];
