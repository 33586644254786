import React, {useCallback, useEffect} from 'react';
import {withLDProvider, useLDClient} from 'launchdarkly-react-client-sdk';

import {useIntercom} from 'react-use-intercom';
import {useAuth0} from '@auth0/auth0-react';
import mixpanel from 'mixpanel-browser';
import {Box, Center, Flex, Spinner, useDisclosure} from '@chakra-ui/react';
import {Outlet} from 'react-router-dom';
import useAppDispatch from './shared/hooks/useAppDispatch';
import {setIsMobile} from './shared/slices/mobileSlice';
import './App.scss';
import useIsAuthenticated from './shared/hooks/useIsAuthenticated';
import SidebarWithHeader from './shared/components/Navigation/SidebarWithHeader/SidebarWithHeader';
import {UsersState, setLdContext} from './shared/slices/usersSlice';
import useAppSelector from './shared/hooks/useAppSelector';
import MobileNav from './shared/components/Navigation/MobileNav/MobileNav';

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  useIsAuthenticated();
  const {boot} = useIntercom();
  const ldClient = useLDClient();
  const {isAuthenticated} = useAuth0();
  const sessionUser = useAppSelector((state: {usersData: UsersState}) => state.usersData.sessionUser);
  const {onOpen} = useDisclosure();

  const token = useAppSelector((state: {auth: {token: string}}) => state.auth.token);

  const intercomBoot = useCallback(() => {
    if (isAuthenticated && sessionUser?.userId) {
      const {
        firstName,
        lastName,
        email,
        userId,
        legacyFacilityId,
        nextGenFacilityId,
        role,
        facilityName,
        segmentName,
        facilityHomeMarket,
        facilitySelectionType,
      } = sessionUser;
      boot({
        name: `${firstName} ${lastName}`,
        email,
        userId,
        customAttributes: {
          type: 'facility',
          legacy_facility_id: legacyFacilityId,
          next_gen_facility_id: nextGenFacilityId,
          role,
          facilty_name: facilityName,
          Segment: segmentName,
          'Home Market': facilityHomeMarket,
          'Selection Type': facilitySelectionType,
        },
      });
    }
  }, [isAuthenticated, sessionUser, boot]);

  const registerIdentitiesWithProviders = useCallback(async () => {
    if (isAuthenticated && sessionUser?.userId && sessionUser?.facilityName) {
      const {
        userId,
        legacyFacilityId,
        nextGenFacilityId,
        role,
        facilityName,
        segmentName,
        facilityHomeMarket,
        firstName,
        lastName,
        email,
      } = sessionUser;
      const ldContext = {
        kind: 'multi',
        facility: {
          facilityName,
          key: legacyFacilityId,
          facilitySegment: segmentName,
          'Home Market': facilityHomeMarket,
        },
        user: {
          key: userId,
          userRole: role,
        },
      };
      await ldClient?.identify(ldContext);
      dispatch(setLdContext(true));

      mixpanel.set_config({persistence: 'localStorage'});
      mixpanel.identify(userId);
      mixpanel.people.set({
        $email: email,
        name: `${firstName} ${lastName}`,
        facilityLegacyId: legacyFacilityId,
        facilityNextGenId: nextGenFacilityId,
        facilityName,
        facilitySegment: segmentName,
        homeMarket: facilityHomeMarket,
        userRole: role,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, sessionUser, ldClient]);

  useEffect(() => {
    const handleResize = () => {
      dispatch(setIsMobile(window.innerWidth < 768));
    };
    intercomBoot();
    registerIdentitiesWithProviders();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intercomBoot, registerIdentitiesWithProviders]);

  if (!token) {
    return (
      <Center w="100vw" h="100vh">
        <Flex margin="0" position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="#38427E" size="xl" />
        </Flex>
      </Center>
    );
  }
  if (sessionUser?.role === 'Viewer' || !sessionUser) {
    return (
      <Box minH="100vh" bg="white" maxW="68rem" margin="auto">
        <MobileNav onOpen={onOpen} />
        <Box className="outlet" height="calc(100vh - 56px)">
          <Outlet />
        </Box>
      </Box>
    );
  }

  return <SidebarWithHeader />;
};

export default withLDProvider({
  clientSideID: `${process.env.REACT_APP_LD_CLIENT_SIDE_ID}`,
})(App);
