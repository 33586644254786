import React from 'react';
import {createBrowserRouter} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import Logout from './Logout/Logout';
import ErrorPage from './Error/ErrorPage';
import CreateProfile from './Settings/subroutes/CreateProfile/CreateProfile';
import Users from './Users/Users';
import App from '../App';
import ShiftTemplates from './ShiftTemplates/ShiftTemplates';
import Login from './Login/Login';
import CreateShift from './Shifts/CreateShift/CreateShift';
import CalendarPage from './Shifts/CalendarPage/CalendarPage';
import EditShift from './Shifts/EditShift/EditShift';
// import CalendarMonthlyView from './Shifts/CalendarPage/CalendarMonthlyView';
import Messages from './Messages/Messages';
import Settings from './Settings/Settings';
import Home from './Home/Home';
import Maintenance from './Maintenance/Maintenance';
import MonthlyAgendaView from './Shifts/CalendarPage/MonthlyAgendaView';
import {useSessionUser} from './Shifts/CreateShift/appSelectors';

const ErrorBoundaryLayout = () => (
  <ErrorBoundary FallbackComponent={Maintenance}>
    <App />
  </ErrorBoundary>
);

const ProtectedRoute = ({children, roles}: any) => {
  const sessionUser = useSessionUser();

  if (sessionUser?.role === 'Viewer' && !roles.includes(sessionUser?.role)) {
    return <ErrorPage />;
  }

  return children;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <ErrorBoundaryLayout />,
    errorElement: <ErrorPage />,
    children: [
      {path: '/home', element: <Home />},
      {
        path: 'settings',
        element: (
          <ProtectedRoute roles={[]}>
            <Settings />
          </ProtectedRoute>
        ),
        children: [
          {
            path: 'profile',
            element: <CreateProfile />,
          },
        ],
      },
      {
        path: 'users',
        element: (
          <ProtectedRoute roles={[]}>
            <Users />
          </ProtectedRoute>
        ),
      },
      {
        path: '/messages',
        element: (
          <ProtectedRoute roles={[]}>
            {' '}
            <Messages />
          </ProtectedRoute>
        ),
      },
      {
        path: 'shift-templates',
        element: (
          <ProtectedRoute roles={[]}>
            <ShiftTemplates />
          </ProtectedRoute>
        ),
      },
      {
        path: 'shifts/create',
        element: (
          <ProtectedRoute roles={[]}>
            <CreateShift />
          </ProtectedRoute>
        ),
      },
      {
        path: 'edit-shift/:shiftId/:openingId',
        element: (
          <ProtectedRoute roles={[]}>
            <EditShift />
          </ProtectedRoute>
        ),
      },
      {
        path: 'weekly',
        element: (
          <ProtectedRoute roles={['Viewer']}>
            <CalendarPage />
          </ProtectedRoute>
        ),
      },
      // {
      //   path: 'monthly',
      //   element: (
      //     <ProtectedRoute roles={[]}>
      //       <CalendarMonthlyView />
      //     </ProtectedRoute>
      //   ),
      // },
      {
        path: 'monthly',
        element: (
          <ProtectedRoute roles={[]}>
            <MonthlyAgendaView />
          </ProtectedRoute>
        ),
      },
      {
        path: 'logout',
        element: <Logout />,
      },
    ],
  },
  {
    path: 'maintenance',
    element: <Maintenance />,
  },
  {
    path: 'login',
    element: <Login />,
  },
]);

export default router;
