import {TimeIcon, WarningIcon} from '@chakra-ui/icons';
import {Flex, Icon, Text} from '@chakra-ui/react';
import React from 'react';
import {FaCheckCircle, FaHeart, FaRegStar, FaStar} from 'react-icons/fa';
import uuid from 'react-uuid';
import {OpeningsV2} from '../../../shared/types';

type Props = {
  opening: OpeningsV2;
  isPastShift: boolean;
};

const NdProListItem = ({opening, isPastShift}: Props) => {
  const formatName = (name: string, status: string, isFavorite?: boolean) => {
    const [firstName, ...lastName] = name.split(' ');

    const getFontColor = (shiftStatus: string) => {
      switch (shiftStatus) {
        case 'Confirmed':
        case 'Completed':
        case 'PendingReview':
        case 'PendingClockIn':
          return '#46BF7E';
        case 'PendingCheckIn':
        case 'OpenedWithApplicants':
        case 'Selected':
          return '#405FF2';
        default:
          return '#46BF7E';
      }
    };

    const fontColor = getFontColor(status);

    if (lastName.length > 0) {
      return (
        <Flex alignItems="center" gap="0.2rem">
          <Text fontSize="12px" fontWeight="600" color={fontColor}>
            {firstName} {lastName[Number(lastName?.length) - 1].charAt(0)}.
          </Text>
          {isFavorite && <Icon as={FaHeart} color="#FC8181" />}
        </Flex>
      );
    }
    return (
      <Text fontSize="12px" fontWeight="600" color={fontColor}>
        {firstName}
      </Text>
    );
  };

  const getStatusText = (status: string, ndProName?: string, isFavorite?: boolean) => {
    if (isPastShift) {
      switch (status) {
        case 'PendingReview':
        case 'Completed':
        case 'PendingClockIn':
        case 'PendingCheckIn':
          return formatName(ndProName ?? '', status, isFavorite);
        default:
          return '';
      }
    } else {
      switch (status) {
        case 'OpenedWithApplicants':
          return (
            <Text fontSize="12px" fontWeight="600" color="#405FF2">
              Select Applicants
            </Text>
          );
        case 'Opened':
          return (
            <Text fontSize="12px" fontWeight="600" color="grey">
              Searching...
            </Text>
          );
        default:
          return formatName(ndProName ?? '', status, isFavorite);
      }
    }
  };

  const renderStatusIcon = (status: string) => {
    switch (status) {
      case 'Completed':
        return <Icon as={FaStar} color="yellow.400" mt="-1px" boxSize="12px" mr="5px" />;
      case 'Confirmed':
        return <Icon as={FaCheckCircle} color="green.400" mt="-1px" boxSize="14px" ml="-1px" mr="3px" />;
      case 'PendingReview':
        return <Icon as={FaRegStar} color="#405FF2" mt="-1px" boxSize="12px" mr="5px" />;
      case 'PendingClockIn':
        return <TimeIcon color="green.400" ml="-2px" mr="2px" padding="1px" />;
      case 'Selected':
      case 'PendingCheckIn':
        return <TimeIcon color="#405FF2" ml="-2px" mr="2px" padding="1px" />;
      case 'OpenedWithApplicants':
        return <WarningIcon color="#405FF2" ml="-2px" mr="2px" padding="1px" />;
      default:
        return null;
    }
  };

  const displayText = getStatusText(opening.status, opening.ndProName, opening.favorite);
  const shouldDisplayIcon = displayText !== '';

  return (
    <Flex className="shift-nd-list" key={uuid()} pb="0.3125rem" w="full" alignItems="center" paddingLeft="0.5rem">
      {shouldDisplayIcon && renderStatusIcon(opening.status)}
      <Text as="span" fontSize="0.75rem" fontWeight="600" ml="0.125rem" color={displayText ? 'black' : '#808080'}>
        {displayText}
      </Text>
    </Flex>
  );
};
export default NdProListItem;
