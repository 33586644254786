import {QueryHookOptions, gql, useQuery} from '@apollo/client';
import { useEffect } from 'react';
import {useApolloClients} from '../../../core/providers/ApolloContextProvider';
import {GetLegacyFacilityByIdData, GetLegacyFacilityByIdVariables} from './types';
import settingFields from './fields';
import useAppDispatch from '../../hooks/useAppDispatch';
import { getSettingsData, setSettingsData } from '../../slices/settingsSlice';


const getLegacyFacilityData = () => gql`
  query Facility($id: Int) {
    data: Facility(id: $id) {
      ${settingFields}
    }
  }
`;

const useGetLegacyFacilityDataById = (
  options?: QueryHookOptions<GetLegacyFacilityByIdData, GetLegacyFacilityByIdVariables>,
) => {
  const {legacyClient} = useApolloClients(); 

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getSettingsData());
  }, [dispatch]);

  return useQuery<GetLegacyFacilityByIdData, GetLegacyFacilityByIdVariables>(getLegacyFacilityData(), {
    skip: !options?.variables?.id ,
    client: legacyClient,
    ...options,
    onCompleted: data => {
      dispatch(setSettingsData(data?.data));
    },
    onError: err => {
      console.log('err from useGetLegacyFacilityDataById', err);
    },
  });
};

export default useGetLegacyFacilityDataById;
