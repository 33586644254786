/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import { Unit, UnitPosition } from '../../../../shared/types/unit';
import { Qualification } from '../../../../shared/types/qualifications';
import { PositionWithExtendedQualifications, PositionWithQualifications } from '../../../ShiftTemplates/TemplateForm/TemplateForm.types';
import { Position } from '../../../../shared/types/position';
import { SetValueFunction } from './CreateShiftForm.types';
import { SingleValue } from '../../../ShiftTemplates/ShiftTemplates.types';
import { ShiftTemplateModel, Template } from '../../../../shared/gql/shiftTemplates/types';

// Saved Shifts
export const generateSavedShiftOpitons = (shiftTemplates: Template[]) =>
  shiftTemplates?.map((shift: Template) => ({
    value: shift.id,
    label: shift.name,
  }));

// Unit Selector
export const generateUnitOptions = (facilityUnits: Unit[] | undefined) =>
  facilityUnits?.map((unit: Unit) => ({
    value: unit.id,
    label: unit.name,
  }));

// Rate Selector
export const getSelectedPositionQualifications = (
  selectedUnitPositions: PositionWithQualifications[] | undefined,
  selectedPositions: Position[]
): PositionWithQualifications[] | undefined =>
  selectedUnitPositions?.filter((position: PositionWithQualifications) =>
    selectedPositions.some((positionObject: Position) => positionObject.name === position.name)
  );

export const getSelectedPositionBaseRate = (selectedPositionQualifications: PositionWithQualifications[] | undefined) =>
  selectedPositionQualifications?.map((position: PositionWithQualifications) => ({
    name: position.name,
    baseRate: position.rate.baseRate,
  }));

export const stopTimeSelection = (time: SingleValue, setValue: SetValueFunction) => {
  setValue('stopTime', time.value);
};

// ToDo: Refactor to use the same function as the Qualification Selector in Shift Templates
// Qualification Selector
export const groupQualificationOptions = (selectedPositionQualifications: PositionWithQualifications[] | undefined) =>
  selectedPositionQualifications?.map((item: PositionWithQualifications) => ({
    label: item.name,
    options: item.qualifications.map((qualification: Qualification) => ({
      label: qualification.name,
      value: qualification.id,
    })),
  }));

export const qualificationSelection = (qualificationArray: SingleValue[], setValue: any) => {
  const tempQualification = qualificationArray.map((qualificationObject: SingleValue) => ({
    id: qualificationObject.value,
    name: qualificationObject.label,
  }));
  setValue('qualifications', tempQualification);
};

export const getDefaultValues = (template: ShiftTemplateModel | null, positions: Position[] | undefined) => {
  if (!template) {
    return {
      templateName: '',
      unit: undefined,
      bonus: 0,
      position: positions,
      startTime: undefined,
      endTime: undefined,
      break: undefined,
      numOfOpenings: 0,
      day: [],
      description: undefined,
      clockInInstruction: undefined,
      clockOutInstruction: undefined,
      qualifications: undefined,
      dayAndOpenings: [],
    };
  }

  const {
    name: templateName,
    content: {
      unit,
      bonusAmount: bonus,
      startTime,
      endTime,
      openings,
      description,
      clockInInstruction,
      clockOutInstruction,
      qualifications,
      break: breakTime,
    },
  } = template;

  const { total: numOfOpenings, dayOfWeek: day } = openings[0] ?? {};

  return {
    templateName,
    unit: { value: unit.id, label: unit.name },
    bonus,
    position: positions,
    startTime,
    endTime,
    numOfOpenings,
    day: day ? [day] : [],
    description,
    clockInInstruction,
    clockOutInstruction,
    qualifications: qualifications.map((qualification: Qualification) => ({
      label: qualification.name,
      value: qualification.id,
    })),
    dayAndOpenings: [],
    breakTime: breakTime ? { label: breakTime, value: breakTime } : undefined,
  };
};

export const handleDaySelection = (currentDaySelected: string, setValue: any, watch: any) => {
  const currentDayAndOpenings = watch('dayAndOpenings');

  if (!currentDayAndOpenings.length) {
    setValue('dayAndOpenings', [{ localStartDate: currentDaySelected, openings: 1 }], {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue('date', [currentDaySelected], { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    return;
  }

  const existingEntry = currentDayAndOpenings?.find((entry: any) => entry.localStartDate === currentDaySelected);

  if (!existingEntry) {
    setValue('dayAndOpenings', [...currentDayAndOpenings, { localStartDate: currentDaySelected, openings: 1 }], {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    const currentDates = watch('dayAndOpenings')?.map((entry: any) => entry.localStartDate);
    setValue('date', currentDates, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  }
};

export const handleDecrementNumOfOpenings = (index: number, setValue: any, watch: any) => {
  const numOfOpenings = watch('numOfOpenings');
  if (numOfOpenings[index] === 1) {
    numOfOpenings[index] = 0;
  } else {
    numOfOpenings[index] -= 1;
  }
  setValue('numOfOpenings', [...numOfOpenings], { shouldValidate: true, shouldDirty: true, shouldTouch: true });
};

export const getDays = () => {
  const daysOfWeek = [];
  const dayNames = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= 6; i++) {
    const newDay = moment().add(i, 'days');

    daysOfWeek.push({
      date: newDay.date(),
      dayOfWeek: dayNames[newDay.day()],
      fullDate: newDay.format('YYYY-MM-DD'),
    });
  }

  return daysOfWeek;
};

// Define a function to parse the query parameters
export const getClonedShiftData = (parameters: string, facilityUnits: UnitPosition[], otherQualifications: Qualification[], timezone: string = 'US/Central'): Record<string, any> => {
  const dayNames = ['Sun','Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
  const decodeParams = decodeURIComponent((parameters).replace(/\+/g, '%20'));
  const queryParameters: Record<string, string> = {};
  const searchParams = decodeParams.slice(1).split('&');
  searchParams.forEach((param) => {
    const [key, value] = param.split('=');
    queryParameters[key] = value;
  });
  const breakTime = queryParameters?.breakTime ?? 0;
  const breakTimeSelected = parseInt(breakTime, 10) < 60 ? `${breakTime}mins` : '1hour'
  const startDay = moment.tz(queryParameters.start_time, timezone).day();
  const foundUnit = facilityUnits?.find(unit => unit.legacyUnitId === parseInt(queryParameters.unit, 10));
  const unitPositions = foundUnit?.positions ?? [];
  const foundPostion = unitPositions?.find(position => position.name === queryParameters.type) as PositionWithExtendedQualifications;
  const qualifications = queryParameters.qualifications && queryParameters.qualifications.indexOf(',') < 0 ? `${queryParameters.qualifications},` : queryParameters.qualifications;
  const qualificationsArray = queryParameters.qualifications ? qualifications.slice(0).split(',') : [];
  const foundQualification = otherQualifications.filter(qualification => qualificationsArray.some(clonedQualification => qualification.name === clonedQualification));
  const qualificationData = foundPostion?.qualifications && foundPostion?.qualifications.length > 0 ? {
    clockInInstruction: foundPostion?.qualifications[0]?.clockInInstruction ?? '',
    clockOutInstruction: foundPostion?.qualifications[0]?.clockOutInstruction ?? '',
    description: foundPostion?.qualifications[0]?.description ?? '',
  }
: null;
  const clonedShift = {
    unitId: foundUnit?.id,
    content: {
      unit: {
        id: foundUnit?.id,
        name: foundUnit?.name
      },
      break: breakTimeSelected,
      endTime: moment.tz(queryParameters.end_time, timezone).format('HH:mm'),
      date: moment.tz(queryParameters.start_time, timezone).format('YYYY-MM-DD'),
      openings: [
        {
          total: 1,
          dayOfWeek: dayNames[startDay]
        }
      ],
      positions: [
        {
          id: foundPostion?.id,
          name: foundPostion?.name,
          subsidy: foundPostion?.rate.subsidy,
          baseRate: foundPostion?.rate.baseRate,
          adjustmentRate: 0,
        }
      ],
      clockInInstruction: qualificationData?.clockInInstruction,
      clockOutInstruction: qualificationData?.clockOutInstruction,
      startTime: moment.tz(queryParameters.start_time, timezone).format('HH:mm'),
      bonusAmount: 0,
      description: queryParameters.description && queryParameters.description.length > 0 ? queryParameters.description : qualificationData?.description,
      qualifications: foundQualification,
    },
  }
  return clonedShift;
};
