import React from 'react';

import {Box, Flex, Text} from '@chakra-ui/react';
import NDReactSelect from '../NDReactSelect/NDReactSelect';
import BreakSkeleton from './BreakSkeleton/BreakSkeleton';

import {SingleValue} from '../../../routes/ShiftTemplates/ShiftTemplates.types';
import {Props} from './BreakSelector.types';

const BreakSelector = ({
  breakDefaultValues,
  shiftTemplate,
  isEditable,
  handleBreakSelection,
  setValue,
  register,
  currentBreakValues,
  loading,
}: Props) => {
  if (loading) {
    return <BreakSkeleton />;
  }

  return (
    <Flex flexDirection="column" gap=".5rem">
      <Text fontWeight="600">Break</Text>
      <Box marginBottom="1.5rem" width={['inherit', '12.5rem']}>
        <NDReactSelect
          name="break"
          {...register('break')}
          defaultValue={breakDefaultValues}
          isDisabled={!!shiftTemplate && !isEditable}
          placeholder="Break"
          value={currentBreakValues}
          isMulti={false}
          options={[
            {value: '0mins', label: 'No Break'},
            {value: '15mins', label: '15 min'},
            {value: '30mins', label: '30 min'},
            {value: '45mins', label: '45 min'},
            {value: '1hour', label: '1 hour'},
          ]}
          onChange={(e: SingleValue) => handleBreakSelection(e, setValue)}
        />
      </Box>
    </Flex>
  );
};

export default BreakSelector;
