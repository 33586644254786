import React from 'react';
import {Flex, Text, Checkbox, NumberInput, NumberInputField, Box} from '@chakra-ui/react';
import {Controller} from 'react-hook-form';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {Props} from './ShiftBonusSelector.types';

const BonusSelector = ({
  control,
  hasBonus,
  handleHasBonus,
  watch,
  handleBonusChange,
  setValue,
  setHasBonus,
  register,
}: Props) => {
  const {showRates} = useFlags();
  if (!showRates) {
    return null;
  }
  return (
    <Flex flexDirection="column" gap=".5rem">
      <Text fontWeight="600">Bonus</Text>
      <Checkbox
        _disabled={{
          opacity: '0.8',
        }}
        isChecked={hasBonus}
        marginBottom={['inherit', '1rem']}
        onChange={() => handleHasBonus(setHasBonus, hasBonus, setValue)}
      >
        This shift has a bonus
      </Checkbox>
      {hasBonus && (
        <>
          <Text>Bonus amount</Text>
          <Controller
            name="bonus"
            control={control}
            render={({field}) => (
              <NumberInput
                {...field}
                {...register('bonus')}
                precision={2}
                step={0.2}
                value={typeof watch('bonus') === 'number' ? (watch('bonus') / 100).toFixed(2) : watch('bonus')}
                width="6.5rem"
                textAlign="center"
                _disabled={{
                  opacity: '0.6',
                }}
                onChange={(e: any) => handleBonusChange(e, setValue)}
              >
                <NumberInputField />
                <Box position="absolute" left="0.5rem" top="50%" transform="translateY(-50%)">
                  $
                </Box>
              </NumberInput>
            )}
          />
        </>
      )}
    </Flex>
  );
};

export default BonusSelector;
