import React, {useEffect, useState} from 'react';

import {
  Avatar,
  Badge,
  Box,
  Button,
  Modal as ChakraModal,
  Flex,
  IconButton,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';

import {convertDistance} from 'geolib';
import uuid from 'react-uuid';
import moment from 'moment';

import {FaRegHeart, FaHeart, FaRegStar} from 'react-icons/fa6';
import {BiClinic, BiMessageRounded} from 'react-icons/bi';
import {MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowRight} from 'react-icons/md';
import {IoLocationOutline} from 'react-icons/io5';
import {Props} from './ApplicantProfileModal.types';
import {useGetApplicantFacilityInformation} from '../../../../shared/gql/CalendarShifts/queries';
import {
  useArchiveApplicants,
  useRemoveRelationToFacility,
  useSetRelationToFacility,
} from '../../../../shared/gql/CalendarShifts/mutations';
import PdfModal from './PdfModal';
import credentials from '../Icons/Credentials.svg';
import shifts from '../Icons/Shifts.svg';
import test from '../Icons/Test.svg';
import position from '../Icons/Position.svg';
import resume from '../Icons/Resume.svg';
import {useSessionUser} from '../../CreateShift/appSelectors';
import {
  trackNdproFavEventFromProfile,
  trackNdproRemoveFavEventFromProfile,
  trackNdproSelectFromProfile,
} from '../../../../shared/mixpanel/events/ndpro';

type PdfModalData = {
  isOpen: boolean;
  data: {
    src: string;
    title?: string;
  } | null;
};

const ApplicantProfileModal = ({
  handleIsModalOpen,
  applicantId,
  shiftIds,
  handleMessageClick,
  shiftData,
  handleUpdateApplicantStatus: updateApplicantStatus,
  updateApplicantStatusLoading,
  refetchShiftOpenings,
  setConfirmationModalOpen,
  setActionDone,
}: Props) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [pdfData, setPdfData] = useState<PdfModalData>({isOpen: false, data: null});
  const [displayTest, setDisplayTest] = useState(false);
  const [displayCredentials, setDisplayCredentials] = useState(false);
  const sessionUser = useSessionUser();
  const isViewer = sessionUser?.role === 'Viewer';

  const {data, loading, refetch} = useGetApplicantFacilityInformation({
    variables: {id: applicantId},
  });
  const bg = useColorModeValue('white', 'gray.700');

  const applicantData = data?.data;

  const TestResultList = applicantData?.credentials?.filter(credential => credential.type === 'test');

  const certificationList = applicantData?.credentials?.filter(
    credential =>
      credential?.type === 'certification' || credential?.type === 'vaccination' || credential?.type === 'other'
  );

  const handleClose = () => {
    onClose();
    handleIsModalOpen({isOpen: false, applicantId: null});
  };

  const [setRelationToFacility] = useSetRelationToFacility();
  const [removeRelationToFacility] = useRemoveRelationToFacility();

  const toast = useToast();

  const displayToast = (description: string, toastStatus: 'loading' | 'error' | 'info' | 'warning' | 'success') => {
    toast({
      title: description,
      status: toastStatus,
      duration: 3000,
      isClosable: true,
    });
  };

  const handleUpdateApplicantStatus = async (select: boolean) => {
    if (applicantId) updateApplicantStatus(applicantId, applicantData?.name || '', select, shiftIds, refetch);
  };

  const handleChangeRelationToFacility = async () => {
    setActionDone(true);
    if (applicantData?.relation === 'facility-favorite') {
      try {
        const setRelationResponse = await removeRelationToFacility({
          variables: {
            nurseId: applicantData?.nurseId,
            reason: '',
            relation: 'facility-favorite',
          },
        });
        if (setRelationResponse.data) {
          trackNdproRemoveFavEventFromProfile(applicantData, applicantData?.nurseId || 0);
          displayToast(`${applicantData?.name} has been removed from your Favorites List`, 'error');
          refetch();
          refetchShiftOpenings();
        } else if (!setRelationResponse.data) {
          displayToast('Remove applicant from favourite failed!', 'error');
        }
      } catch (error) {
        console.log('error:', error);
      }
    } else {
      try {
        const setRelationResponse = await setRelationToFacility({
          variables: {
            nurseId: applicantData?.nurseId,
            reason: '',
            relation: 'facility-favorite',
          },
        });
        if (setRelationResponse.data) {
          trackNdproFavEventFromProfile(applicantData, applicantData?.nurseId || 0);
          displayToast(`${applicantData?.name} has been added to your Favorites List`, 'success');
          refetch();
          refetchShiftOpenings();
        } else if (!setRelationResponse.data) {
          displayToast('Add applicant to favourite failed!', 'error');
        }
      } catch (error) {
        console.log('error:', error);
      }
    }
  };
  const [archiveApplicants] = useArchiveApplicants();

  const handleArchiveApplicants = async (id: number | null | undefined, isUnarchive?: boolean) => {
    const archiveApplicantResponse = await archiveApplicants({
      variables: {
        id,
        archiveDate: !isUnarchive ? moment(new Date()).toISOString() : null,
        shiftIds,
      },
    });

    if (archiveApplicantResponse?.data) {
      refetch();
      refetchShiftOpenings();
    }
  };

  useEffect(() => {
    onOpen();
  }, [onOpen]);

  return (
    <>
      <ChakraModal isOpen={isOpen} onClose={handleClose} size={['s', 'md']} isCentered>
        <ModalOverlay />

        <ModalContent>
          {!applicantData || loading ? (
            <Box p="1rem 1.5rem" bg={bg} borderRadius="md" height="auto" minHeight="25rem">
              <SkeletonCircle size="12" />

              <Flex width="100%" gap="0.5rem" paddingTop="1.5rem">
                <Skeleton borderRadius="0.5rem" height="2.3rem" width="50%" alignSelf="flex-start" />
                <Skeleton borderRadius="0.5rem" height="2.3rem" width="50%" alignSelf="flex-start" />
              </Flex>
              <VStack spacing={8} mt={10}>
                <SkeletonText noOfLines={4} width="50%" skeletonHeight="1rem" alignSelf="flex-start" />
                <SkeletonText noOfLines={2} width="50%" skeletonHeight="1rem" alignSelf="flex-start" />
              </VStack>
            </Box>
          ) : (
            <>
              <ModalHeader>
                <Flex alignItems="center" gap="0.5rem">
                  <Avatar
                    size="md"
                    bg="#313971"
                    color="white"
                    bgImage={applicantData?.image}
                    name={!applicantData?.image ? applicantData?.name : ''}
                  />

                  <Text fontWeight="700" color="black" width="fit-content">
                    {applicantData?.name}
                  </Text>
                  {applicantData?.relation === 'facility-favorite' ? (
                    <FaHeart color="#FC8181" onClick={isViewer ? () => {} : () => handleChangeRelationToFacility()} />
                  ) : (
                    <FaRegHeart
                      color="#FC8181"
                      onClick={isViewer ? () => {} : () => handleChangeRelationToFacility()}
                    />
                  )}
                </Flex>
              </ModalHeader>
              <ModalCloseButton size="sm" marginTop={['.2rem', '.6rem']} />

              <ModalBody minHeight="20rem">
                <Flex flexDirection="column" gap="1rem">
                  <Flex width="100%" gap="0.5rem">
                    <Button
                      variant="secondary"
                      width="100%"
                      onClick={() => {
                        handleMessageClick({
                          channelId: applicantData?.channelId,
                          shiftStartTime: shiftData?.startTime,
                          shiftEndTime: shiftData?.endTime,
                          nurseName: applicantData?.name,
                          shiftRole: 'Demo',
                        });
                      }}
                      isDisabled={isViewer}
                    >
                      <Flex gap="0.3125rem" alignItems="center">
                        <BiMessageRounded />
                        Message
                      </Flex>
                    </Button>

                    <Button
                      variant="secondary"
                      width="100%"
                      onClick={() => setPdfData({isOpen: true, data: applicantData?.resume})}
                      isDisabled={!applicantData?.resume}
                    >
                      <Flex gap="0.3125rem" alignItems="center">
                        <img src={resume} alt="resume" /> Resume
                      </Flex>
                    </Button>
                  </Flex>

                  <Flex flexDirection="column" gap="0.4rem">
                    <Flex gap="0.3125rem" alignItems="flex-start">
                      <Flex padding="5px">
                        <img src={position} alt="position" />
                      </Flex>

                      <Text width="95%">{applicantData?.positionType?.toString()}</Text>
                    </Flex>

                    {applicantData?.unitSpecialties && (
                      <Flex gap="0.3125rem">
                        <Flex padding="5px">
                          <BiClinic color="#2D3748" />
                        </Flex>
                        <Flex gap="0.3125rem">{applicantData?.unitSpecialties}</Flex>
                      </Flex>
                    )}

                    <Flex gap="0.3125rem">
                      <Flex padding="5px">
                        <img src={shifts} alt="shifts" />
                      </Flex>
                      <Text width="95%">
                        {applicantData?.totalFacilityShifts} for you - {applicantData?.totalShifts} in total
                      </Text>
                    </Flex>

                    {applicantData?.rating && (
                      <Flex gap="0.3125rem">
                        <Flex padding="5px">
                          <FaRegStar color="#2D3748" />
                        </Flex>
                        <Text>{applicantData?.rating} rating</Text>
                      </Flex>
                    )}

                    <Flex gap="0.3125rem">
                      <Flex padding="5px">
                        <IoLocationOutline color="black" />
                      </Flex>
                      <Text>{Number(convertDistance(Number(applicantData?.distance), 'mi')).toFixed(1)} mi</Text>
                    </Flex>
                  </Flex>

                  <Flex paddingTop="10px" flexDirection="column" gap="0.5rem">
                    <Flex flexDirection="column" gap="0.5rem">
                      <Flex gap="0.3125rem">
                        <Flex padding="5px">
                          <img src={credentials} alt="credentials" />
                        </Flex>
                        <Text fontWeight="600" color="#405FF2">
                          Credentials
                        </Text>
                        <IconButton
                          background="#31387114"
                          _hover={{background: '#31387114'}}
                          size="xs"
                          icon={
                            displayCredentials ? (
                              <MdOutlineKeyboardArrowDown fontSize="1.5rem" color="#405FF2" />
                            ) : (
                              <MdOutlineKeyboardArrowRight fontSize="1.5rem" color="#405FF2" />
                            )
                          }
                          aria-label="credentials"
                          isRound
                          onClick={() => setDisplayCredentials((isDisplay: boolean) => !isDisplay)}
                        />
                      </Flex>

                      {displayCredentials &&
                        (certificationList?.length ? (
                          <Flex gap="0.3rem" flexWrap="wrap">
                            {certificationList?.map(certificate => {
                              if (
                                certificate?.expiration === null ||
                                moment(new Date())?.isBefore(moment(certificate?.expiration), 'date')
                              )
                                return (
                                  <Badge
                                    key={uuid()}
                                    bg="#e5e5eb"
                                    fontSize="0.7rem"
                                    borderRadius="0.3125rem"
                                    fontWeight="semibold"
                                    padding="0.2rem 0.4rem"
                                    cursor="pointer"
                                    onClick={() => setPdfData({isOpen: true, data: certificate?.front})}
                                  >
                                    {certificate?.name}
                                  </Badge>
                                );
                              return null;
                            })}
                          </Flex>
                        ) : (
                          'N/A'
                        ))}
                    </Flex>

                    <Flex flexDirection="column" gap="0.5rem">
                      <Flex gap="0.3125rem">
                        <Flex padding="5px">
                          <img src={test} alt="test" />
                        </Flex>
                        <Text fontWeight="600" color="#405FF2">
                          Test results
                        </Text>
                        <IconButton
                          _hover={{background: '#31387114'}}
                          background="#31387114"
                          size="xs"
                          aria-label="test"
                          isRound
                          icon={
                            displayTest ? (
                              <MdOutlineKeyboardArrowDown fontSize="1.5rem" color="#405FF2" />
                            ) : (
                              <MdOutlineKeyboardArrowRight fontSize="1.5rem" color="#405FF2" />
                            )
                          }
                          onClick={() => setDisplayTest((isDisplay: boolean) => !isDisplay)}
                        />
                      </Flex>

                      {displayTest &&
                        (TestResultList?.length ? (
                          <Flex gap="0.3rem" flexWrap="wrap">
                            {TestResultList?.map(vaccine => {
                              if (
                                vaccine?.expiration === null ||
                                moment(new Date())?.isBefore(moment(vaccine?.expiration), 'date')
                              )
                                return (
                                  <Badge
                                    key={uuid()}
                                    bg="#e5e5eb"
                                    fontSize="0.7rem"
                                    borderRadius="0.3125rem"
                                    fontWeight="semibold"
                                    padding="0.2rem 0.4rem"
                                    cursor="pointer"
                                    onClick={() => setPdfData({isOpen: true, data: vaccine?.front})}
                                  >
                                    {vaccine?.name}
                                  </Badge>
                                );
                              return null;
                            })}
                          </Flex>
                        ) : (
                          <Text paddingLeft="2rem" color="#718096" fontWeight="600">
                            N/A
                          </Text>
                        ))}
                    </Flex>
                  </Flex>
                </Flex>
              </ModalBody>
            </>
          )}

          <ModalFooter>
            {applicantData?.status === 'selected' && (
              <Flex width="100%">
                <Box
                  width="100%"
                  onClick={
                    isViewer
                      ? () => {}
                      : () =>
                          setConfirmationModalOpen({
                            isOpen: true,
                            data: {
                              id: applicantData?.id,
                              name: applicantData?.name,
                              shiftIds,
                              refetch,
                            },
                          })
                  }
                  alignSelf="center"
                  cursor={isViewer ? 'not-allowed' : 'pointer'}
                  opacity={isViewer ? '0.5' : '1'}
                >
                  <Text color="#E53E3E" textAlign="center">
                    Unselect
                  </Text>
                </Box>
                <Button isDisabled width="100%">
                  Selected
                </Button>
              </Flex>
            )}

            {applicantData?.status === 'confirmed' && (
              <Button background="#48BB78" color="white" isDisabled>
                Confirmed
              </Button>
            )}

            {applicantData?.status === 'applied' && (
              <Flex width="100%">
                <Box
                  width="100%"
                  onClick={
                    isViewer ? () => {} : () => handleArchiveApplicants(applicantId, !!applicantData?.archiveDate)
                  }
                  alignSelf="center"
                  cursor={isViewer ? 'not-allowed' : 'pointer'}
                  opacity={isViewer ? '0.5' : '1'}
                >
                  <Text color="#ED8936" textAlign="center">
                    {applicantData?.archiveDate ? 'Unarchive Applicant' : 'Archive Applicant'}
                  </Text>
                </Box>
                <Button
                  width="100%"
                  onClick={() => {
                    handleUpdateApplicantStatus(true);
                    trackNdproSelectFromProfile(applicantData, applicantData?.nurseId);
                  }}
                  isLoading={!applicantData || updateApplicantStatusLoading}
                  isDisabled={isViewer}
                >
                  Select Candidate
                </Button>
              </Flex>
            )}
          </ModalFooter>
        </ModalContent>
      </ChakraModal>

      {pdfData?.isOpen && <PdfModal handleIsModalOpen={setPdfData} data={pdfData?.data} />}
    </>
  );
};

export default ApplicantProfileModal;
