import React, {ForwardRefRenderFunction} from 'react';
import {Box, Text, Flex} from '@chakra-ui/react';
import {components} from 'react-select';

const Option: ForwardRefRenderFunction<HTMLDivElement, any> = ({label, isSelected, ...props}, ref) => (
  <Box ref={ref}>
    <components.Option {...props}>
      <Flex gap=".5rem">
        <input type="checkbox" checked={isSelected} onChange={() => {}} />
        <Text>{label}</Text>
      </Flex>
    </components.Option>
  </Box>
);

export default React.forwardRef(Option);
