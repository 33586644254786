import { ColorHues } from '@chakra-ui/react';

const indigo: ColorHues = {
  50: '#E8EAF1',
  100: '#C5CADE',
  200: '#A0A8C7',
  300: '#7C86B1',
  400: '#616CA1',
  500: '#475392',
  600: '#404B89',
  700: '#38427E',
  800: '#313871',
  900: '#313971',
};

const cyan: ColorHues = {
  50: '#E4F2FF',
  100: '#BEDFFF',
  200: '#94CBFF',
  300: '#6AB7FF',
  400: '#4DA7FF',
  500: '#3798FF',
  600: '#3689F0',
  700: '#3377DC',
  800: '#3065CA',
  900: '#2A46AA',
};

const brightYellow = '#FFC7703';
const danger = {
  100: '#FFF5F5',
  500: '#C53030',
};

const warning = {
  100: '#FFFAF0',
  500: '#DD6B20',
};

const ndColors = {
  primary: indigo,
  secondary: cyan,
  accent: brightYellow,
  red: danger,
  orange: warning,
};

export default ndColors;
