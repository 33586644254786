import useAppSelector from '../../../shared/hooks/useAppSelector';
import {ReduxState} from './CreateShiftForm/CreateShiftForm.types';

export const useFacilityId = () => useAppSelector(state => state?.usersData?.sessionUser?.nextGenFacilityId) ?? '';

export const useIsNdAdmin = () => useAppSelector(state => state?.usersData?.sessionUser?.ndAdmin) ?? false;

export const useLegacyFacilityId = () => useAppSelector(state => state?.usersData?.sessionUser?.legacyFacilityId) ?? '';

export const useShiftTemplates = () => useAppSelector(state => state.shiftTemplates.data);

export const useLoggedInUser = () => useAppSelector(state => state.usersData.loggedInUser?.[0]);

export const useSessionUser = () => useAppSelector(state => state.usersData.sessionUser);

export const useTimeZone = () => useAppSelector(state => state.usersData.sessionUser?.timeZone) ?? '';

export const useShiftTemplate = () => useAppSelector((state: ReduxState) => state.shiftTemplates.shiftTemplate);

export const useFacilityUnits = () =>
  useAppSelector((state: ReduxState) => state.shiftTemplates.facilityUnits?.getFacilityUnitPositionRate.units);

export const useOtherQualifications = () =>
  useAppSelector((state: ReduxState) => state.shiftTemplates.facilityUnits?.getFacilityUnitPositionRate.qualifications);

export const useShifts = () => useAppSelector((state: ReduxState) => state.shifts.data);
