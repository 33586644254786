import mixpanel from "mixpanel-browser";
import {useAuth0} from "@auth0/auth0-react";
import {useIntercom} from "react-use-intercom";
import {useCallback} from "react";

const useLogout = () => {
  const {logout} = useAuth0();
  const {hardShutdown} = useIntercom();

  return useCallback(async () => {
    mixpanel.reset();
    hardShutdown();
    localStorage.clear();
    await logout({logoutParams: {returnTo: window.location.origin}});
  },[logout, hardShutdown])
}

export default useLogout
