import React, {useEffect} from 'react';

import {Flex, Text} from '@chakra-ui/react';
import CreateShiftForm from './CreateShiftForm/CreateShiftForm';
import MobileNotification from '../../../shared/components/MobileNotification/MobileNotification';
import {trackShiftPageCreate} from '../../../shared/mixpanel/page-views';

const CreateShift = () => {
  useEffect(() => {
    trackShiftPageCreate();
  }, []);

  return (
    <Flex flexDirection="column" width="100%" padding="1rem 2rem">
      <MobileNotification />
      {/* Heading */}
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        marginBottom="1.5rem"
        width="100%"
      >
        <Text fontWeight="700" fontSize="1.5rem">
          Create new shifts
        </Text>
        <Text>Choose from your shift templates</Text>
      </Flex>
      <CreateShiftForm />
    </Flex>
  );
};

export default CreateShift;
