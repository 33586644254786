import React from 'react';
import {useForm} from 'react-hook-form';
import {Input, Button, Flex, Box} from '@chakra-ui/react';
import {useDispatch} from 'react-redux';
import {setLastMessage} from '../../shared/slices/messagesSlice';

import {useAddLegacyMessage} from '../../shared/gql/messages/mutations';

type Props = {
  onClose: () => void;
  channelId: number;
  handleSuccessResponse: (response: string) => void;
  setActionDone?: (value: boolean) => void;
};

const MessageInput = ({onClose, channelId, handleSuccessResponse, setActionDone}: Props) => {
  const [loading, setLoading] = React.useState(false);
  const {register, handleSubmit} = useForm();
  const dispatch = useDispatch();
  const [addLegacyMessage] = useAddLegacyMessage({
    onCompleted: data => {
      setLoading(false);
      if (data?.addMessage?.createdAt) {
        dispatch(setLastMessage(data.addMessage));
        onClose();
        handleSuccessResponse('success');
      } else {
        handleSuccessResponse('error');
      }
    },
  });
  // ToDo: Fix any type
  const onSubmit = async (data: any) => {
    if (setActionDone) setActionDone(true);
    try {
      setLoading(true);
      await addLegacyMessage({variables: {channelId, text: data.message}});
    } catch (error) {
      console.error('Failed to add message', error);
    }
  };

  return (
    <Box w="100%" marginLeft="1rem" marginRight="1rem" marginBottom="1rem">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex gap=".5rem">
          <Input id="message" type="text" {...register('message', {required: 'This is required'})} />
          <Button bg="#404B89" type="submit" isLoading={loading} loadingText="Sending">
            Submit
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default MessageInput;
