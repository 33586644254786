import {Flex, Skeleton, Text} from '@chakra-ui/react';
import React from 'react';

const UnitSkeleton = () => (
  <Flex flexDirection="column" gap=".5rem" w={['inherit', '26rem']}>
    <Text fontWeight="600">Unit</Text>
    <Skeleton height="2.5rem" w="26rem" borderRadius=".5rem" />
  </Flex>
);

export default UnitSkeleton;
