import React, {useEffect} from 'react';

import {Box, Flex, Text} from '@chakra-ui/react';
import TimePickerV2 from 'react-time-picker';
import moment from 'moment';
import 'moment-timezone';
import TimeSkeleton from './TimeSkeleton/TimeSkeleton';

import {Props} from './TimeSelector.types';
import './TimeSelector.scss';
import {useTimeZone} from '../../../routes/Shifts/CreateShift/appSelectors';

const TimeSelector = React.forwardRef(
  ({register, isEditable, watch, errors, shiftTemplate, setValue, loading}: Props, ref) => {
    const watchStartTime = watch('startTime');
    const watchEndTime = watch('endTime');
    const timeZone = useTimeZone();

    const handleStartTimeSelection = (value: string) => {
      setValue('startTime', value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    };
    const handleStopTimeSelection = (value: string) => {
      setValue('endTime', value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    };

    useEffect(() => {
      const standardOffset = moment.tz('2024-10-01', timeZone).utcOffset();
      const setTimesWithTimeZone = (startTime: string, endTime: string, tz: string) => {
        if (startTime && endTime && tz) {
          // setValue('startTime', moment.utc(startTime, 'HH:mm').tz(tz).format('HH:mm'), {
          setValue('startTime', moment.utc(startTime, 'HH:mm').add(standardOffset, 'minutes').format('HH:mm'), {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          });
          // setValue('endTime', moment.utc(endTime, 'HH:mm').tz(tz).format('HH:mm'), {
          setValue('endTime', moment.utc(endTime, 'HH:mm').add(standardOffset, 'minutes').format('HH:mm'), {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          });
        }
      };

      if (!timeZone) return;

      if (shiftTemplate?.content) {
        setTimesWithTimeZone(shiftTemplate.content.startTime, shiftTemplate.content.endTime, timeZone);
      }
    }, [shiftTemplate, timeZone, setValue]);

    if (loading) {
      return <TimeSkeleton />;
    }

    return (
      <Flex flexDirection="column" gap=".5rem">
        <Text fontWeight="600">Time</Text>
        <Flex marginBottom="1.5rem" gap={['3rem', '2rem']} alignItems="center">
          <Flex flexDirection="column" gap=".5rem">
            <Box
              w="fit-content"
              border="0.0625rem solid 
rgba(226, 232, 240, 1)"
              padding=".5rem"
              borderRadius="0.375rem"
            >
              <TimePickerV2
                {...register('startTime')}
                ref={ref}
                disabled={!!shiftTemplate && !isEditable}
                disableClock
                clearIcon={null}
                onChange={value => {
                  if (value !== null) {
                    handleStartTimeSelection(value);
                  }
                }}
                value={watchStartTime}
              />
            </Box>
          </Flex>
          <Text>to</Text>
          <Flex flexDirection="column" gap=".5rem">
            <Box
              w="fit-content"
              border="0.0625rem solid 
rgba(226, 232, 240, 1)"
              padding=".5rem"
              borderRadius="0.375rem"
            >
              <TimePickerV2
                {...register('endTime')}
                ref={ref}
                disabled={!!shiftTemplate && !isEditable}
                disableClock
                clearIcon={null}
                onChange={value => {
                  if (value !== null) {
                    handleStopTimeSelection(value);
                  }
                }}
                value={watchEndTime}
              />
            </Box>
          </Flex>
        </Flex>
        {errors.startTime && (
          <Text color="red.500" fontSize="sm">
            {errors.startTime.message}
          </Text>
        )}
        {errors.endTime && (
          <Text color="red.500" fontSize="sm">
            {errors.endTime.message}
          </Text>
        )}
      </Flex>
    );
  }
);

export default TimeSelector;
