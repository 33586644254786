import React from 'react';
import {Flex, Text} from '@chakra-ui/react';

type InfoBoxProps = {
  title: string;
  content: string;
  isExpanded: boolean;
  setExpanded: (value: boolean) => void;
  alignItems: 'flex-start' | 'center' | 'flex-end';
};

const ShiftInformationBox: React.FC<InfoBoxProps> = ({title, content, isExpanded, setExpanded, alignItems}) => {
  const commonTextProps = {
    fontSize: '0.8125rem',
    color: 'black',
    fontWeight: '400',
  };

  const commonTextSecondaryProps = {
    fontSize: '0.8125rem',
    fontWeight: '700',
  };

  const truncatedText = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '12.5rem',
  };

  const hoverableText = {
    cursor: 'pointer',
    borderRadius: '0.125rem',
    transition: 'background-color 0.2s ease',
    ':hover': {
      backgroundColor: '#e4e4e4',
    },
  };

  return (
    <Flex flex="1" display="flex" flexDir="column" alignItems={alignItems} pr='0.625rem'>
      <Text sx={commonTextProps}>{title}</Text>
      <Text
        sx={isExpanded ? commonTextSecondaryProps : {...commonTextSecondaryProps, ...truncatedText, ...hoverableText}}
        onClick={() => setExpanded(!isExpanded)}
      >
        {content || 'None'}
      </Text>
    </Flex>
  );
};

export default ShiftInformationBox;
